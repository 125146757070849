import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";


export type EComManufacturerModel = MfModelBase & {
  manufacturerAccountKey: string,
  manufacturerName: string,
  addressLine1: string,
  addressLine2: string,
  addressLine3: string,
  addressLine4: string,
  addressLine5: string,
  postalCode: string,
  city: string,
  stateCode: string,
  countryCode: string,
}

export type EComManufacturerFieldsConfig = {
  [key in keyof Required<EComManufacturerModel>]: MfModelFieldConfig
};

export const ECOM_MANUFACTURER_MODEL_FIELDS_CONFIG: EComManufacturerFieldsConfig = {
  manufacturerAccountKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  manufacturerName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  addressLine1: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  addressLine2: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  addressLine3: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  addressLine4: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  addressLine5: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  postalCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  city: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  stateCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  countryCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_MANUFACTURER_MODEL_CONFIG_ID = "manufacturer";

export const ECOM_MANUFACTURER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_MANUFACTURER_MODEL_CONFIG_ID,
  fields: ECOM_MANUFACTURER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_MANUFACTURER_MODEL_CONFIG",
  },
  portals: {}
};