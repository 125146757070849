import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Injector } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MfTypeInfo, MfModelConfigService, MfPortalsClientService, mfTypeIsUndefined, MfModelConfigMapped, mfTypeIsArray, MfError, mfTypeIsNullOrUndefined } from "@pattonair/material-framework";
import { EComCartBaseService } from "./cart.base.service";
import { catchError, concatMap, map, Observable, of, share, tap } from "rxjs";
import { ECOM_ENVIRONMENT } from "../environments/environment";
import { EComModelsConfig } from "../modelConfigs/model.config";
import { EComInvoiceAddCartItemRequest, EComInvoiceAddCartItemsRequest, EComInvoiceCartActionResult, EComInvoiceCartDeleteMultipleItemsRequest, EComInvoiceCreateCartRequest } from "../modelConfigs/cart.invoice.model.config";
import { EComCurrencyService } from "./currency.service";
import { EComInvoiceCartBeginPaymentAction, EComInvoiceCartCompleteAction, EComInvoiceCartCompleteActions, EComInvoiceCartEndPaymentAction, EComInvoiceCartItemActionResult, EComInvoiceCartItemAddAction, EComInvoiceCartItemAddActions, EComInvoiceCartItemRemoveAction, EComInvoiceCartItemRemoveActions } from "./cart.incoice.actions";
import { EComRouteNames } from "../ecom.route.names";
import { EComInvoiceCheckoutBeginPaymentModel, EComInvoiceCheckoutBeginPaymentRequestModel, EComInvoiceCheckoutEndPaymentRequestModel } from "../modelConfigs/checkout.invoice.payment.model.config";
import { EComInvoiceCartValidationItemModel, EComInvoiceCartValidationModel, EComInvoiceCompleteModel } from "../modelConfigs/checkout.invoice.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComCartInvoiceService" };

@Injectable({ providedIn: "root" })
export class EComCartInvoiceService extends EComCartBaseService<EComInvoiceCartValidationItemModel> {
  protected _addCartItemRequestModelConfig: MfModelConfigMapped;
  protected _addCartItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartActionResultModelConfig: MfModelConfigMapped;
  protected _cartDeleteMultipleItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartValidationModelConfig: MfModelConfigMapped;
  protected _checkoutBeginPaymentModelConfig: MfModelConfigMapped;
  protected _checkoutBeginPaymentResponseModelConfig: MfModelConfigMapped;
  protected _checkoutEndPaymentRequestModelConfig: MfModelConfigMapped;
  protected _completeModelConfig: MfModelConfigMapped;
  protected _createCartRequestModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _currencyService: EComCurrencyService,
    @Inject(DOCUMENT)
    protected _document: Document,
  ) {
    super(TYPE_INFO, _injector);
    this._addCartItemRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceAddCartItemRequest");
    this._addCartItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceAddCartItemsRequest");
    this._cartActionResultModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCartActionResult");
    this._cartDeleteMultipleItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCartDeleteMultipleItemsRequest");
    this._checkoutBeginPaymentModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCheckoutBeginPayment");
    this._checkoutBeginPaymentResponseModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCheckoutBeginPaymentRequest");
    this._checkoutEndPaymentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCreateCartRequest");
    this._createCartRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCreateCartRequest");
    this._cartValidationModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCartValidation");
    this._completeModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceComplete");

    this._sub(this._currencyService.selected, {
      next: () => {
        this._sub(this._currencyService.onSelectedChange, { next: () => this._clearCache() });
      }
    });
  }

  public get count(): Observable<number> {
    return this._getCount();
  }

  public get id(): Observable<string> {
    return of(this._idInt).pipe(
      concatMap((id) => {
        if (!mfTypeIsUndefined(id)) {
          return of(id);
        } else {
          return this._getCreateCart();
        }
      }),
      share(),
    );
  }

  public add(actions: EComInvoiceCartItemAddActions): Observable<EComInvoiceCartItemActionResult> {
    let add$: Observable<EComInvoiceCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      add$ = this._addItems(actions);
    } else {
      add$ = this._addItem(actions);
    }

    return add$.pipe(
      tap((result) => {
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public remove(actions: EComInvoiceCartItemRemoveActions): Observable<EComInvoiceCartItemActionResult> {
    let remove$: Observable<EComInvoiceCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      remove$ = this._removeItems(actions);
    } else {
      remove$ = this._removeItem(actions);
    }

    return remove$.pipe(
      tap((result) => {
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public beginPayment(action: EComInvoiceCartBeginPaymentAction): Observable<boolean> {
    return this._beginPayment(action).pipe(
      map((result) => {
        if (!this._redirectIfValidationErrors(result)) {
          if (!mfTypeIsNullOrUndefined(result.paymentUrl)) {
            this._document.location.href = result.paymentUrl;
            return true;
          } else {
            throw new MfError(this._typeInfo, "beginPayment", "Unabel to determin payment routing");
          }
        }
        return false;
      })
    );
  }

  public endPayment(action: EComInvoiceCartEndPaymentAction): Observable<boolean> {
    return this._endPayment(action).pipe(
      map((result) => {
        this._router.navigate([EComRouteNames.routeInvoiceCheckoutReview], {
          queryParams: { [EComRouteNames.queryParamNameLockNavigation]: true }
        }
        );
        return result;

        return false;
      })
    );
  }

  public complete(action: EComInvoiceCartCompleteActions): Observable<boolean> {
    return this._complete(action).pipe(
      map((result) => {
        return !this._redirectIfValidationErrors(result);
      })
    );
  }

  protected _complete(checkout: EComInvoiceCartCompleteAction): Observable<EComInvoiceCartValidationModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComInvoiceCompleteModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
            };

            return this._portalsClientService.getItemPost<EComInvoiceCartValidationModel, EComInvoiceCompleteModel>(
              this._cartValidationModelConfig,
              this._completeModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/complete`)
              .pipe(
                map((cartResponse) => {
                  this._clearCache();
                  this.onCountChanged.next(0);
                  return cartResponse;
                })
              );
          })
        );
      })
    );
  }

  protected _addItem(action: EComInvoiceCartItemAddAction): Observable<EComInvoiceCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComInvoiceAddCartItemRequest = {
          key: selectedCustomer.key,
          invoiceKey: action.invoiceKey,
        };

        return this._portalsClientService.getItemPost<EComInvoiceCartActionResult, EComInvoiceAddCartItemRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/add`)
          .pipe(
            map((cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            })
          );
      }),
    );
  }

  protected _addItems(actions: EComInvoiceCartItemAddAction[]): Observable<EComInvoiceCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComInvoiceAddCartItemsRequest = {
          key: selectedCustomer.key,
          invoiceKeys: actions.map(action => action.invoiceKey),
        };

        return this._portalsClientService.updateItemPost<EComInvoiceCartActionResult, EComInvoiceAddCartItemsRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemsRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/addmulti`)
          .pipe(map(
            (cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            }
          ));
      }),
    );
  }

  protected _removeItem(action: EComInvoiceCartItemRemoveAction): Observable<EComInvoiceCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            return this._portalsClientService.deleteItemDelete<EComInvoiceCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/${cartKey}//${ECOM_ENVIRONMENT.portalsLinesUrl}/${action.invoiceKey}`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          }),
        );
      }),
    );
  }

  protected _removeItems(action: EComInvoiceCartItemRemoveAction[]): Observable<EComInvoiceCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComInvoiceCartDeleteMultipleItemsRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              invoiceKeys: action.map(i => i.invoiceKey)
            };
            return this._portalsClientService.getItemPost<EComInvoiceCartActionResult, EComInvoiceCartDeleteMultipleItemsRequest>(
              this._cartActionResultModelConfig,
              this._cartDeleteMultipleItemsRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}/delete`)
              .pipe(
                map((cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                })
              );
          })
        );
      }),
    );
  }

  protected _beginPayment(action: EComInvoiceCartBeginPaymentAction): Observable<EComInvoiceCheckoutBeginPaymentModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComInvoiceCheckoutBeginPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              amount: action.amount,
            };

            return this._portalsClientService.getItemPost<EComInvoiceCheckoutBeginPaymentModel, EComInvoiceCheckoutBeginPaymentRequestModel>(
              this._checkoutBeginPaymentModelConfig,
              this._checkoutBeginPaymentResponseModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/begin`
            );
          })
        );
      })
    );
  }

  protected _endPayment(action: EComInvoiceCartEndPaymentAction): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComInvoiceCheckoutEndPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              paymentInfo: action.paymentInfo,
            };

            return this._portalsClientService.post<EComInvoiceCheckoutEndPaymentRequestModel>(
              this._checkoutEndPaymentRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/end`
            );
          })
        );
      })
    );
  }

  protected _getCount(): Observable<number> {
    if (!mfTypeIsUndefined(this._count)) {
      return of(this._count);
    } else {
      if (mfTypeIsUndefined(this._count$)) {
        this._count$ = this.id.pipe(
          concatMap((id) => {
            return this._portalsClientService.getItemGet<EComInvoiceCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/${id}`)
              .pipe(
                map((cartCountResponse) => {
                  this._count = cartCountResponse.itemCount;
                  return this._count;
                })
              );
          }),
          share(),
        );
      }
      return this._count$;
    }
  }

  protected _getCreateCart(): Observable<string> {
    if (mfTypeIsUndefined(this._create$)) {
      this._create$ = this._customerService.selected.pipe(
        concatMap((selectedCustomer) => {
          return this._currencyService.selected.pipe(
            concatMap((selectedCurrency) => {
              const data: EComInvoiceCreateCartRequest = { key: selectedCustomer.key, currencyCode: selectedCurrency.code };
              return this._portalsClientService.getItemPost<EComInvoiceCartActionResult, EComInvoiceCreateCartRequest>(
                this._cartActionResultModelConfig,
                this._createCartRequestModelConfig,
                data,
                `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/create`)
                .pipe(
                  map((cartCreateResponse) => {
                    this._idInt = cartCreateResponse.cartKey;
                    this._count = cartCreateResponse.itemCount;
                    this.onCountChanged.next(0);
                    return this._idInt;
                  })
                );
            }),
          );
        }),
        catchError((error) => {
          delete this._idInt;
          this.onCountChanged.next(0);
          throw error;
        }),
        share(),
      );
    }
    return this._create$;
  }

  protected _redirectIfValidationErrors(result: EComInvoiceCartItemActionResult): boolean {
    if (this._hasValidationErrors(result) === true) {
      this._combineErrors(result);
      this._router.navigate([EComRouteNames.routeInvoiceCartList], { queryParams: { [EComRouteNames.queryParamNameAutoValidation]: true, } });
      return true;
    } else {
      this.clearErrors();
    }
    return false;
  }
}