import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";


export type EComRequestQuoteModel = MfModelBase & {
  key: string,
  itemSpecKey: string,
  itemKey: string;
  quantity: number,
}

export type EComRequestQuoteModelFieldsConfig = {
  [key in keyof Required<EComRequestQuoteModel>]: MfModelFieldConfig
};

export const ECOM_REQUEST_QUOTE_MODEL_FIELDS_CONFIG: EComRequestQuoteModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  itemSpecKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  quantity: {
    dataType: {
      type: MfModelFieldDataTypes.int,
    }
  },
  itemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_REQUEST_QUOTE_MODEL_CONFIG_ID = "requestQuote";

export const ECOM_REQUEST_QUOTE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_REQUEST_QUOTE_MODEL_CONFIG_ID,
  fields: ECOM_REQUEST_QUOTE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_REQUEST_QUOTE_MODEL_CONFIG",
  },
  portals: {}
};