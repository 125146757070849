import { Component, Injector } from "@angular/core";
import { MfBaseComponent, MfTypeInfo } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComTestComponent" };

@Component({
  selector: "ecom-test",
  templateUrl: "./test.component.html",
  styleUrls: ["./test.component.scss"],
})
export class EComTestComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }
}