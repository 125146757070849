import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { EComCheckoutStepBaseComponent } from "@e-commerce/checkout/base/checkout.step.base.component";
import { MF_TABLE_CONFIG_TOKEN, MfModelConfigMapped, MfModelConfigService, MfModelFieldExtendedConfig, mfObjectGetPropertyPath, MfPortalsTableComponent, MfTableConfig, MfTableHeaderItem, mfTableReIndexModelFieldExtendedConfig, MfTypeInfo, mfTypeIsUndefined } from "@pattonair/material-framework";
import { EComInvoiceCheckoutData } from "../checkout.invoice";
import { ECOM_TABLE_CONFIG } from "../../../ecom.config";
import { EComModelsConfig } from "../../../modelConfigs/model.config";
import { EComRecordOfPaymentModel, EComRecordOfPaymentRequestModel } from "../../../modelConfigs/record.of.payment.nodel.config";
import { ECOM_ENVIRONMENT } from "../../../environments/environment";
import { concatMap, map, Observable } from "rxjs";
import { EComCustomerService } from "../../../services/customer.service";
import { EComCartInvoiceService } from "../../../services/cart.invoice.service";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceOrderReviewComponent" };

const ECOM_INVOICE_LOCATION_KEY = "checkout.invoice.orderReview";

export const ECOM_TABLE_CONFIG_ORDER_REVIEW: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  header: {
    ...ECOM_TABLE_CONFIG.header,
    layout: {
      left: [],
      right: [
        MfTableHeaderItem.viewManager,
      ]
    },
  },
  backgroundColor: "#e3e6eb",
};

const ECOM_RECORD_OF_PAYMENTS_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("invoiceNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("invoiceDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("paymentDueDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("currencyCode")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("currencyValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("currencyTaxValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("currencyTotalValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("currentOpenAmount")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComRecordOfPaymentModel>("resultingOpenAmount")
  },
];

@Component({
  selector: "ecom-invoice-order-review",
  templateUrl: "./invoice.order.review.component.html",
  styleUrls: ["./invoice.order.review.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_TABLE_CONFIG_ORDER_REVIEW },
  ]
})
export class EComInvoiceOrderReviewComponent extends EComCheckoutStepBaseComponent<EComInvoiceCheckoutData> implements OnInit {
  @ViewChild("recordOfPaymentsTable", { static: true })
  protected get _recordOfPaymentsTable(): MfPortalsTableComponent<EComRecordOfPaymentModel, string, number> | undefined {
    return this._recordOfPaymentTableInit;
  }
  protected set _recordOfPaymentsTable(value: MfPortalsTableComponent<EComRecordOfPaymentModel, string, number> | undefined) {
    this._recordOfPaymentTableInit = value;
    if (!mfTypeIsUndefined(this._recordOfPaymentTableInit)) {
      this._initializeTable();
      setTimeout(() => {
        this._tableLoadData();
      }, 500);
    }
  }

  protected _locationKey = ECOM_INVOICE_LOCATION_KEY;

  protected _recordOfPaymentRequestModelConfig: MfModelConfigMapped;
  protected _recordOfPaymentModelConfig: MfModelConfigMapped;
  protected _recordOfPaymentTableInit?: MfPortalsTableComponent<EComRecordOfPaymentModel, string, number>;

  public constructor(
    protected override _injector: Injector,
    protected _customerService: EComCustomerService,
    protected _modelConfigService: MfModelConfigService,
    protected _cartInvoiceService: EComCartInvoiceService,
  ) {
    super(TYPE_INFO, _injector);
    this._recordOfPaymentModelConfig = this._modelConfigService.get<EComModelsConfig>("recordOfPayment");
    this._recordOfPaymentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("recordOfPaymentRequest");
    this._setModelConfigs();
    this._tableLoadData();
  }

  public ngOnInit(): void {
    this._tableLoadData();
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._recordOfPaymentTableInit)) {
      this._sub(this._customerService.hasSelection, {
        next: (hasSelection) => {
          if (hasSelection) {
            this._recordOfPaymentTableInit!.blockDataLoad = false;
            this._recordOfPaymentTableInit!.loadData();
          }
        }
      });
    }
  }

  protected override _onCheckoutModelSet(model: EComInvoiceCheckoutData): void {
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_RECORD_OF_PAYMENTS_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._recordOfPaymentModelConfig, ECOM_RECORD_OF_PAYMENTS_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._recordOfPaymentTableInit)) {
      this._recordOfPaymentTableInit.blockDataLoad = true;
      this._recordOfPaymentTableInit.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/ledger`;

      this._recordOfPaymentTableInit.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComRecordOfPaymentRequestModel): Observable<EComRecordOfPaymentRequestModel> => {
    return this._customerService.selected.pipe(
      concatMap((selected) => {
        return this._cartInvoiceService.id.pipe(
          map((cartKey) => {
            data.cartKey = cartKey;
            data.key = selected.key;
            return data;
          })
        );
      })
    );
  };
}