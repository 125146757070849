import { Directive, Injector, OnInit, ViewChild } from "@angular/core";
import { MfModelBase, MfPortalsTableComponent, mfTypeIsUndefined } from "@pattonair/material-framework";
import { MfBaseComponent, MfTypeInfo } from "@pattonair/material-framework";
import { EComCustomerService } from "../services/customer.service";
import { EComCartBaseService } from "../services/cart.base.service";
import { EComCartErrorTypes, EComErrorValidationItemModel } from "../services/cart.action";

@Directive()
export abstract class EComCartBaseComponent<TModel extends MfModelBase & { isSelected?: boolean }, TCartValidationItemModel extends MfModelBase, TCartItemActionResult extends {
  errors?: EComErrorValidationItemModel[],
  items?: TCartValidationItemModel[]
}> extends MfBaseComponent implements OnInit {
  @ViewChild("cartLinesTable", { static: true })
  protected get _cartLinesTable(): MfPortalsTableComponent<TModel, string, number> | undefined {
    return this._cartLinesTableInit;
  }
  protected set _cartLinesTable(value: MfPortalsTableComponent<TModel, string, number> | undefined) {
    this._cartLinesTableInit = value;
    this._initializeTable();
  }

  protected _cartLinesTableInit?: MfPortalsTableComponent<TModel, string, number>;
  protected _hasSelected = false;
  protected _hasValidationErrors: boolean = false;
  protected _validationError?: TCartItemActionResult;
  protected _isLoadingInit = false;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
    protected _customerService: EComCustomerService,
    protected _cartService: EComCartBaseService<TCartValidationItemModel>,
  ) {
    super(_typeInfo, _injector);
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected get _isLoading(): boolean {
    return this._cartLinesTableInit?.isLoading === true || this._isLoadingInit === true;
  }

  protected get _cartItemsCount(): number {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      return this._cartLinesTableInit.dataSource.data.length;
    }
    return 0;
  }

  protected _toggleAllSelected(state: boolean): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._cartLinesTableInit.dataSource.data.forEach(i => i.isSelected = state);
      this._hasSelected = this._cartLinesTableInit.dataSource.data.some(i => i.isSelected === true);
    }
  }

  protected _toggleItemChangeSelected(cartLine: TModel): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      cartLine.isSelected = mfTypeIsUndefined(cartLine.isSelected) ? true : !cartLine.isSelected;
      this._hasSelected = this._cartLinesTableInit.dataSource.data.some(i => i.isSelected === true);
    }
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._hasSelected = false;
      this._cartLinesTableInit.blockDataLoad = false;
      this._cartLinesTableInit.loadData();
      this._checkForValidationErrors();
      this._cdRef.detectChanges();
    }
  }

  protected _checkForValidationErrors(): boolean {
    if (this._cartService.hasValidationErrors === true) {
      this._loadValidationError();
      return true;
    } else {
      this._clearValidationError();
      return false;
    }
  }

  protected get _hasBlockingErrors(): boolean {
    return (!mfTypeIsUndefined(this._cartService.errorItems) && this._cartService.errorItems.length > 0)
      ||
      (!mfTypeIsUndefined(this._cartService.errors) && this._cartService.errors.length > 0 && this._cartService.errors.filter(i => i.type === EComCartErrorTypes.error).length > 0);
  }

  protected abstract _initializeTable(): void;
  protected abstract _loadValidationError(): void;
  protected abstract _clearValidationError(): void;
}