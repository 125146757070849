import { Directive } from "@angular/core";
import { EComCheckoutBasModel } from "@e-commerce/checkout/base/checkout";
import { MfBaseComponent, mfTypeIsUndefined } from "@pattonair/material-framework";

@Directive()
export abstract class EComCheckoutStepBaseComponent<TCheckoutData extends EComCheckoutBasModel> extends MfBaseComponent {
  public get checkoutData(): TCheckoutData | undefined {
    return this._checkoutData;
  }
  public set checkoutData(value: TCheckoutData | undefined) {
    this._checkoutData = value;
    if (!mfTypeIsUndefined(this._checkoutData)) {
      this._onCheckoutModelSet(this._checkoutData);
    }
  }

  protected _checkoutData?: TCheckoutData;
  protected readonly _isCheckoutStep = true;
  protected abstract _onCheckoutModelSet(model: TCheckoutData): void;
}