import { AfterViewInit, Component, Injector, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS } from "@e-commerce/ecom.route.keys";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComManufacturerDetailLineComponent } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.component";
import { EComItemDetailModel } from "@e-commerce/modelConfigs/item.detail.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComStockSummeryModel, EComStockSummeryRequest } from "@e-commerce/modelConfigs/stock.summery.model.config";
import { EComQuoteDialogComponent } from "@e-commerce/productDetail/dialog/quote.dialog.component";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComProductService } from "@e-commerce/services/product.service";
import { MfTypeInfo, MfModelFieldExtendedConfig, mfObjectGetPropertyPath, ERROR_MESSAGE_MIN_VALUE, MfBaseComponent, MfPortalsTableComponent, MfModelConfigMapped, MfBreadcrumbService, MfModelConfigService, MfDialogService, mfTypeIsUndefined, mfTableReIndexModelFieldExtendedConfig, mfTypeIsNullOrUndefined, MfTableRowCellComponent, MfModelValueOutputFormatterTypes } from "@pattonair/material-framework";

import { map, Observable, Subject } from "rxjs";
import { EComOrderCartItemAddAction } from "../services/cart.order.actions";

const TYPE_INFO: MfTypeInfo = { className: "EComProductDetailComponent" };

const ECOM_PRODUCT_DETAIL_LOCATION_KEY = "order.detail.stocks";

const ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("manufacturerName")
  },
  {
    table: { index: 0, header: { sortable: false } },
    display: { displayName: "Stock Location" },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("site")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("certificateDescription")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("revision")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("shelfLifeDays")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("quantityAvailable")
  },
  {
    valueOutputFormatter: {
      formatter: {
        type: MfModelValueOutputFormatterTypes.numberEditor,
        options: {
          width: 120,
          validators: [
            {
              validationFunction: { type: "min", params: { min: 0 } },
              validationMessage: { priority: 1, validatorName: "min", message: ERROR_MESSAGE_MIN_VALUE, messageParams: [0] }
            },
          ]
        },
      }
    },
    table: { index: 0, },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("orderQty")
  },
];

type EComItemDetailFeature = {
  label: string;
  value: string;
}

type EComItemDetailViewModel = EComItemDetailModel & {
  imageUrl?: string;
}

@Component({
  selector: "ecom-product-detail",
  templateUrl: "./product.detail.component.html",
  styleUrls: ["./product.detail.component.scss"],
})
export class EComProductDetailComponent extends MfBaseComponent implements OnInit, AfterViewInit {
  @ViewChild("stockSummeryTable", { static: true })
  protected get _stockSummeryTable(): MfPortalsTableComponent<EComStockSummeryModel, string, number> | undefined {
    return this._stockSummeryTableInt;
  }
  protected set _stockSummeryTable(value: MfPortalsTableComponent<EComStockSummeryModel, string, number> | undefined) {
    this._stockSummeryTableInt = value;
    this._initializeTable();
  }

  @ViewChildren("manufacturerDetails")
  protected _manufacturerDetails?: QueryList<EComManufacturerDetailLineComponent>;

  protected _itemSpecKey?: string;
  protected _itemKey?: string;
  protected _itemDetail?: EComItemDetailViewModel;
  protected _itemFeatures: EComItemDetailFeature[] = [];
  protected _stockSummeryModelConfig: MfModelConfigMapped;
  protected _stockSummeryRequestModelConfig: MfModelConfigMapped;
  protected _stockSummeryTableInt?: MfPortalsTableComponent<EComStockSummeryModel, string, number>;
  protected _itemDetailLoadedSubject = new Subject<boolean>();
  protected _showAllStockLocations = false;
  protected _locationKey = ECOM_PRODUCT_DETAIL_LOCATION_KEY;
  protected _selectionOrderValid = false;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _breadcrumbService: MfBreadcrumbService,
    protected _activatedRoute: ActivatedRoute,
    protected _productService: EComProductService,
    protected _cartService: EComCartOrderService,
    protected _customerService: EComCustomerService,
    protected _modelConfigService: MfModelConfigService,
    protected _dialogService: MfDialogService,
  ) {
    super(TYPE_INFO, _injector);
    this._stockSummeryModelConfig = this._modelConfigService.get<EComModelsConfig>("stockSummery");
    this._stockSummeryRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("stockSummeryRequest");
    this._setModelConfigs();
    this._getRouteParams();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngAfterViewInit(): void {
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected get _isLoading(): boolean {
    return this._stockSummeryTable?.isLoading === true;
  }

  protected get _hasSinglePriceBreak(): boolean {
    return !mfTypeIsUndefined(this._itemDetail?.priceBreaks.find(i => i.quantity === 1));
  }

  protected _onItemDetailExpand(product: EComStockSummeryModel): void {
    this._manufacturerDetails?.forEach(i => {
      if (i.stockSummery?.itemSpecKey === product.itemSpecKey) {
        i.loadDetail();
      }
    });
  }

  protected _onContinueShoppingClicked(): void {
    this._router.navigate([EComRouteNames.routeProducts]);
  }

  protected _onGetAQuoteClicked(): void {
    if (!mfTypeIsUndefined(this._itemDetail) && !mfTypeIsUndefined(this._itemSpecKey) && !mfTypeIsUndefined(this._itemKey)) {
      const dialogRef = this._dialogService.openOkCancel({
        disableClose: true,
        data: {
          contentComponent: EComQuoteDialogComponent,
          title: "Request A Quote",
          okDisplayName: "Request",
          inputs: {
            quantity: 1,
          },
          outputs: ["quantity"]
        },
      });

      this._sub(dialogRef.afterClosed(), {
        next: (result) => {
          if (result.ok === true) {
            const quantity = result.outputs["quantity"];

            const loading = this._subLoading(
              this._productService.requestQuote(this._itemSpecKey!, this._itemKey!, quantity),
              {
                next: () => { loading.complete(); },
                error: () => { loading.complete(); }
              },
              "Requesting Quote"
            );
          }
        },
      });
    }
  }

  protected _onAddToCartClicked(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      const items = this._stockSummeryTable.dataSource.data
        .filter(i => i.orderQty > 0).map(i => ({
          itemSpecKey: i.itemSpecKey,
          quantity: i.orderQty,
          isOrderAction: true
        } as EComOrderCartItemAddAction));
      const loading = this._subLoading(
        this._cartService.add(items.length === 1 ? items[0] : items),
        {
          next: () => {
            loading.complete();
            this._router.navigate([EComRouteNames.routeShopCartList]);
          },
        },
        "Adding Items To Cart"
      );
    }
  }

  protected _onShowAllStockLocationsClicked(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable) && this._stockSummeryTable.isLoading === false) {
      this._showAllStockLocations = !this._showAllStockLocations;
      this._tableLoadData();
    }
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._itemDetail) && !mfTypeIsUndefined(this._stockSummeryTable)) {
      this._stockSummeryTable.blockDataLoad = false;
      this._stockSummeryTable.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._stockSummeryModelConfig, ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG);
  }

  protected _getRouteParams(): void {
    this._sub(this._activatedRoute.params, {
      next: (params) => {
        const itemKey = params[EComRouteNames.parameterNameItemKey];
        if (!mfTypeIsNullOrUndefined(itemKey)) {
          this._itemKey = itemKey;
        }
        const itemSpecKey = params[EComRouteNames.parameterNameItemSpecKey];
        if (!mfTypeIsNullOrUndefined(itemSpecKey)) {
          this._itemSpecKey = itemSpecKey;
        }

        if (!mfTypeIsUndefined(this._itemKey)) {
          this._getProduct(itemKey);
        }
      }
    });
  }

  protected _getProduct(itemKey: string): void {
    const getProduct$ = this._productService.getProductDetail(itemKey).pipe(
      map((itemDetail) => {
        if (!mfTypeIsUndefined(this._stockSummeryTable)) {
          this._itemDetail = itemDetail;
          this._itemDetail.imageUrl = this._productService.getProductImageUrl(this._itemDetail);
          this._tableLoadData();
          this._setItemFeatures();
          this._setBreadcrumbProductName();
        }
        return true;
      })
    );

    const loading = this._subLoading(getProduct$, {
      next: () => {
        loading.complete();
      }
    });
  }

  protected _setItemFeatures(): void {
    if (!mfTypeIsUndefined(this._itemDetail) && !mfTypeIsUndefined(this._itemDetail.descriptions)) {
      const length = this._itemDetail.descriptions.length;
      for (let index = 0; index < length; index++) {
        const description = this._itemDetail.descriptions[index];
        const keyValue = description.split(",");
        this._itemFeatures.push({
          label: keyValue[0],
          value: keyValue[1],
        });
      }
    }
  }

  protected _setBreadcrumbProductName(): void {
    if (!mfTypeIsUndefined(this._itemDetail)) {
      this._breadcrumbService.update(ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS, { label: this._itemDetail.itemCode });
    }
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      this._stockSummeryTable.blockDataLoad = true;
      this._stockSummeryTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsStocksUrl}`;
      this._stockSummeryTable.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _onRowsChanged(cells: MfTableRowCellComponent<EComStockSummeryModel>[]): void {
    cells.forEach((cell) => {
      const max = mfTypeIsUndefined(cell.model) ? 0 : cell.model.quantityAvailable;
      if (!mfTypeIsUndefined(cell?.editFormatter?.addValidators)) {
        cell.editFormatter.addValidators([
          {
            validationFunction: { type: "max", params: { max } },
            validationMessage: { priority: 1, validatorName: "max", message: "There is only {0} in stock.", messageParams: [max] }
          }
        ]);
      }
    });
  }

  protected _onRowValueChanged(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      const haveQty = this._stockSummeryTable.dataSource.data.filter(i => i.orderQty > 0);
      if (!mfTypeIsUndefined(haveQty) && haveQty.length > 0) {
        this._selectionOrderValid = haveQty.every(i => i.orderQty <= i.quantityAvailable);
      } else {
        this._selectionOrderValid = false;
      }
    }
  }

  protected _updatePostDate = (data: EComStockSummeryRequest): Observable<EComStockSummeryRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.key = selected.key;
        data.id = mfTypeIsUndefined(this._itemSpecKey) ? this._itemKey : this._showAllStockLocations === true ? this._itemKey : this._itemSpecKey;
        return data;
      })
    );
  };
}