// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComFreightHandlingTypes = {
  incora: "incora",
  collect: "collect ",
} as const;

export type EComFreightHandlingTypes = typeof EComFreightHandlingTypes[keyof typeof EComFreightHandlingTypes]

export function ecomGetFreightHandlingTypesDisplayValue(value: EComFreightHandlingTypes): string {
  switch (value) {
    case EComFreightHandlingTypes.incora:
      return "Incora Prepay and Add to Invoice";
    case EComFreightHandlingTypes.collect:
      return "Collect (Customer Freight Account)";
  }
}