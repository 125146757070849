import { Component, Injector, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MfTypeInfo, MfBaseValidationComponent, MfDialogHostBaseComponent, MfInputDataTypes, ERROR_MESSAGE_REQUIRED, ERROR_MESSAGE_MIN_VALUE } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComQuoteDialogComponent" };

@Component({
  selector: "ecom-quote-date",
  templateUrl: "./quote.dialog.component.html",
  styleUrls: ["./quote.dialog.component.scss"],
})
export class EComQuoteDialogComponent extends MfBaseValidationComponent implements MfDialogHostBaseComponent {
  @Input()
  public get quantity(): number | undefined {
    return this._quantity;
  }
  public set quantity(value: number | undefined) {
    this._quantity = value;
  }

  public isValid = false;

  protected readonly _formGroup: FormGroup;
  protected readonly _inputName = "quantity" as const;
  protected readonly _inputFormControl = new FormControl();
  protected _quantity?: number;


  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
    this._formGroup = new FormGroup([]);
    this._buildFormGroup();
  }

  protected _changed(value: MfInputDataTypes): void {
    this.quantity = value as number;
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this._formGroup, this._inputName, this._inputFormControl);
    this._addValidation(this._inputName, this._inputFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation(this._inputName, this._inputFormControl, Validators.min(1), { priority: 1, validatorName: "min", message: ERROR_MESSAGE_MIN_VALUE, messageParams: ["1"] });
    this._addFormControlErrorMessageHandling(this._inputName, this._inputFormControl);

    this._sub(this._inputFormControl.statusChanges, { next: () => this.isValid = this._inputFormControl.valid });
  }
}