import { EventEmitter, Injectable, Injector } from "@angular/core";
import { MfTypeInfo, MfBaseService, mfTypeIsUndefined, MfError, MfLocalStorageService, MfPortalsClientService, MfModelConfigMapped, MfModelConfigService } from "@pattonair/material-framework";
import { EComCurrencyModel } from "@e-commerce/modelConfigs/currency.model.config";
import { catchError, concatMap, map, Observable, of, share } from "rxjs";
import { EComCustomerService } from "./customer.service";
import { EComModelsConfig } from "../modelConfigs/model.config";
import { ECOM_ENVIRONMENT } from "../environments/environment";

const TYPE_INFO: MfTypeInfo = { className: "EComCurrencyService" };

const ECOM_SELECTED_KEY = "selected";

@Injectable({ providedIn: "root" })
export class EComCurrencyService extends MfBaseService {
  public onSelectedChange: EventEmitter<EComCurrencyModel> = new EventEmitter();

  protected _currencyModelConfig: MfModelConfigMapped;
  protected _selected?: EComCurrencyModel;
  protected _selected$?: Observable<EComCurrencyModel>;
  protected _currencies?: EComCurrencyModel[] = [
    { code: "USD", displayName: "USD" },
    { code: "GBP", displayName: "GBP" },
  ];
  protected _currencies$?: Observable<EComCurrencyModel[]>;

  public constructor(
    protected override _injector: Injector,
    protected _customerService: EComCustomerService,
    protected _storageService: MfLocalStorageService,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
  ) {
    super(TYPE_INFO, _injector);
    this._currencyModelConfig = this._modelConfigService.get<EComModelsConfig>("currency");

    this._sub(this._customerService.onSelectedChange, { next: () => this._clearCache() });
  }

  public get hasSelection(): Observable<boolean> {
    return this.selected.pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public get selected(): Observable<EComCurrencyModel> {
    if (!mfTypeIsUndefined(this._selected)) {
      return of(this._selected);
    } else {
      this._selected$ = this.currencies.pipe(
        concatMap((currencies) => {
          return this._customerService.details.pipe(
            concatMap((customerDetail) => {
              if (currencies.length > 0) {
                const selectedStorage = this._storageService.get<EComCurrencyModel>(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`);
                const selectedCode = !mfTypeIsUndefined(selectedStorage) ? selectedStorage.code : !mfTypeIsUndefined(customerDetail.defaultCurrencyCode) ? customerDetail.defaultCurrencyCode : currencies[0].code;

                return this.setSelectedByCode(selectedCode).pipe(
                  concatMap((setSelectedResult) => {
                    if (setSelectedResult === true) {
                      return of(this._selected as EComCurrencyModel);
                    } else {
                      return this.setSelectedByCode(currencies[0].code).pipe(
                        map((result) => {
                          if (result === true) {
                            return this._selected as EComCurrencyModel;
                          }
                          throw new MfError(this._typeInfo, "selected", "Unable to set selected currency no default found");
                        })
                      );
                    }
                  })
                );
              }

              const error = new MfError(this._typeInfo, "selected", "Unable to get selected currencies list is empty");
              error.hideFromSnackBar = true;

              throw error;
            }),
          );
        }),
        share(),
      );
    }
    return this._selected$;
  }

  public get currencies(): Observable<EComCurrencyModel[]> {
    if (mfTypeIsUndefined(this._currencies)) {
      if (mfTypeIsUndefined(this._currencies$)) {
        this._currencies$ = this._customerService.selected.pipe(
          concatMap((customer) => {
            return this._portalsClientService.getCollectionPost<EComCurrencyModel, {}>(this._currencyModelConfig, `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}`, {}).pipe(
              map((response) => {

                if (!mfTypeIsUndefined(response) && !mfTypeIsUndefined(response.results)) {
                  if (!mfTypeIsUndefined(response.results)) {
                    return response.results;
                  }
                }

                throw new MfError(this._typeInfo, "currencies", `Unable to read currencies from response ${response}`);
              }),

            );
          }),
          share(),
        );
      }

      return this._currencies$;
    } else {
      return of(this._currencies);
    }
  }

  public setSelected(selected: EComCurrencyModel): Observable<boolean> {
    return this.setSelectedByCode(selected.code);
  }

  public setSelectedByCode(code: string): Observable<boolean> {
    if (mfTypeIsUndefined(this._selected) || (!mfTypeIsUndefined(this._selected) && this._selected.code !== code)) {
      return this.currencies.pipe(
        map((accounts) => {
          const selected = accounts.find(account => account.code === code);
          if (!mfTypeIsUndefined(selected)) {
            this._storageService.addUpdate(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`, selected);
            this._selected = selected;
            this.onSelectedChange.next(this._selected);
            return true;
          }
          return false;
        }),
      );

    }
    return of(true);
  }

  protected _clearCache(): void {
    this._storageService.remove(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`);
    delete this._selected;
    delete this._selected$;
    //delete this._currencies;
    //delete this._currencies$;
  }
}