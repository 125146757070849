import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComManufacturerDetailLineModule } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.module";
import { EComProductsComponent } from "@e-commerce/products/products.component";
import { MfIconModule, MfInputModule, MfTableModule, MfTableSimpleSearchModule } from "@pattonair/material-framework";
//import { EComRecommendedProductsModule } from "./recommended/recommended.products.module";

@NgModule({
  declarations: [
    EComProductsComponent,
  ],
  exports: [
    EComProductsComponent,
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    EComManufacturerDetailLineModule,
    MatButtonModule,
    MatTooltipModule,
    MfIconModule,
    MfInputModule,
    MfTableModule,
    MfTableSimpleSearchModule,
    //EComRecommendedProductsModule,
  ],
  providers: [
  ]
})
export class EComProductsModule { }