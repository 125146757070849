import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ecomCartGetDefaultRequiredDateEarliest, ecomCartGetDefaultRequiredDateMidday } from "@e-commerce/cart/cart";
import { EComRequiredDateDialogComponent } from "@e-commerce/cart/order/dialog/required.date.dialog.component";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComOrderCartLineModel, EComOrderCartRequest } from "@e-commerce/modelConfigs/cart.order.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComOrderCartItemActionResult } from "@e-commerce/services/cart.order.actions";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfModelFieldExtendedConfig, mfObjectGetPropertyPath, ERROR_MESSAGE_REQUIRED, MfTableModelBase, MfModelConfigMapped, MfModelConfigService, MfDialogService, mfTypeIsUndefined, mfTypeIsNullOrUndefined, mfStringIsEmptyOrWhitespace, mfTableReIndexModelFieldExtendedConfig, MfModelValueEditFormatterValueChangeEvent, MfModelValueOutputFormatterTypes } from "@pattonair/material-framework";
import { concatMap, map, Observable } from "rxjs";
import { EComCartModeService } from "@e-commerce/services/cart.mode.service";
import { EComCartBaseComponent } from "../cart.base.component";
import { EComOrderCartValidationItemModel } from "../../modelConfigs/checkout.order.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComCartOrderComponent" };

const ECOM_CART_LOCATION_KEY = "cart.order";
const ECOM_CART_UPDATED_COLOR = "red";


const ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("itemCode")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("site")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("revision")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("unitOfMeasure")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { sortable: false, style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("unitOfMeasureUpdated")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("price")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { sortable: false, style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("priceUpdated")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("currency")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { sortable: false, style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("currencyUpdated")
  },
  {
    valueOutputFormatter: {
      formatter: {
        type: MfModelValueOutputFormatterTypes.numberEditor,
        options: {
          width: 120,
          clearValue: 1,
          minValue: 1,
          validators: [
            {
              validationFunction: { type: "required" },
              validationMessage: { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED }
            }
          ]
        },
      }
    },
    table: {
      index: 0, header: { sortable: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("quantity")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { sortable: false, style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false }
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("availableQuantityUpdated")
  },
  {
    valueOutputFormatter: {
      formatter: {
        type: MfModelValueOutputFormatterTypes.dateEditor,
        options: {
          width: 165,
          hideTime: true,
          min: ecomCartGetDefaultRequiredDateEarliest(),
          validators: [
            {
              validationFunction: { type: "required" },
              validationMessage: { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED }
            }
          ]
        },
      }
    },
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("requiredDate")
  },
  {
    table: {
      index: 0,
      visible: false,
      cell: { style: { color: ECOM_CART_UPDATED_COLOR } },
      header: { sortable: false, style: { color: ECOM_CART_UPDATED_COLOR } },
      showHideOrder: { visible: false },
    },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("requiredDateUpdated")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderCartLineModel>("value")
  },
];

type EComCartLineViewModel = EComOrderCartLineModel & MfTableModelBase & {
  isSelected?: boolean;
};

@Component({
  selector: "ecom-cart-order",
  templateUrl: "./cart.order.component.html",
  styleUrls: ["./cart.order.component.scss"]
})
export class EComCartOrderComponent extends EComCartBaseComponent<EComCartLineViewModel, EComOrderCartValidationItemModel, EComOrderCartItemActionResult> {
  protected _cartLineModelConfig: MfModelConfigMapped;
  protected _cartLineRequestModelConfig: MfModelConfigMapped;
  protected _locationKey = ECOM_CART_LOCATION_KEY;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
    protected _cartModeService: EComCartModeService,
    protected _orderCartService: EComCartOrderService,
    protected override _customerService: EComCustomerService,
    protected _dialogService: MfDialogService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector, _customerService, _orderCartService);
    this._cartModeService.setMode(EComCartModes.order);
    this._cartLineModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartLine");
    this._cartLineRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartLineRequest");
    this._setModelConfigs();
    this._getQueryParams();
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (queryParams) => {
        const autoValidation = coerceBooleanProperty(queryParams[EComRouteNames.queryParamNameAutoValidation]);
        if (!mfTypeIsNullOrUndefined(autoValidation) && autoValidation === true) {
          const loading = this._subLoading(this._checkout(), {
            next: () => {
              loading.complete();
            },
            error: () => {
              loading.complete();
            },
          }, "Validating Cart");
        }
      }
    });
  }

  protected _cartActionCompleted(): Observable<boolean> {
    return this._checkout().pipe(
      map((result) => {
        if (result === true) {
          this._isLoadingInit = false;
          this._tableLoadData();
          return true;
        } else {
          this._clearValidationError();
          this._tableLoadData();
          this._isLoadingInit = false;
          return false;
        }
      }),
    );
  }

  protected _deleteSelectedItems(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._isLoadingInit = true;
      const selectedItems = this._cartLinesTableInit.dataSource.data.filter(i => i.isSelected === true);

      const osb$ = this._orderCartService.remove(selectedItems.map(i => ({ cartItemKey: i.cartItemKey }))).pipe(
        concatMap(() => this._cartActionCompleted()),
      );

      const loading = this._subLoading(osb$, {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        }
      }, "Removing Selected Items From Cart");
    }
  }

  protected _updateRequiredDateSelectedItems(): void {
    this._isLoadingInit = true;
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      const selectedItems = this._cartLinesTableInit.dataSource.data.filter(i => i.isSelected === true);
      this._sub(this._dialogService.openOkCancel({
        disableClose: true,
        data: {
          contentComponent: EComRequiredDateDialogComponent,
          title: "Required Date",
          inputs: {
            requiredDate: ecomCartGetDefaultRequiredDateMidday(),
          },
          outputs: ["requiredDate"]
        },
      }).afterClosed(), {
        next: (result) => {
          if (result.ok === true && !mfTypeIsUndefined(result.outputs)) {
            const requiredDate = result.outputs["requiredDate"];

            const osb$ = this._orderCartService.update(selectedItems.map(i => ({ cartItemKey: i.cartItemKey, requiredDate: requiredDate }))).pipe(
              concatMap(() => this._cartActionCompleted()),
            );

            const loading = this._subLoading(osb$,
              {
                next: () => {
                  loading.complete();
                },
                error: () => {
                  loading.complete();
                },
              },
              "Updating Required Date For Selected Items In Cart"
            );
          }
        }
      });
    }
  }

  protected _rowEditorValueChanged(event: MfModelValueEditFormatterValueChangeEvent<EComCartLineViewModel>): void {
    this._isLoadingInit = true;
    const isDateUpdate = event.fieldPath.endsWith("requiredDate") ? true : false;

    const obs$ = this._orderCartService.update(
      {
        cartItemKey: event.model.cartItemKey,
        quantity: isDateUpdate ? event.model.quantity : event.originalValue as number,
        newQuantity: isDateUpdate ? undefined : event.model.quantity,
        requiredDate: event.model.requiredDate,
      }
    ).pipe(concatMap(() => this._cartActionCompleted()));

    const loading = this._subLoading(obs$,
      {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        },
      },
      "Updating Item In Cart"
    );
  }

  protected _removeFromCart(cartLine: EComCartLineViewModel): void {
    this._isLoadingInit = true;

    const obs$ = this._orderCartService.remove({ cartItemKey: cartLine.cartItemKey }).pipe(
      concatMap(() => this._cartActionCompleted()),
    );

    const loading = this._subLoading(
      obs$,
      {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        },
      },
      "Removing Item From Cart"
    );
  }

  protected _gotoProductDetail(cartLine: EComCartLineViewModel): void {
    this._router.navigate([EComRouteNames.getProductDetailRouteItemSpecKey(cartLine.itemKey, cartLine.cartItemKey)]);
  }

  protected _continueShopping(): void {
    this._router.navigate([EComRouteNames.routeProducts]);
  }

  protected _checkoutOrProceed(): void {
    if (this._hasValidationErrors === false) {
      const loading = this._subLoading(this._checkout(), {
        next: (result) => {
          if (result === false) {
            this._clearValidationError();
            this._router.navigate([EComRouteNames.routeShopCheckoutShippingMethod]);
          }
          loading.complete();
        },
        error: () => {
          loading.complete();
        }
      }, "Validating Cart");
    } else {
      this._router.navigate([EComRouteNames.routeShopCheckoutShippingMethod]);
    }
  }

  protected _checkout(): Observable<boolean> {
    return this._orderCartService.checkout().pipe(
      map(() => this._checkForValidationErrors()),
    );
  }

  protected _loadValidationError(): void {
    this._hasValidationErrors = this._hasBlockingErrors;
    this._validationError = { errors: this._orderCartService.errors, items: this._orderCartService.errorItems };
    this._loadValidationErrorsSetModelStates(this._validationError);
    this._loadValidationErrorsSetColumnStates(this._validationError);
  }

  protected _clearValidationError(): void {
    if (!mfTypeIsUndefined(this._validationError)) {
      this._hasValidationErrors = false;
      this._cartService.clearErrors();
      this._validationError.items = [];
      this._validationError.errors = [];
      this._loadValidationErrorsSetModelStates(this._validationError);
      this._loadValidationErrorsSetColumnStates(this._validationError);
    }
  }

  protected _loadValidationErrorsSetModelStates(validationError: EComOrderCartItemActionResult): void {
    if (!mfTypeIsUndefined(this._cartLinesTable)) {
      if (!mfTypeIsUndefined(validationError) && !mfTypeIsUndefined(validationError.items)) {
        this._cartLinesTable.dataSource.data.forEach((model) => {
          const validationItem = validationError!.items!.find(validationItem => validationItem.cartItemKey === model.cartItemKey);
          if (!mfTypeIsUndefined(validationItem)) {
            model.unitOfMeasureUpdated = validationItem.latestItemUom;
            model.priceUpdated = validationItem.latestPrice;
            model.currencyUpdated = validationItem.latestPriceCurrency;
            model.availableQuantityUpdated = validationItem.latestQuantityAvailable;
            model.requiredDateUpdated = validationItem.isRequiredDateValid === false ? model.requiredDate : undefined;
            model.error = true;
          } else {
            this._clearModelValidationUpdatedFields(model);
          }
        });
      } else {
        if (!mfTypeIsUndefined(this._cartLinesTable)) {
          this._cartLinesTable.dataSource.data.forEach((model) => this._clearModelValidationUpdatedFields(model));
        }
      }
    }
  }

  protected _clearModelValidationUpdatedFields(model: EComCartLineViewModel): void {
    delete model.unitOfMeasureUpdated;
    delete model.priceUpdated;
    delete model.currencyUpdated;
    delete model.availableQuantityUpdated;
    delete model.requiredDateUpdated;
    model.error = false;
  }

  protected _loadValidationErrorsSetColumnStates(validationError: EComOrderCartItemActionResult): void {
    if (!mfTypeIsUndefined(this._cartLinesTable) && !mfTypeIsUndefined(validationError) && !mfTypeIsUndefined(validationError.items)) {
      let unitOfMeasureUpdatedSome = false;
      let priceUpdatedSome = false;
      let currencyUpdatedSome = false;
      let availableQuantitySome = false;
      let requiredDateUpdatedSome = false;

      const length = validationError!.items.length;
      for (let index = 0; index < length; index++) {
        const validationItem = validationError!.items[index];

        if (!mfTypeIsNullOrUndefined(validationItem.latestItemUom) && !mfStringIsEmptyOrWhitespace(validationItem.latestItemUom)) {
          unitOfMeasureUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestPrice)) {
          priceUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestPriceCurrency) && !mfStringIsEmptyOrWhitespace(validationItem.latestPriceCurrency)) {
          currencyUpdatedSome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.latestQuantityAvailable)) {
          availableQuantitySome = true;
        }
        if (!mfTypeIsNullOrUndefined(validationItem.isRequiredDateValid) && validationItem.isRequiredDateValid === false) {
          requiredDateUpdatedSome = true;
        }
        if (unitOfMeasureUpdatedSome === true && priceUpdatedSome === true && currencyUpdatedSome === true && availableQuantitySome === true && requiredDateUpdatedSome === true) {
          break;
        }
      }

      this._setValidationErrorsColumnsVisibleState(unitOfMeasureUpdatedSome, priceUpdatedSome, currencyUpdatedSome, availableQuantitySome, requiredDateUpdatedSome);
    }
  }

  protected _setValidationErrorsColumnsVisibleState(
    unitOfMeasureUpdatedVisible: boolean,
    priceUpdatedVisible: boolean,
    currencyUpdatedVisible: boolean,
    availableQuantityUpdatedVisible: boolean,
    requiredDateUpdatedVisible: boolean
  ): void {
    if (!mfTypeIsUndefined(this._cartLinesTable)) {
      const unitOfMeasureUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComOrderCartLineModel>("unitOfMeasureUpdated"));
      if (!mfTypeIsUndefined(unitOfMeasureUpdated)) {
        unitOfMeasureUpdated.visible = unitOfMeasureUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(unitOfMeasureUpdated);
      }

      const priceUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComOrderCartLineModel>("priceUpdated"));
      if (!mfTypeIsUndefined(priceUpdated)) {
        priceUpdated.visible = priceUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(priceUpdated);
      }

      const currencyUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComOrderCartLineModel>("currencyUpdated"));
      if (!mfTypeIsUndefined(currencyUpdated)) {
        currencyUpdated.visible = currencyUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(currencyUpdated);
      }

      const availableQuantityUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComOrderCartLineModel>("availableQuantityUpdated"));
      if (!mfTypeIsUndefined(availableQuantityUpdated)) {
        availableQuantityUpdated.visible = availableQuantityUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(availableQuantityUpdated);
      }

      const requiredDateUpdated = this._cartLinesTable.findFieldColumnByFieldPath(mfObjectGetPropertyPath<EComOrderCartLineModel>("requiredDateUpdated"));
      if (!mfTypeIsUndefined(requiredDateUpdated)) {
        requiredDateUpdated.visible = requiredDateUpdatedVisible;
        this._cartLinesTable.onFieldColumnVisibleChanged(requiredDateUpdated);
      }
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._cartLineModelConfig, ECOM_CART_ITEM_EXTENDED_MODEL_CONFIG);
  }

  protected _onDataLoaded(): void {
    if (!mfTypeIsUndefined(this._validationError)) {
      this._loadValidationErrorsSetModelStates(this._validationError);
    }
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._cartLinesTableInit.blockDataLoad = true;
      this._cartLinesTableInit.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}`;

      this._cartLinesTableInit.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComOrderCartRequest): Observable<EComOrderCartRequest> => {
    return this._customerService.selected.pipe(
      concatMap((selected) => {
        return this._orderCartService.id.pipe(
          map((cartKey) => {
            data.cartKey = cartKey;
            data.key = selected.key;
            return data;
          })
        );
      })
    );
  };
}