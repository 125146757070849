import { Injectable, Injector } from "@angular/core";
import { MfTypeInfo, MfBaseService } from "@pattonair/material-framework";
import { Subject } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComRootService" };

@Injectable()
export class EComRootService extends MfBaseService {
  public showFooterChanged: Subject<boolean> = new Subject();

  public get showFooter() {
    return this._showFooter;
  }
  public set showFooter(value) {
    if (this._showFooter !== value) {
      this._showFooter = value;
      this.showFooterChanged.next(this._showFooter);
    }
  }

  protected _showFooter = true;

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }
}