import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { EComManufacturerDetailLineComponent } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.component";
import { MfTableModule, MfPipesModule } from "@pattonair/material-framework";

@NgModule({
  declarations: [
    EComManufacturerDetailLineComponent,
  ],
  exports: [
    EComManufacturerDetailLineComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MfTableModule,
    MfPipesModule,
    MatProgressBarModule,
  ],
  providers: [
  ]
})
export class EComManufacturerDetailLineModule { }