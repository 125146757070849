
import { Component, Inject, Injector } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { EComCheckoutStepValidationBaseComponent } from "@e-commerce/checkout/base/checkout.step.validation.base.component";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { MfTypeInfo, ERROR_MESSAGE_REQUIRED } from "@pattonair/material-framework";
import { EComCurrencyService } from "../../../services/currency.service";
import { EComInvoiceCheckoutData } from "../checkout.invoice";
import { EComCartPaymentTypes } from "../../../common/cart.modes";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceBillingPaymentComponent" };

@Component({
  selector: "ecom-invoice-billing-payment",
  templateUrl: "./invoice.billing.payment.component.html",
  styleUrls: ["./invoice.billing.payment.component.scss"]
})
export class EComInvoiceBillingPaymentComponent extends EComCheckoutStepValidationBaseComponent<EComInvoiceCheckoutData> {
  public formGroup: FormGroup = new FormGroup([]);
  protected _paymentAmountFormControl = new FormControl();
  protected readonly _paymentAmountName = "paymentAmount" as const;
  protected _paymentTypeFormControl = new FormControl();
  protected readonly _paymentTypeName = "paymentType" as const;
  protected readonly _paymentTypeCreditCard = "creditCard";

  protected _currency?: string;
  protected _cartPaymentTypes = EComCartPaymentTypes;

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _currencyService: EComCurrencyService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._buildFormGroup();
    this._getSelectedCurrency();
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this.formGroup, this._paymentAmountName, this._paymentAmountFormControl);
    this._addValidation(this._paymentAmountName, this._paymentAmountFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation(this._paymentAmountName, this._paymentAmountFormControl, Validators.min(1), { priority: 1, validatorName: "min", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._paymentAmountName, this._paymentAmountFormControl);
    this._setFormControlValue(this._paymentAmountFormControl, "");

    this._addFormControlToFormGroup(this.formGroup, this._paymentTypeName, this._paymentTypeFormControl);
    this._addValidation(this._paymentTypeName, this._paymentTypeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._paymentTypeName, this._paymentTypeFormControl);
    this._setFormControlValue(this._paymentTypeFormControl, this._paymentTypeCreditCard);

    this._sub(this._paymentAmountFormControl.valueChanges, { next: () => this.checkoutData!.amount = this._paymentAmountFormControl.value });
    this._sub(this._paymentTypeFormControl.valueChanges, {
      next: () => {
        this.checkoutData!.paymentMethod = this._paymentTypeFormControl.value;
        if (this.checkoutData!.paymentMethod === EComCartPaymentTypes.creditCard) {
          this.checkoutData!.paymentMethodName = "Credit Card";
        } else {
          this.checkoutData!.paymentMethodName = "Account (Invoice)";
        }
      }
    });
  }

  protected override _onCheckoutModelSet(model: EComInvoiceCheckoutData): void {
    this._setFormControlValue(this._paymentAmountFormControl, model.amount);
    this._setFormControlValue(this._paymentTypeFormControl, model.paymentMethod);
  }

  protected _getSelectedCurrency(): void {
    this._sub(this._currencyService.selected, {
      next: (selectedCurrency) => {
        this._currency = selectedCurrency.code;
      },
    });
  }
}