import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { MfTypeInfo, MfModelFieldExtendedConfig, MfModelConfigMapped, MfModelConfigService, mfTableReIndexModelFieldExtendedConfig, mfTypeIsUndefined, mfObjectGetPropertyPath, MfTableQueryFilterService, MFModelConfigFieldPath, MfBreadcrumbService, MfTableModelBase, MfModelValueEditFormatterValueChangeEvent } from "@pattonair/material-framework";
import { EComCartModes } from "../../common/cart.modes";
import { EComCartModeService } from "../../services/cart.mode.service";
import { EComCartInvoiceService } from "../../services/cart.invoice.service";
import { EComInvoiceCartLineModel, EComInvoiceCartRequest } from "../../modelConfigs/cart.invoice.model.config";
import { concatMap, map, Observable, of } from "rxjs";
import { EComCustomerService } from "../../services/customer.service";
import { EComCurrencyService } from "../../services/currency.service";
import { EComInvoiceModel } from "../../modelConfigs/invoice.model.config";
import { ECOM_BREADCRUMB_KEY_INVOICES } from "../../ecom.route.keys";
import { ecomGetInvoicesListPayQueryParams } from "../../route.helpers";
import { EComInvoiceCartItemActionResult } from "../../services/cart.incoice.actions";
import { EComCartBaseComponent } from "../cart.base.component";
import { EComInvoiceCartValidationItemModel } from "../../modelConfigs/checkout.invoice.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComCartInvoiceComponent" };

const ECOM_INVOICE_LOCATION_KEY = "cart.invoice";

type EComCartLineViewModel = EComInvoiceCartLineModel & MfTableModelBase & {
  isSelected?: boolean;
};

const ECOM_INVOICE_CART_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("invoiceNumber")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("invoiceDate")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("paymentDueDate")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("currencyCode")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("currencyValue")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("currencyTaxValue")
  },
  {
    table: { index: 0 },
    fieldPath: mfObjectGetPropertyPath<EComCartLineViewModel>("currencyTotalValue")
  },
];

@Component({
  selector: "ecom-cart-invoice",
  templateUrl: "./cart.invoice.component.html",
  styleUrls: ["./cart.invoice.component.scss"]
})
export class EComCartInvoiceComponent extends EComCartBaseComponent<EComCartLineViewModel, EComInvoiceCartValidationItemModel, EComInvoiceCartItemActionResult> {
  protected _invoiceStatusFieldPath: MFModelConfigFieldPath;
  protected _cartLineModelConfig: MfModelConfigMapped;
  protected _cartLineRequestModelConfig: MfModelConfigMapped;
  protected _locationKey = ECOM_INVOICE_LOCATION_KEY;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
    protected _cartModeService: EComCartModeService,
    protected _cartInvoiceService: EComCartInvoiceService,
    protected override _customerService: EComCustomerService,
    protected _tableQueryFilterService: MfTableQueryFilterService,
    protected _breadcrumbService: MfBreadcrumbService,
    protected _currencyService: EComCurrencyService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector, _customerService, _cartInvoiceService);
    this._cartModeService.setMode(EComCartModes.invoice);
    this._invoiceStatusFieldPath = mfObjectGetPropertyPath<EComInvoiceModel>("status");
    this._cartLineModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCartLine");
    this._cartLineRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCartLineRequest");
    this._setModelConfigs();
    this._updateBreadcum();
    this._sub(this._currencyService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  protected _updateBreadcum(): void {
    this._breadcrumbService.update(ECOM_BREADCRUMB_KEY_INVOICES, {
      queryParamsInterceptor: () => ecomGetInvoicesListPayQueryParams(this._tableQueryFilterService)
    });
  }

  protected _goToInvoices(): void {
    this._router.navigate([EComRouteNames.routeInvoicesList], {
      queryParams: ecomGetInvoicesListPayQueryParams(this._tableQueryFilterService)
    });
  }

  protected _removeFromCart(cartInvoice: EComCartLineViewModel): void {
    this._isLoadingInit = true;

    const obs$ = this._cartInvoiceService.remove({ invoiceKey: cartInvoice.documentKey }).pipe(
      concatMap(() => this._cartActionCompleted()),
    );

    const loading = this._subLoading(
      obs$,
      {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        },
      },
      "Removing Item From Cart"
    );
  }

  protected _deleteSelectedItems(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._isLoadingInit = true;
      const selectedItems = this._cartLinesTableInit.dataSource.data.filter(i => i.isSelected === true);

      const osb$ = this._cartInvoiceService.remove(selectedItems.map(i => ({ invoiceKey: i.documentKey }))).pipe(
        concatMap(() => this._cartActionCompleted()),
      );

      const loading = this._subLoading(osb$, {
        next: () => {
          loading.complete();
        },
        error: () => {
          loading.complete();
        }
      }, "Removing Selected Items From Cart");
    }
  }

  protected _proceedToCheckout(): void {
    this._clearValidationError();
    this._router.navigate([EComRouteNames.routeInvoiceCheckoutBillingPayment]);
  }

  protected _rowEditorValueChanged(event: MfModelValueEditFormatterValueChangeEvent<EComCartLineViewModel>): void {
    event.value;
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_INVOICE_CART_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._cartLineModelConfig, ECOM_INVOICE_CART_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._cartLinesTableInit)) {
      this._cartLinesTableInit.blockDataLoad = true;
      this._cartLinesTableInit.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}`;

      this._cartLinesTableInit.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComInvoiceCartRequest): Observable<EComInvoiceCartRequest> => {
    return this._customerService.selected.pipe(
      concatMap((selected) => {
        return this._cartInvoiceService.id.pipe(
          map((cartKey) => {
            data.cartKey = cartKey;
            data.key = selected.key;
            return data;
          })
        );
      })
    );
  };

  protected _cartActionCompleted(): Observable<boolean> {
    this._clearValidationError();
    this._isLoadingInit = false;
    this._tableLoadData();
    return of(true);
  }

  protected _loadValidationError(): void {
    this._hasValidationErrors = this._hasBlockingErrors;
    this._validationError = { errors: this._cartService.errors, items: this._cartService.errorItems };
    this._loadValidationErrorsSetModelStates(this._validationError);
  }

  protected _clearValidationError(): void {
    if (!mfTypeIsUndefined(this._validationError)) {
      this._hasValidationErrors = false;
      this._cartService.clearErrors();
      this._validationError.items = [];
      this._validationError.errors = [];
      this._loadValidationErrorsSetModelStates(this._validationError);
    }
  }

  protected _loadValidationErrorsSetModelStates(validationError: EComInvoiceCartItemActionResult): void {
    if (!mfTypeIsUndefined(this._cartLinesTable)) {
      if (!mfTypeIsUndefined(validationError) && !mfTypeIsUndefined(validationError.items)) {
        this._cartLinesTable.dataSource.data.forEach((model) => {
          const validationItem = validationError!.items!.find(validationItem => validationItem.documentKey === model.documentKey);
          if (!mfTypeIsUndefined(validationItem)) {
            model.error = true;
          } else {
            this._clearModelValidationUpdatedFields(model);
          }
        });
      } else {
        if (!mfTypeIsUndefined(this._cartLinesTable)) {
          this._cartLinesTable.dataSource.data.forEach((model) => this._clearModelValidationUpdatedFields(model));
        }
      }
    }
  }

  protected _clearModelValidationUpdatedFields(model: EComCartLineViewModel): void {
    model.error = false;
  }
}