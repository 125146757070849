import { Component, Inject, Injector, Input } from "@angular/core";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComOrderDocumentModel } from "@e-commerce/modelConfigs/order.document.model.config";
import { EComOrderModel } from "@e-commerce/modelConfigs/order.model.config";
import { EComOrderService } from "@e-commerce/services/order.service";
import { MfTypeInfo, MfBaseComponent, mfTypeIsUndefined } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderDetailDocumentationComponent" };

@Component({
  selector: "ecom-order-documentation",
  templateUrl: "./order.documentation.component.html",
  styleUrls: ["./order.documentation.component.scss"]
})
export class EComOrderDetailDocumentationComponent extends MfBaseComponent {
  @Input()
  public get order(): EComOrderModel | undefined {
    return this._order;
  }
  public set order(value: EComOrderModel | undefined) {
    this._order = value;
    this._getDocuments();
  }

  protected _order?: EComOrderModel | undefined;
  protected _documents?: EComOrderDocumentModel[];
  protected _isLoadingInt: boolean = false;

  public constructor(
    protected override _injector: Injector,
    protected _orderService: EComOrderService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected get _isLoading(): boolean {
    return this._isLoadingInt;
  }

  protected _getDocuments(): void {
    if (!mfTypeIsUndefined(this.order)) {
      this._isLoadingInt = true;
      this._sub(this._orderService.getDocuments(this.order.orderLineKey), {
        next: (documents) => {
          this._documents = documents;
          this._isLoadingInt = false;
        }
      });
    }
  }

  protected _onDownloadClicked(document: EComOrderDocumentModel): void {
    if (!mfTypeIsUndefined(this.order)) {
      this._sub(this._orderService.downloadDocument(this.order.orderLineKey, document));
    }
  }
}