import { NgModule } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComInvoiceBillingPaymentModule } from "@e-commerce/checkout/invoice/billingPayment/invoice.billing.payment.module";
import { EComCheckoutInvoiceComponent } from "@e-commerce/checkout/invoice/checkout.invoice.component";
import { EComInvoiceOrderReviewModule } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.module";
import { EComCheckoutSummaryModule } from "@e-commerce/checkout/summary/checkout.summary.module";

@NgModule({
  declarations: [
    EComCheckoutInvoiceComponent,
  ],
  exports: [
    EComCheckoutInvoiceComponent,
  ],
  imports: [
    BrowserModule,
    EComCheckoutSummaryModule,
    EComInvoiceBillingPaymentModule,
    EComInvoiceOrderReviewModule,
    MatTabsModule,
    RouterModule,
  ],
  providers: [
  ]
})
export class EComCheckoutInvoiceModule { }