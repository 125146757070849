import { EventEmitter, Injectable, Injector } from "@angular/core";
import { Observable, catchError, concatMap, map, of, share } from "rxjs";
import { ECOM_ENVIRONMENT } from "../environments/environment";
import { EComCustomerDetailModel } from "../modelConfigs/customer.detail.model.config";
import { EComCustomerModel } from "../modelConfigs/customer.model.config";
import { EComModelsConfig } from "../modelConfigs/model.config";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, MfLocalStorageService, mfTypeIsUndefined, MfError } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComCustomerService" };

const ECOM_SELECTED_KEY = "selected";


@Injectable({ providedIn: "root" })
export class EComCustomerService extends MfBaseService {
  public onSelectedChange: EventEmitter<EComCustomerModel> = new EventEmitter();

  protected _selected?: EComCustomerModel;
  protected _selected$?: Observable<EComCustomerModel>;
  protected _customerDetailModelConfig: MfModelConfigMapped;
  protected _customerModelConfig: MfModelConfigMapped;
  protected _customers?: EComCustomerModel[];
  protected _customers$?: Observable<EComCustomerModel[]>;
  protected _detail?: EComCustomerDetailModel;
  protected _detail$?: Observable<EComCustomerDetailModel>;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _storageService: MfLocalStorageService,
  ) {
    super(TYPE_INFO, _injector);
    this._customerModelConfig = this._modelConfigService.get<EComModelsConfig>("customer");
    this._customerDetailModelConfig = this._modelConfigService.get<EComModelsConfig>("customerDetail");
  }

  public get hasSelection(): Observable<boolean> {
    return this.selected.pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  public get details(): Observable<EComCustomerDetailModel> {
    if (!mfTypeIsUndefined(this._detail)) {
      return of(this._detail);
    } else {
      if (mfTypeIsUndefined(this._detail$)) {
        this._detail$ = this.selected.pipe(
          concatMap((selectedCustomer) => {
            return this._portalsClientService.getItemGet<EComCustomerDetailModel>(this._customerDetailModelConfig, `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCustomerUrl}/${selectedCustomer.key}/details`)
              .pipe(
                map((customerDetail) => {
                  this._detail = customerDetail;
                  return this._detail;
                }),
              );
          }),
          share(),
        );
      }
      return this._detail$;
    }
  }

  public get selected(): Observable<EComCustomerModel> {
    if (!mfTypeIsUndefined(this._selected)) {
      return of(this._selected);
    } else {
      if (mfTypeIsUndefined(this._selected$)) {
        this._selected$ = this.customers.pipe(
          concatMap((customers) => {
            if (customers.length > 0) {
              const selectedStorage = this._storageService.get<EComCustomerModel>(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`);
              const selectedKey = !mfTypeIsUndefined(selectedStorage) ? selectedStorage.key : customers[0].key;

              return this.setSelectedById(selectedKey).pipe(
                concatMap((result) => {
                  if (result === true) {
                    return of(this._selected as EComCustomerModel);
                  } else {
                    return this.setSelectedById(customers[0].key).pipe(
                      map((result) => {
                        if (result === true) {
                          return this._selected as EComCustomerModel;
                        }
                        throw new MfError(this._typeInfo, "selected", "Unable to set selected customer no default found");
                      })
                    );
                  }
                })
              );
            }

            const error = new MfError(this._typeInfo, "selected", "Unable to get selected customers list is empty");
            error.hideFromSnackBar = true;

            throw error;
          }),
          share(),
        );
      }
      return this._selected$;
    }
  }

  public get customers(): Observable<EComCustomerModel[]> {
    if (mfTypeIsUndefined(this._customers)) {
      if (mfTypeIsUndefined(this._customers$)) {
        this._customers$ = this._portalsClientService.getCollectionGet<EComCustomerModel>(this._customerModelConfig, `${ECOM_ENVIRONMENT.portalsUserRootUrl}/${ECOM_ENVIRONMENT.portalsCustomersUrl}`).pipe(
          map((response: any) => {

            if (!mfTypeIsUndefined(response) && !mfTypeIsUndefined(response.Results)) {
              //TODO: hack until case is fixed
              this._customers = response.Results.map((i: any) => ({ key: i.Key, displayName: i.DisplayName, sourceSystem: i.SourceSystem }));

              if (!mfTypeIsUndefined(this._customers)) {
                return this._customers;
              }
            }

            throw new MfError(this._typeInfo, "customers", `Unable to read customers from response ${response}`);
          }),
          share(),
        );
      }

      return this._customers$;
    } else {
      return of(this._customers);
    }
  }

  public setSelected(selected: EComCustomerModel): Observable<boolean> {
    return this.setSelectedById(selected.key);
  }

  public setSelectedById(key: string): Observable<boolean> {
    if (mfTypeIsUndefined(this._selected) || (!mfTypeIsUndefined(this._selected) && this._selected.key !== key)) {
      return this.customers.pipe(
        map((accounts) => {
          const selected = accounts.find(account => account.key === key);
          if (!mfTypeIsUndefined(selected)) {
            this._storageService.addUpdate(`${this.typeInfo.className}_${ECOM_SELECTED_KEY}`, selected);
            this._selected = selected;
            delete this._detail;
            delete this._detail$;
            this.onSelectedChange.next(this._selected);
            return true;
          }
          return false;
        }),
      );

    }
    return of(true);
  }
}