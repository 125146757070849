import { Injectable, Injector } from "@angular/core";
import { MfTypeInfo, MfBaseService } from "@pattonair/material-framework";
import { EComCartModes } from "../common/cart.modes";
import { Subject } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCartModeService" };

@Injectable({ providedIn: "root" })
export class EComCartModeService extends MfBaseService {
  public onCartModeChanged: Subject<EComCartModes> = new Subject();

  protected _mode: EComCartModes = EComCartModes.order;

  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public get mode(): EComCartModes {
    return this._mode;
  }

  public setMode(mode: EComCartModes): void {
    if (this._mode !== mode) {
      this._mode = mode;
      this.onCartModeChanged.next(this._mode);
    }
  }
}