// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComAddressTypes = {
  residential: 0,
  commercial: 1,
  freightForwarder: 2,
} as const;

export type EComAddressTypes = typeof EComAddressTypes[keyof typeof EComAddressTypes]

export function ecomGetAddressTypesDisplayValue(value: EComAddressTypes): string {
  switch (value) {
    case EComAddressTypes.residential:
      return "Residential";
    case EComAddressTypes.commercial:
      return "Commercial";
    case EComAddressTypes.freightForwarder:
      return "Freight Forwarder";
  }
}