import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComHomeComponent } from "@e-commerce/home/home.component";
import { MFActionCardsModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComHomeComponent
  ],
  exports: [
    EComHomeComponent
  ],
  imports: [
    BrowserModule,
    MFActionCardsModule,
    EComAccountSelectModule,
  ],
  providers: [
  ]
})
export class EComHomeModule { }