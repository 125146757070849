import { MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";


export type EComCurrencyModel = MfModelBase & {
  code: string;
  displayName: string;
}

export type EComCurrencyModelFieldsConfig = {
  [key in keyof Required<EComCurrencyModel>]: MfModelFieldConfig
};

export const ECOM_CURRENCY_MODEL_FIELDS_CONFIG: EComCurrencyModelFieldsConfig = {
  code: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Key"
    }
  },
  displayName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Display Name"
    }
  },
};

export const ECOM_CURRENCY_MODEL_CONFIG_ID = "currency";

export const ECOM_CURRENCY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CURRENCY_MODEL_CONFIG_ID,
  fields: ECOM_CURRENCY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CURRENCY_MODEL_CONFIG",
  },
  portals: {}
};