import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComCartPreviewModule } from "@e-commerce/cartPreview/cart.preview.module";
import { EComFooterModule } from "@e-commerce/footer/footer.module";
import { EComRootUnAuthnComponent } from "@e-commerce/root/unauthn/root.unauthn.component";
import { MfRootModule, MfAuthModule, MfUserPersonaModule, MfIconModule, MfFooterModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComRootUnAuthnComponent
  ],
  exports: [
    EComRootUnAuthnComponent
  ],
  imports: [
    BrowserModule,
    MfRootModule,
    MfAuthModule,
    MfUserPersonaModule,
    MfIconModule,
    MfFooterModule,
    MatButtonModule,
    EComCartPreviewModule,
    RouterModule,
    EComFooterModule,
  ],
})
export class EComRootUnAuthnModule {
}