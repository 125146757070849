import { Component, Inject, Injector, Input } from "@angular/core";
import { EComOrderModel } from "@e-commerce/modelConfigs/order.model.config";
import { MfTypeInfo, MfBaseComponent, MfModelFieldConfigMapped, MfModelConfigMapped, MfModelConfigService, MF_ROOT_CONFIG_TOKEN, MfRootConfig, mfObjectGetPropertyPath } from "@pattonair/material-framework";
import { EComModelsConfig } from "../modelConfigs/model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComOrderDetailDetailsComponent" };

@Component({
  selector: "ecom-order-details",
  templateUrl: "./order.details.component.html",
  styleUrls: ["./order.details.component.scss"]
})
export class EComOrderDetailDetailsComponent extends MfBaseComponent {
  @Input()
  public order?: EComOrderModel;

  protected _orderStatusModelFieldConfig: MfModelFieldConfigMapped;
  protected _orderModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _modelConfigService: MfModelConfigService,
    @Inject(MF_ROOT_CONFIG_TOKEN) public rootConfig: MfRootConfig
  ) {
    super(TYPE_INFO, _injector);
    this._orderModelConfig = this._modelConfigService.get<EComModelsConfig>("order");
    this._orderStatusModelFieldConfig = this._modelConfigService.getModelFieldConfigByFieldKey(this._orderModelConfig.fields, mfObjectGetPropertyPath<EComOrderModel>("status"));
  }
}