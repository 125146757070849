import { EComOrderCartValidationItemModel } from "@e-commerce/modelConfigs/checkout.order.model.config";
import { MfPortalsRequestCollectionData, MfModelFieldConfig, MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";
import { EComErrorValidationItemModel } from "../services/cart.action";

export type EComOrderCartRequest = MfPortalsRequestCollectionData & {
  cartKey?: string;
  key?: string;
}

export type EComOrderCartRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCartRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCartRequestModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG_ID = "orderCartLineRequest";

export const ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartDeleteMultipleItemsRequest = {
  key: string;
  cartKey: string;
  cartItemKeys: string[];
}

export type EComOrderCartDeleteMultipleItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCartDeleteMultipleItemsRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCartDeleteMultipleItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKeys: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID = "orderCartDeleteMultipleItemsRequest";

export const ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};


export type EComOrderCreateCartRequest = {
  key: string;
}

export type EComOrderCreateCartRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCreateCartRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCreateCartRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG_ID = "orderCreateCartRequest";

export const ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderAddCartItemsRequest = {
  key: string;
  items: Omit<EComOrderAddCartItemRequest, "key">[];
}

export type EComOrderAddCartItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderAddCartItemsRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComOrderAddCartItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID = "orderAddCartItemsRequest";

export const ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderAddCartItemRequest = {
  key: string;
  itemSpecKey: string;
  quantity: number;
  requiredDate: Date;
}

export type EComOrderAddCartItemRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderAddCartItemRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG: EComOrderAddCartItemRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemSpecKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  quantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date
    },
  }
};

export const ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID = "orderAddCartItemRequest";

export const ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderUpdateCartItemRequest = {
  key: string;
  cartKey: string;
  cartItemKey: string;
  newQuantity?: number;
  quantity: number;
  requiredDate: Date;
}

export type EComOrderUpdateCartItemRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderUpdateCartItemRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG: EComOrderUpdateCartItemRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  newQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  quantity: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date,
    },
  }
};

export const ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID = "orderUpdateCartItemRequest";

export const ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartUpdateMultipleItemsRequest = {
  key: string;
  cartKey: string;
  cartItemKeys: string[];
  requiredDate: Date;
}

export type EComOrderCartUpdateMultipleItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCartUpdateMultipleItemsRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCartUpdateMultipleItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartItemKeys: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date,
    },
    valueLoadFormatter: {
      dataTypes: {
        date: {

        }
      }
    }
  }
};

export const ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID = "orderCartUpdateMultipleItemsRequest";

export const ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartActionResult = {
  cartKey: string;
  itemCount: number;
  items?: EComOrderCartValidationItemModel[],
  errors?: EComErrorValidationItemModel[];
}

export type EComOrderCartCountResponseModelFieldsConfig = {
  [key in keyof Required<EComOrderCartActionResult>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_RESPONSE_MODEL_FIELDS_CONFIG: EComOrderCartCountResponseModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCount: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
  errors: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG_ID = "orderCartActionResult";

export const ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_RESPONSE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartLineModel = {
  cartItemKey: string,
  itemKey: string;
  createdBy: string;
  createdDate: Date,
  currency: string;
  currencyUpdated?: string;
  itemCode: string;
  modifiedBy: string;
  modifiedDate: Date,
  price: number,
  priceUnitOfMeasure: string;
  priceUnitOfMeasureUpdated?: string;
  priceUpdated?: number,
  quantity: number,
  availableQuantityUpdated?: number,
  requiredDate: Date,
  requiredDateUpdated?: Date,
  revision: string;
  shelfLifeDays: number,
  site: string;
  unitOfMeasure: string;
  unitOfMeasureUpdated?: string;
  value: number;
}

export type EComOrderCartLineModelFieldsConfig = {
  [key in keyof Required<EComOrderCartLineModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_LINE_MODEL_FIELDS_CONFIG: EComOrderCartLineModelFieldsConfig = {
  quantity: {
    display: {
      displayName: "Qty Order",
    },
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  requiredDate: {
    display: {
      displayName: "Required Date",
    },
    dataType: {
      type: MfModelFieldDataTypes.date
    },
  },
  requiredDateUpdated: {
    display: {
      displayName: "Required Date Change",
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  site: {
    display: {
      displayName: "Site",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCode: {
    display: {
      displayName: "Part",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  revision: {
    display: {
      displayName: "Revision",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shelfLifeDays: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  price: {
    display: {
      displayName: "Price",
    },
    dataType: {
      type: MfModelFieldDataTypes.int,
    },
    valueOutputFormatter: {
      currency: {}
    }
  },
  createdDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  modifiedDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  createdBy: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  modifiedBy: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUnitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  unitOfMeasure: {
    display: {
      displayName: "UoM",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currency: {
    display: {
      displayName: "Currency",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  value: {
    display: {
      displayName: "Value",
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    },
    valueOutputFormatter: {
      currency: {}
    }
  },
  currencyUpdated: {
    display: {
      displayName: "Currency Change",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUnitOfMeasureUpdated: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  priceUpdated: {
    display: {
      displayName: "Price Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  availableQuantityUpdated: {
    display: {
      displayName: "Available Quantity Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  unitOfMeasureUpdated: {
    display: {
      displayName: "UoM Changed",
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_ORDER_CART_LINE_MODEL_CONFIG_ID = "orderCartLine";

export const ECOM_ORDER_CART_LINE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_LINE_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_LINE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_LINE_MODEL_CONFIG",
  },
  portals: {}
};