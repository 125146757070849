import { Component, Inject, Injector } from "@angular/core";
import { Params, Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { MfTypeInfo, MfActionCardAction, MfActionCardActions, MfBaseComponent, mfStringIsEmptyOrWhitespace, MfTableQueryFilterService, MFModelConfigFieldPath, mfObjectGetPropertyPath } from "@pattonair/material-framework";
import { EComInvoiceModel } from "../modelConfigs/invoice.model.config";
import { EComCartModeService } from "../services/cart.mode.service";
import { ecomGetInvoicesListPayQueryParams } from "../route.helpers";
import { EComCurrencyService } from "../services/currency.service";


const TYPE_INFO: MfTypeInfo = { className: "EComHomeComponent" };

type EComActionCardAction = MfActionCardAction & {
  redirectUrl?: string;
  queryParams?: Params;
};

type EComActionCardActions = MfActionCardActions & {
  cards: EComActionCardAction[];
};

@Component({
  selector: "ecom-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class EComHomeComponent extends MfBaseComponent {
  protected _actions: EComActionCardActions;
  protected _invoiceStatusFieldPath: MFModelConfigFieldPath;
  private _actionPayInvoices: EComActionCardAction;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _cartModeService: EComCartModeService,
    protected _tableQueryFilterService: MfTableQueryFilterService,
    protected _currencyService: EComCurrencyService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cartModeService.setMode(EComCartModes.order);
    this._invoiceStatusFieldPath = mfObjectGetPropertyPath<EComInvoiceModel>("status");

    this._actionPayInvoices = {
      title: "Pay Invoices",
      description: "Pay your outstanding invoices",
      icon: _config.invoicesIcon,
      redirectUrl: EComRouteNames.routeInvoicesList,
    };

    this._actionPayInvoices.queryParams = ecomGetInvoicesListPayQueryParams(this._tableQueryFilterService);

    this._actions = {
      iconSize: 30,
      minWidth: 300,
      maxWidth: 300,
      cards: [
        {
          title: "Shop",
          description: "Purchase from our extensive range of products",
          icon: _config.shopIcon,
          redirectUrl: EComRouteNames.routeProductsList,
        },
        // {
        //   title: "Upload Order",
        //   description: "Bulk order your requirements to quickly add them to your cart",
        //   icon: _config.uploadOrderIcon,
        //   redirectUrl: "",
        // },
        {
          title: "Order History",
          description: "View your order details",
          icon: _config.orderHistoryIcon,
          redirectUrl: EComRouteNames.routeOrders,
        },
        {
          title: "View Invoices",
          description: "View your invoices and documentation",
          icon: _config.invoicesIcon,
          redirectUrl: EComRouteNames.routeInvoicesList,
        },
        //this._actionPayInvoices,
      ]
    };
  }

  protected _actionClicked(action: EComActionCardAction): void {
    if (!mfStringIsEmptyOrWhitespace(action.redirectUrl)) {
      this._router.navigate([action.redirectUrl], { queryParams: action.queryParams });
    }
  }
}