import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComOrderDetailModule } from "@e-commerce/orderDetail/order.detail.module";
import { EComOrdersComponent } from "@e-commerce/orders/orders.component";
import { MfTableModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComOrdersComponent
  ],
  exports: [
    EComOrdersComponent
  ],
  imports: [
    BrowserModule,
    MfTableModule,
    EComAccountSelectModule,
    EComOrderDetailModule,
  ],
  providers: [
  ]
})
export class EComOrdersModule { }