<div class="mf-row ecom-checkout-container">
  <div class="mf-column ecom-step-container">
    <div class=" ecom-step-select">
      <nav mat-tab-nav-bar
           [tabPanel]="tabPanel">
        <a mat-tab-link
           [ngClass]="{ 'mf-error': rlaShippingMethodLink.isActive === true && _formGroup?.invalid }"
           [disabled]="_lockNavigation === true || rlaShippingMethodLink.isActive === false && _formGroup?.invalid"
           [routerLink]="_ecomRouteNames.partNameShippingMethod"
           routerLinkActive
           #rlaShippingMethodLink="routerLinkActive"
           [active]="rlaShippingMethodLink.isActive"> Shipping Method </a>
        <a mat-tab-link
           [ngClass]="{ 'mf-error': rlaBillingAddressLink.isActive === true && _formGroup?.invalid }"
           [disabled]="_lockNavigation === true || rlaBillingAddressLink.isActive === false && _formGroup?.invalid"
           [routerLink]="_ecomRouteNames.partNameBillingPayment"
           routerLinkActive
           #rlaBillingAddressLink="routerLinkActive"
           [active]="rlaBillingAddressLink.isActive"> Billing & Payment </a>
        <a mat-tab-link
           [ngClass]="{ 'mf-error': rlaOrderReviewLink.isActive === true && _formGroup?.invalid }"
           [disabled]="_lockNavigation === true || rlaOrderReviewLink.isActive === false && _formGroup?.invalid"
           [routerLink]="_ecomRouteNames.partNameReview"
           routerLinkActive
           #rlaOrderReviewLink="routerLinkActive"
           [active]="rlaOrderReviewLink.isActive"> Order Review </a>
      </nav>
    </div>
    <div class="ecom-step-panel">
      <mat-tab-nav-panel #tabPanel>
        <router-outlet (activate)="_routerActivate()"></router-outlet>
      </mat-tab-nav-panel>
    </div>
  </div>
  <div class="ecom-summary-container">
    <ecom-checkout-summary [formGroup]="_formGroup"
                           [buttonLabel]="_summaryButtonLabel"
                           [showTC]="_summaryShowTC"
                           [checkoutData]="_checkoutData"
                           title="Order Summary"
                           tcValue="By placing the order, I am confirming that I have read and agree with the"
                           [tcLink]="_linksConfig.termsOfServiceUrl"
                           (onContinueClick)="_summaryContinueClick()"></ecom-checkout-summary>
  </div>
</div>