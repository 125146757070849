import { NgModule } from "@angular/core";
import { EComCarrierService } from "@e-commerce/services/carrier.service";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCheckoutOrderService } from "@e-commerce/services/checkout.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComInvoiceService } from "@e-commerce/services/invoice.service";
import { EComManufacturerService } from "@e-commerce/services/manufacturer.service";
import { EComOrderService } from "@e-commerce/services/order.service";
import { EComProductService } from "@e-commerce/services/product.service";
import { EComUserService } from "@e-commerce/services/user.service";
import { EComCartInvoiceService } from "@e-commerce/services/cart.invoice.service";
import { EComCartModeService } from "@e-commerce/services/cart.mode.service";
import { EComCurrencyService } from "@e-commerce/services/currency.service";
import { EComCheckoutInvoiceService } from "./checkout.invoice.service";

@NgModule({
  imports: [
  ],
  providers: [
    EComCarrierService,
    EComCartInvoiceService,
    EComCartModeService,
    EComCartOrderService,
    EComCheckoutInvoiceService,
    EComCheckoutOrderService,
    EComCurrencyService,
    EComCustomerService,
    EComInvoiceService,
    EComManufacturerService,
    EComOrderService,
    EComProductService,
    EComUserService,
  ]
})
export class EComServiceModule { }