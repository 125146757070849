import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComInvoiceDocumentDownloadInfoModel, EComInvoiceDownloadDocumentRequest } from "@e-commerce/modelConfigs/invoice.document.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, mfFileNameClean } from "@pattonair/material-framework";
import * as saveAs from "file-saver";
import { concatMap, map, Observable } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoiceService" };

type EComInvoiceData = {
  documentKey: string;
  documentNumber: string;
}

@Injectable({ providedIn: "root" })
export class EComInvoiceService extends MfBaseService {
  protected _invoiceDocumentDownloadInfoModelConfig: MfModelConfigMapped;
  protected _invoiceDownloadDocumentRequestModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._invoiceDocumentDownloadInfoModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceDocumentDownloadInfo");
    this._invoiceDownloadDocumentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceDownloadDocumentRequest");
  }

  public downloadDocument(document: EComInvoiceData): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._getDownloadInfo(selectedCustomer.key, document).pipe(
          concatMap((orderDocumentDownloadInfo) => {
            return this._downloadDocument(orderDocumentDownloadInfo, document);
          }),
        );
      })
    );
  }

  protected _getDownloadInfo(customerKey: string, document: EComInvoiceData): Observable<EComInvoiceDocumentDownloadInfoModel> {
    return this._portalsClientService.getItemGet<EComInvoiceDocumentDownloadInfoModel>(
      this._invoiceDocumentDownloadInfoModelConfig,
      `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${customerKey}/${ECOM_ENVIRONMENT.portalsInvoicesUrl}/${document.documentKey}/download`
    );
  }

  protected _downloadDocument(invoiceDocumentDownloadInfo: EComInvoiceDocumentDownloadInfoModel, document: EComInvoiceData): Observable<boolean> {
    const data: EComInvoiceDownloadDocumentRequest = {
      key: invoiceDocumentDownloadInfo.payLoad.key,
      documentKey: invoiceDocumentDownloadInfo.payLoad.documentKey,
    };

    return this._portalsClientService.getFileBlobPost<EComInvoiceDownloadDocumentRequest>(
      this._invoiceDownloadDocumentRequestModelConfig,
      data,
      `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${invoiceDocumentDownloadInfo.downloadUrl}`,
    ).pipe(
      map((response) => {
        saveAs(response.blob, mfFileNameClean(`${document.documentNumber}.${response.extension}`));
        return true;
      })
    );
  }
}