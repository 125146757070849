import { Component, EventEmitter, Injector, Input, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { EComOrderCheckoutData } from "@e-commerce/checkout/order/checkout.order";
import { MfTypeInfo, MfBaseComponent, MfModelConfigService, MfModelConfigMapped, mfObjectGetPropertyPath, MFModelConfigFieldPath } from "@pattonair/material-framework";
import { EComInvoiceCheckoutData } from "../invoice/checkout.invoice";
import { EComModelsConfig } from "../../modelConfigs/model.config";
import { ActivatedRoute } from "@angular/router";
import { EComCartModes } from "../../common/cart.modes";
import { tap } from "rxjs";


const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutSummaryComponent" };

@Component({
  selector: "ecom-checkout-summary",
  templateUrl: "./checkout.summary.component.html",
  styleUrls: ["./checkout.summary.component.scss"]
})
export class EComCheckoutSummaryComponent extends MfBaseComponent {
  @Input()
  public formGroup?: FormGroup;

  @Input()
  public buttonLabel?: string;

  @Input()
  public title?: string;

  @Input()
  public showTC?: boolean;

  @Input()
  public tcValue?: string;

  @Input()
  public tcLink?: string;

  @Input()
  public checkoutData?: EComOrderCheckoutData | EComInvoiceCheckoutData;

  @Output()
  public onContinueClick: EventEmitter<void> = new EventEmitter();

  public tcChecked: boolean = false;

  protected _checkoutDataModelConfig?: MfModelConfigMapped;
  protected _itemsCountFieldPath?: MFModelConfigFieldPath;
  protected _totalQtyFieldPath?: MFModelConfigFieldPath;
  protected _currencyCodeFieldPath?: MFModelConfigFieldPath;
  protected _subTotalFieldPath?: MFModelConfigFieldPath;
  protected _totalFieldPath?: MFModelConfigFieldPath;
  protected _invoiceTotalFieldPath?: MFModelConfigFieldPath;
  protected _paymentAmountFieldPath?: MFModelConfigFieldPath;
  protected _currencyAmountFieldPath?: MFModelConfigFieldPath;
  protected _currencyTaxAmountFieldPath?: MFModelConfigFieldPath;
  protected _currencyTotalAmountFieldPath?: MFModelConfigFieldPath;
  protected _currentOpenAmountFieldPath?: MFModelConfigFieldPath;
  protected _amountToPayFieldPath?: MFModelConfigFieldPath;
  protected _resultingAmountFieldPath?: MFModelConfigFieldPath;

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
  ) {
    super(TYPE_INFO, _injector);
    this._getParams();
  }

  public continueClick(): void {
    this.onContinueClick.next();
  }

  public tcCheckedChanged(event: MatCheckboxChange): void {
    this.tcChecked = event.checked;
  }

  protected _getParams(): void {
    this._sub(this._activatedRoute.data.pipe(
      tap((data) => {
        const mode = data["mode"];

        if (mode === EComCartModes.invoice) {
          this._checkoutDataModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCheckoutData");
        } else {
          this._checkoutDataModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCheckoutData");
        }

        this._itemsCountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.itemsCount");
        this._totalQtyFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.totalQty");
        this._currencyCodeFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.currencyCode");
        this._subTotalFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.subTotal");
        this._totalFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.total");
        this._invoiceTotalFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.invoiceTotal");
        this._paymentAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.paymentAmount");
        this._currencyAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.currencyAmount");
        this._currencyTaxAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.currencyTaxAmount");
        this._currencyTotalAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.currencyTotalAmount");
        this._currentOpenAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.currentOpenAmount");
        this._amountToPayFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.amountToPay");
        this._resultingAmountFieldPath = mfObjectGetPropertyPath<EComOrderCheckoutData>("summery.resultingAmount");
      }),
    ));
  }
}