import { Injectable, Injector } from "@angular/core";
import { MfTypeInfo, MfBaseService } from "@pattonair/material-framework";
import { Observable, of } from "rxjs";
import { EComRecommendedProductModel } from "../../modelConfigs/recommended.product.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComRecommendedProductsService" };

export const TEMP_DATA: EComRecommendedProductModel[] = [
  {
    itemCode: "1 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "2 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "3 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "4 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "5 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "6 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "7 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "8 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "9 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
  {
    itemCode: "10 A Big Bolt",
    itemDescription: "A bolt big enough for all you needs",
    imageUrl: "https://upload.wikimedia.org/wikipedia/commons/2/21/Big_Bolt_and_Nut.jpg",
  },
];

@Injectable()
export class EComRecommendedProductsService extends MfBaseService {
  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
  }

  public get(): Observable<EComRecommendedProductModel[]> {
    return of(TEMP_DATA);
  }
}