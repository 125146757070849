import { Params } from "@angular/router";
import { MfFilterOperatorTypes, mfObjectGetPropertyPath, MfTableQueryFilterService } from "@pattonair/material-framework";
import { ECOM_INVOICES_LOCATION_KEY } from "./invoices/invoices.component";
import { EComInvoiceStatusesTypes } from "./common/invoice.status";
import { EComInvoiceModel } from "./modelConfigs/invoice.model.config";

export function ecomGetInvoicesListPayQueryParams(tableQueryFilterService: MfTableQueryFilterService): Params {
  return {
    [tableQueryFilterService.getQueryParamName(mfObjectGetPropertyPath<EComInvoiceModel>("status"), MfFilterOperatorTypes.eq, ECOM_INVOICES_LOCATION_KEY)]: EComInvoiceStatusesTypes.approvedForPayment,
    pay: true,
  };
}
