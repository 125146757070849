import { EComCarrierModel } from "@e-commerce/modelConfigs/carrier.model.config";
import { EComCustomerDetailModel } from "@e-commerce/modelConfigs/customer.detail.model.config";
import { EComOrderCartCompleteAction } from "@e-commerce/services/cart.order.actions";
import { EComCheckoutSummery } from "../checkout.summery";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";

export type EComOrderCheckoutData = EComOrderCartCompleteAction & {
  customerDetail?: EComCustomerDetailModel,
  carriers: EComCarrierModel[];
  summery: EComCheckoutSummery;
  carrierName?: string;
  paymentMethodName?: string;
}

export type EComOrderCheckoutDataFieldsConfig = {
  [key in keyof Required<EComOrderCheckoutData>]: MfModelFieldConfig
};

export const ECOM_ORDER_CHECKOUT_DATA_MODEL_FIELDS_CONFIG: EComOrderCheckoutDataFieldsConfig = {
  customerDetail: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  carriers: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
  summery: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  carrierName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethodName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  purchaseOrderRef: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG_ID = "orderCheckoutData";

export const ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CHECKOUT_DATA_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG",
  },
  portals: {
    mock: {}
  }
};