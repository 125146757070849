import { EComOrderStatusesTypes, eComOrderStatusesTypeToDisplayName } from "@e-commerce/common/order.status";
import { MfPortalsRequestCollectionData, MfModelFieldConfig, MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfModelFieldDataTypes, MfModelConfig, MfModelBase, MfModelValueOutputFormatterTypes } from "@pattonair/material-framework";

export type EComOrderRequest = MfPortalsRequestCollectionData & {
  customerOrder?: string;
  itemCode?: string;
  key?: string;
  orderNumber?: string;
  status?: string;
}

export type EComOrderRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_REQUEST_MODEL_FIELDS_CONFIG: EComOrderRequestModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  customerOrder: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  status: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  }
};

export const ECOM_ORDER_REQUEST_MODEL_CONFIG_ID = "orderRequest";

export const ECOM_ORDER_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderModel = MfModelBase & {
  actionQuantity: string;
  carrierAccountComposite: string;
  customerOrder: string;
  customerOrderLine: string;
  itemCode: string;
  itemCodeShort: string;
  itemDescription: string;
  orderCompany: string;
  orderLineKey: string;
  orderLineNumber: number;
  orderNumber: string;
  orderQuantity: number;
  orderType: string;
  portalTransactionNumber: number;
  promiseDate?: Date;
  requiredDate?: Date;
  revision: string;
  shipDate?: Date;
  shippedQuantity: number;
  status: EComOrderStatusesTypes;
  statusText: string;
  trackingNumber: string;
  uniqueIdentifier: number;
  unitOfMeasure: string;
}

export type EComOrderModelFieldsConfig = {
  [key in keyof Required<EComOrderModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_MODEL_FIELDS_CONFIG: EComOrderModelFieldsConfig = {
  actionQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Action Qty"
    }
  },
  carrierAccountComposite: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  customerOrder: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Customer Order"
    }
  },
  customerOrderLine: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Customer Order Line"
    }
  },
  itemCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Part"
    }
  },
  itemCodeShort: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  itemDescription: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderCompany: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderLineKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  orderLineNumber: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Incora Order Line"
    }
  },
  orderNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Incora Order"
    }
  },
  orderQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Order Qty"
    }
  },
  orderType: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  portalTransactionNumber: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  promiseDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    }
  },
  requiredDate: {
    dataType: {
      type: MfModelFieldDataTypes.date
    },
    display: {
      displayName: "Req Date"
    }
  },
  revision: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Revision"
    }
  },
  shipDate: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    }
  },
  shippedQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
    display: {
      displayName: "Shipped Qty"
    }
  },
  status: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    display: {
      displayName: "Status"
    },
    valueOutputFormatter: {
      formatter: {
        type: MfModelValueOutputFormatterTypes.statusChip,
      },
      statusChip: {
        statuses: [{
          color: "#ff0000",
          displayName: eComOrderStatusesTypeToDisplayName(EComOrderStatusesTypes.cancelled),
          value: EComOrderStatusesTypes.cancelled
        }, {
          color: "#00ff00",
          displayName: eComOrderStatusesTypeToDisplayName(EComOrderStatusesTypes.held),
          value: EComOrderStatusesTypes.held
        }, {
          color: "#0000ff",
          displayName: eComOrderStatusesTypeToDisplayName(EComOrderStatusesTypes.placed),
          value: EComOrderStatusesTypes.placed
        }, {
          color: "#00ffff",
          displayName: eComOrderStatusesTypeToDisplayName(EComOrderStatusesTypes.shipped),
          value: EComOrderStatusesTypes.shipped
        }, {
          color: "#00ff55",
          displayName: eComOrderStatusesTypeToDisplayName(EComOrderStatusesTypes.inProgress),
          value: EComOrderStatusesTypes.inProgress
        }]
      }
    },
  },
  statusText: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  trackingNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  },
  uniqueIdentifier: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    }
  },
  unitOfMeasure: {
    dataType: {
      type: MfModelFieldDataTypes.string
    }
  }
};

export const ECOM_ORDER_MODEL_CONFIG_ID = "order";

export const ECOM_ORDER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_MODEL_CONFIG",
  },
  portals: {}
};