<div class="ecom-order-summary-container">
  <div class="ecom-title mat-headline-5">
    {{title}}
  </div>
  <div class="ecom-summary">
    <table cellPadding="0"
           cellSpacing="0">

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.itemsCount)">
        <td class="ecom-label">Items</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_itemsCountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.totalQty)">
        <td class="ecom-label">Total Qty</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_totalQtyFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.currencyCode)">
        <td class="ecom-label">Currency</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_currencyCodeFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.invoiceTotal)">
        <td class="ecom-label">Invoice Total</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_invoiceTotalFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.paymentAmount)">
        <td class="ecom-label">Payment Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_paymentAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.currencyAmount)">
        <td class="ecom-label">Currency Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_currencyAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.currencyTaxAmount)">
        <td class="ecom-label">Currency Tax Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_currencyTaxAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.currencyTotalAmount)">
        <td class="ecom-label">Currency Total Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_currencyTotalAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.currentOpenAmount)">
        <td class="ecom-label">Current Open Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_currentOpenAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.amountToPay)">
        <td class="ecom-label">Amount To Pay</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_amountToPayFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.resultingAmount)">
        <td class="ecom-label">Resulting Open Amount</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_resultingAmountFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.subTotal)">
        <td class="ecom-label">Subtotal</td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_subTotalFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

      <tr *ngIf="!_isUndefined(checkoutData?.summery?.total)"
          class="ecom-total">
        <td class="ecom-label">
          Total Value
        </td>
        <td class="ecom-value">
          <mf-model-value-output [model]="checkoutData"
                                 [fieldPath]="_totalFieldPath"
                                 [modelConfig]="_checkoutDataModelConfig"></mf-model-value-output>
        </td>
      </tr>

    </table>
  </div>
  <div class="mf-large-margin-top"
       [style.visibility]="showTC === true ? 'unset' : 'hidden'">
    <mat-checkbox style="max-width: 280px;"
                  (change)="tcCheckedChanged($event)"
                  [checked]="tcChecked">
      {{ tcValue }} <a *ngIf="!_isEmptyOrWhitespace(tcLink)"
         [href]="tcLink"
         target="_blank">Terms & Conditions</a>
    </mat-checkbox>
  </div>
  <div class="ecom-actions">
    <button [disabled]="(showTC === true && tcChecked === false) || _isUndefined(formGroup) || formGroup.invalid"
            (click)="continueClick()"
            color="accent"
            class="mf-x-large-margin-top"
            style="width: 100%;"
            mat-raised-button>{{buttonLabel}}</button>
  </div>
</div>