import { DOCUMENT } from "@angular/common";
import { Inject, Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ecomCartGetDefaultQty, ecomCartGetDefaultRequiredDateMidday } from "@e-commerce/cart/cart";
import { EComCartPaymentTypes } from "@e-commerce/common/cart.modes";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComOrderAddCartItemRequest, EComOrderAddCartItemsRequest, EComOrderCartActionResult, EComOrderCartDeleteMultipleItemsRequest, EComOrderCartUpdateMultipleItemsRequest, EComOrderCreateCartRequest, EComOrderUpdateCartItemRequest } from "@e-commerce/modelConfigs/cart.order.model.config";
import { EComOrderCompleteModel, EComOrderCartValidationModel, EComOrderCheckoutRequestModel, EComOrderCartValidationItemModel } from "@e-commerce/modelConfigs/checkout.order.model.config";
import { EComOrderCheckoutBeginPaymentModel, EComOrderCheckoutBeginPaymentRequestModel, EComOrderCheckoutEndPaymentRequestModel } from "@e-commerce/modelConfigs/checkout.order.payment.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComOrderCartBeginPaymentActions, EComOrderCartBeginPaymentAction, EComOrderCartCompleteActions, EComOrderCartCompleteAction, EComOrderCartEndPaymentAction, EComOrderCartItemActionResult, EComOrderCartItemAddActions, EComOrderCartItemAddAction, EComOrderCartItemRemoveActions, EComOrderCartItemRemoveAction, EComOrderCartItemUpdateActions, EComOrderCartItemUpdateAction, EComOrderCartItemUpdateRequiredDateAction } from "@e-commerce/services/cart.order.actions";
import { MfTypeInfo, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, mfTypeIsUndefined, MfError, mfTypeIsArray, mfTypeIsNullOrUndefined } from "@pattonair/material-framework";
import { catchError, concatMap, map, Observable, of, share, tap } from "rxjs";
import { EComCartBaseService } from "./cart.base.service";

const TYPE_INFO: MfTypeInfo = { className: "EComCartOrderService" };

@Injectable({ providedIn: "root" })
export class EComCartOrderService extends EComCartBaseService<EComOrderCartValidationItemModel> {
  protected _addCartItemRequestModelConfig: MfModelConfigMapped;
  protected _addCartItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartActionResultModelConfig: MfModelConfigMapped;
  protected _cartDeleteMultipleItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartUpdateMultipleItemsRequestModelConfig: MfModelConfigMapped;
  protected _cartValidationModelConfig: MfModelConfigMapped;
  protected _checkoutBeginPaymentModelConfig: MfModelConfigMapped;
  protected _checkoutBeginPaymentResponseModelConfig: MfModelConfigMapped;
  protected _checkoutEndPaymentRequestModelConfig: MfModelConfigMapped;
  protected _checkoutRequestModel: MfModelConfigMapped;
  protected _completeModelConfig: MfModelConfigMapped;
  protected _createCartRequestModelConfig: MfModelConfigMapped;
  protected _updateCartItemRequestModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    @Inject(DOCUMENT)
    protected _document: Document,
  ) {
    super(TYPE_INFO, _injector);
    this._addCartItemRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderAddCartItemRequest");
    this._addCartItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderAddCartItemsRequest");
    this._cartActionResultModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartActionResult");
    this._cartDeleteMultipleItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartDeleteMultipleItemsRequest");
    this._cartUpdateMultipleItemsRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartUpdateMultipleItemsRequest");
    this._cartValidationModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCartValidation");
    this._checkoutBeginPaymentModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCheckoutBeginPayment");
    this._checkoutBeginPaymentResponseModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCheckoutBeginPaymentRequest");
    this._checkoutEndPaymentRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCheckoutEndPaymentRequest");
    this._checkoutRequestModel = this._modelConfigService.get<EComModelsConfig>("orderCheckoutRequest");
    this._completeModelConfig = this._modelConfigService.get<EComModelsConfig>("orderComplete");
    this._createCartRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCreateCartRequest");
    this._updateCartItemRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderUpdateCartItemRequest");
  }

  public get count(): Observable<number> {
    return this._getCount();
  }

  public get id(): Observable<string> {
    return of(this._idInt).pipe(
      concatMap((id) => {
        if (!mfTypeIsUndefined(id)) {
          return of(id);
        } else {
          return this._getCreateCart();
        }
      }),
      share(),
    );
  }

  public checkout(): Observable<EComOrderCartValidationModel> {
    return this._checkout();
  }

  public complete(action: EComOrderCartCompleteActions): Observable<boolean> {
    return this._complete(action).pipe(
      map((result) => {
        return !this._redirectIfValidationErrors(result);
      })
    );
  }

  public beginPayment(action: EComOrderCartBeginPaymentActions): Observable<boolean> {
    return this._beginPayment(action).pipe(
      map((result) => {
        if (!this._redirectIfValidationErrors(result)) {
          if (!mfTypeIsNullOrUndefined(result.paymentUrl) && action.paymentMethod === EComCartPaymentTypes.creditCard) {
            this._document.location.href = result.paymentUrl;
            return true;
          } else if (action.paymentMethod === EComCartPaymentTypes.account) {
            return true;
          } else {
            throw new MfError(this._typeInfo, "beginPayment", "Unabel to determin payment routing");
          }
        }
        return false;
      })
    );
  }

  public endPayment(action: EComOrderCartEndPaymentAction): Observable<boolean> {
    return this._endPayment(action).pipe(
      map((endPaymentResult) => {
        this._router.navigate([EComRouteNames.routeShopCheckoutReview], {
          queryParams: { [EComRouteNames.queryParamNameLockNavigation]: true }
        }
        );
        return endPaymentResult;
      })
    );
  }

  public add(actions: EComOrderCartItemAddActions): Observable<EComOrderCartItemActionResult> {
    let add$: Observable<EComOrderCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      add$ = this._addItems(actions);
    } else {
      add$ = this._addItem(actions);
    }

    return add$.pipe(
      tap((result) => {
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public remove(actions: EComOrderCartItemRemoveActions): Observable<EComOrderCartItemActionResult> {
    let remove$: Observable<EComOrderCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      remove$ = this._removeItems(actions);
    } else {
      remove$ = this._removeItem(actions);
    }

    return remove$.pipe(
      tap((result) => {
        this._redirectIfValidationErrors(result);
      })
    );
  }

  public update(actions: EComOrderCartItemUpdateActions): Observable<EComOrderCartItemActionResult> {
    let update$: Observable<EComOrderCartItemActionResult>;

    if (mfTypeIsArray(actions)) {
      update$ = this._updateItems(actions);
    } else {
      update$ = this._updateItem(actions);
    }

    return update$.pipe(
      tap((result) => {
        this._redirectIfValidationErrors(result);
      })
    );
  }

  protected _checkout(): Observable<EComOrderCartValidationModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCheckoutRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
            };

            return this._portalsClientService.getItemPost<EComOrderCartValidationModel, EComOrderCheckoutRequestModel>(
              this._cartValidationModelConfig,
              this._checkoutRequestModel,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/checkout`)
              .pipe(
                map((checkoutResponse) => {
                  return checkoutResponse;
                })
              );
          })
        );
      })
    );
  }

  protected _complete(checkout: EComOrderCartCompleteAction): Observable<EComOrderCartValidationModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCompleteModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              carrierKey: checkout.carrierKey,
              paymentMethod: checkout.paymentMethod,
              purchaseOrderRef: checkout.purchaseOrderRef,
            };

            return this._portalsClientService.getItemPost<EComOrderCartValidationModel, EComOrderCompleteModel>(
              this._cartValidationModelConfig,
              this._completeModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/complete`)
              .pipe(
                map((cartResponse) => {
                  this._clearCache();
                  this.onCountChanged.next(0);
                  return cartResponse;
                })
              );
          })
        );
      })
    );
  }

  protected _beginPayment(action: EComOrderCartBeginPaymentAction): Observable<EComOrderCheckoutBeginPaymentModel> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCheckoutBeginPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              carrierKey: action.carrierKey,
              purchaseOrderRef: action.purchaseOrderRef,
              paymentMethod: action.paymentMethod,
            };

            return this._portalsClientService.getItemPost<EComOrderCheckoutBeginPaymentModel, EComOrderCheckoutBeginPaymentRequestModel>(
              this._checkoutBeginPaymentModelConfig,
              this._checkoutBeginPaymentResponseModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/beginPayment`
            );
          })
        );
      })
    );
  }

  protected _endPayment(action: EComOrderCartEndPaymentAction): Observable<boolean> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCheckoutEndPaymentRequestModel = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              paymentInfo: action.paymentInfo,
            };

            return this._portalsClientService.post<EComOrderCheckoutEndPaymentRequestModel>(
              this._checkoutEndPaymentRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/endPayment`
            );
          })
        );
      })
    );
  }

  protected _addItem(action: EComOrderCartItemAddAction): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComOrderAddCartItemRequest = {
          key: selectedCustomer.key,
          itemSpecKey: action.itemSpecKey,
          quantity: action.quantity || ecomCartGetDefaultQty(),
          requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
        };

        return this._portalsClientService.getItemPost<EComOrderCartActionResult, EComOrderAddCartItemRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/add`)
          .pipe(
            map((cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            })
          );
      }),
    );
  }

  protected _addItems(actions: EComOrderCartItemAddAction[]): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const data: EComOrderAddCartItemsRequest = {
          key: selectedCustomer.key,
          items: actions.map(action => ({
            itemSpecKey: action.itemSpecKey,
            quantity: action.quantity || ecomCartGetDefaultQty(),
            requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
          })),
        };

        return this._portalsClientService.updateItemPost<EComOrderCartActionResult, EComOrderAddCartItemsRequest>(
          this._cartActionResultModelConfig,
          this._addCartItemsRequestModelConfig,
          data,
          `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/addmulti`)
          .pipe(map(
            (cartResponse) => {
              this._count = cartResponse.itemCount;
              this.onCountChanged.next(this._count);
              return cartResponse;
            }
          ));
      }),
    );
  }

  protected _updateItem(action: EComOrderCartItemUpdateAction): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderUpdateCartItemRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKey: action.cartItemKey,
              newQuantity: action.newQuantity,
              quantity: action.quantity,
              requiredDate: action.requiredDate || ecomCartGetDefaultRequiredDateMidday()
            };

            return this._portalsClientService.updateItemPut<EComOrderCartActionResult, EComOrderUpdateCartItemRequest>(
              this._cartActionResultModelConfig,
              this._updateCartItemRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          })
        );
      }),
    );
  }

  protected _updateItems(actions: EComOrderCartItemUpdateRequiredDateAction[]): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCartUpdateMultipleItemsRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKeys: actions.map(i => i.cartItemKey),
              requiredDate: actions[0].requiredDate,
            };

            return this._portalsClientService.updateItemPut<EComOrderCartActionResult, EComOrderCartUpdateMultipleItemsRequest>(
              this._cartActionResultModelConfig,
              this._cartUpdateMultipleItemsRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}/requireddate`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          })
        );
      }),
    );
  }

  protected _removeItem(action: EComOrderCartItemRemoveAction): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            return this._portalsClientService.deleteItemDelete<EComOrderCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${cartKey}//${ECOM_ENVIRONMENT.portalsLinesUrl}/${action.cartItemKey}`)
              .pipe(map(
                (cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                }
              ));
          }),
        );
      }),
    );
  }

  protected _removeItems(action: EComOrderCartItemRemoveAction[]): Observable<EComOrderCartItemActionResult> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this.id.pipe(
          concatMap((cartKey) => {
            const data: EComOrderCartDeleteMultipleItemsRequest = {
              key: selectedCustomer.key,
              cartKey: cartKey,
              cartItemKeys: action.map(i => i.cartItemKey)
            };
            return this._portalsClientService.getItemPost<EComOrderCartActionResult, EComOrderCartDeleteMultipleItemsRequest>(
              this._cartActionResultModelConfig,
              this._cartDeleteMultipleItemsRequestModelConfig,
              data,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${ECOM_ENVIRONMENT.portalsLinesUrl}/delete`)
              .pipe(
                map((cartResponse) => {
                  this._count = cartResponse.itemCount;
                  this.onCountChanged.next(this._count);
                  return cartResponse;
                })
              );
          })
        );
      }),
    );
  }

  protected _getCount(): Observable<number> {
    if (!mfTypeIsUndefined(this._count)) {
      return of(this._count);
    } else {
      if (mfTypeIsUndefined(this._count$)) {
        this._count$ = this.id.pipe(
          concatMap((id) => {
            return this._portalsClientService.getItemGet<EComOrderCartActionResult>(
              this._cartActionResultModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${id}`)
              .pipe(
                map((cartCountResponse) => {
                  this._count = cartCountResponse.itemCount;
                  return this._count;
                })
              );
          }),
          share(),
        );
      }
      return this._count$;
    }
  }

  protected _getCreateCart(): Observable<string> {
    if (mfTypeIsUndefined(this._create$)) {
      this._create$ = this._customerService.selected.pipe(
        concatMap((selectedCustomer) => {
          const data: EComOrderCreateCartRequest = { key: selectedCustomer.key };
          return this._portalsClientService.getItemPost<EComOrderCartActionResult, EComOrderCreateCartRequest>(
            this._cartActionResultModelConfig,
            this._createCartRequestModelConfig,
            data,
            `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/create`)
            .pipe(
              map((cartCreateResponse) => {
                this._idInt = cartCreateResponse.cartKey;
                this._count = cartCreateResponse.itemCount;
                this.onCountChanged.next(0);
                return this._idInt;
              })
            );
        }),
        catchError((error) => {
          delete this._idInt;
          this.onCountChanged.next(0);
          throw error;
        }),
        share(),
      );
    }
    return this._create$;
  }

  protected _redirectIfValidationErrors(result: EComOrderCartItemActionResult): boolean {
    if (this._hasValidationErrors(result) === true) {
      this._combineErrors(result);
      this._router.navigate([EComRouteNames.routeShopCartList], { queryParams: { [EComRouteNames.queryParamNameAutoValidation]: true } });
      return true;
    } else {
      this.clearErrors();
    }
    return false;
  }
}