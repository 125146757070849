import { NgxMatDatepickerInputEvent } from "@angular-material-components/datetime-picker/lib/datepicker-input-base";
import { Component, Injector, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ecomCartGetDefaultRequiredDateEarliest } from "@e-commerce/cart/cart";
import { MfTypeInfo, MfBaseValidationComponent, MfDialogHostBaseComponent, mfTypeIsNull, ERROR_MESSAGE_REQUIRED } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComReviewCreateReadUpdateComponent" };

@Component({
  selector: "ecom-cart-required-date",
  templateUrl: "./required.date.dialog.component.html",
  styleUrls: ["./required.date.dialog.component.scss"],
})
export class EComRequiredDateDialogComponent extends MfBaseValidationComponent implements MfDialogHostBaseComponent {
  @Input()
  public get requiredDate(): Date | undefined {
    return this._requiredDate;
  }
  public set requiredDate(value: Date | undefined) {
    this._requiredDate = value;
    this._setFormControlValue(this._inputFormControl, this.requiredDate);
  }

  public isValid = false;

  protected readonly _formGroup: FormGroup;
  protected readonly _inputName = "requiredDate" as const;
  protected readonly _inputFormControl = new FormControl();
  protected _requiredDate?: Date | undefined;
  protected _min = ecomCartGetDefaultRequiredDateEarliest();


  public constructor(
    protected override _injector: Injector,
  ) {
    super(TYPE_INFO, _injector);
    this._formGroup = new FormGroup([]);
    this._buildFormGroup();
  }

  protected _changed(event: NgxMatDatepickerInputEvent<Date>): void {
    if (!mfTypeIsNull(event.value)) {
      this.requiredDate = event.value;
    }
  }

  protected _buildFormGroup(): void {
    this._addFormControlToFormGroup(this._formGroup, this._inputName, this._inputFormControl);
    this._addValidation(this._inputName, this._inputFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._inputName, this._inputFormControl);

    this._sub(this._inputFormControl.statusChanges, { next: () => this.isValid = this._inputFormControl.valid });
  }
}