import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";

export type EComCheckoutOrderSummeryModel = MfModelBase & {
  totalItems?: number;
  totalQuantity?: number;
  netTotal?: number;
  taxTotal?: number;
  freightTotal?: number;
  orderTotal?: number;
  currencyCode?: string;
}

export type EComCheckoutOrderSummeryModelFieldsConfig = {
  [key in keyof Required<EComCheckoutOrderSummeryModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_FIELDS_CONFIG: EComCheckoutOrderSummeryModelFieldsConfig = {
  totalItems: {
    dataType: {
      type: MfModelFieldDataTypes.int,
    }
  },
  totalQuantity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  netTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  taxTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  freightTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  orderTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG_ID = "orderCheckoutSummery";

export const ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG",
  },
  portals: {}
};