import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { eComPayPalGetPaymentInfoFromQueryPrams } from "@e-commerce/common/paypal";
import { EComPayPalResultTypes } from "@e-commerce/common/paypal.result.types";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { MfTypeInfo, MfBaseComponent, mfTypeIsNullOrUndefined, mfStringIsEmptyOrWhitespace } from "@pattonair/material-framework";
import { concatMap, EMPTY, map } from "rxjs";
import { EComCartModes } from "../../../common/cart.modes";
import { EComCartInvoiceService } from "../../../services/cart.invoice.service";

const TYPE_INFO: MfTypeInfo = { className: "EComPaypalErrorComponent" };

const ECOM_DECLINED_MESSAGE = "Your transaction has been declined please try again with another card" as const;
const ECOM_GENERAL_MESSAGE = "Your transaction failed please try again" as const;

@Component({
  selector: "ecom-paypal-error",
  templateUrl: "./paypal.error.component.html",
  styleUrls: ["./paypal.error.component.scss"]
})
export class EComPaypalErrorComponent extends MfBaseComponent {
  protected _result: string = "";
  protected _mode?: EComCartModes;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartOrderService: EComCartOrderService,
    protected _cartInvoiceService: EComCartInvoiceService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._errorPayment();
  }

  protected _errorPayment(): void {
    const loading = this._subLoading(
      this._activatedRoute.queryParams.pipe(
        map((queryParams) => {
          const paymentInfo = eComPayPalGetPaymentInfoFromQueryPrams(queryParams);

          return this._activatedRoute.data.pipe(
            concatMap((data) => {
              this._mode = data["mode"];

              if (!mfTypeIsNullOrUndefined(paymentInfo.respText) && !mfStringIsEmptyOrWhitespace(paymentInfo.respText)) {
                if (paymentInfo.result === EComPayPalResultTypes.declined) {
                  this._result = ECOM_DECLINED_MESSAGE;
                } else {
                  this._result = ECOM_GENERAL_MESSAGE;
                }
              }

              return EMPTY;
            }),
          );
        })
      ),
      {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); },
      }
    );
  }

  protected _goCart(): void {
    if (this._mode === EComCartModes.order) {
      this._router.navigate([EComRouteNames.routeShopCartList]);
    } else {
      this._router.navigate([EComRouteNames.routeInvoiceCartList]);
    }
  }
}