import { Component, Inject, Injector, Input } from "@angular/core";
import { MfTypeInfo, MF_TABLE_CONFIG_TOKEN, MfTableConfig, mfTypeIsUndefined, mfObjectGetPropertyPathValueAsNumber, MfModelValueOutputFormatterOptions, MfModelBase, MfTableFieldColumn, MfModelValueDisplayBaseFormatterComponent } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComShelfLifeDaysFormatterComponent" };

export type EComShelfLifeDaysFormatterOptions = MfModelValueOutputFormatterOptions & {
};

export type EComShelfLifeMode = MfModelBase & {
  shelfLifeDays: number
}

@Component({
  selector: "econ-shelf.life.days-cell-formatter-component",
  templateUrl: "shelf.life.days.cell.formatter.component.html",
  styleUrls: ["shelf.life.days.cell.formatter.component.scss"],
})
export class EComShelfLifeDaysFormatterComponent extends MfModelValueDisplayBaseFormatterComponent<EComShelfLifeDaysFormatterOptions, EComShelfLifeMode> {
  @Input()
  public fieldColumn?: MfTableFieldColumn;

  public constructor(
    protected override _injector: Injector,
    @Inject(MF_TABLE_CONFIG_TOKEN)
    public config: MfTableConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected override _setValue(): void {
    if (!mfTypeIsUndefined(this.fieldColumn)) {
      const numberValue = mfObjectGetPropertyPathValueAsNumber(this.fieldColumn.fieldPath, this.model);
      if (!mfTypeIsUndefined(numberValue)) {
        this._displayValue = numberValue === 0 ? "N/A" : numberValue.toString();
      } else {
        this._displayValue = "N/A";
      }
    }
  }
}
