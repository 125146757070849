import { BrowserModule } from "@angular/platform-browser";
import { EComRecommendedProductsAComponent } from "./recommended.products.a.component";
import { EComRecommendedProductsBComponent } from "./recommended.products.b.component";
import { EComRecommendedProductsService } from "./recommended.products.service";
import { MatCardModule } from "@angular/material/card";
import { MatGridListModule } from "@angular/material/grid-list";
import { NgModule } from "@angular/core";
import { MFCarouselCardsModule, MfCountryModule, MfIconModule, MfPhoneNumberInputModule } from "@pattonair/material-framework";
import { MatFormFieldModule } from "@angular/material/form-field";

@NgModule({
  declarations: [
    EComRecommendedProductsAComponent,
    EComRecommendedProductsBComponent,
  ],
  exports: [
    EComRecommendedProductsAComponent,
    EComRecommendedProductsBComponent,
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MatGridListModule,
    MfIconModule,
    MFCarouselCardsModule,
    MfPhoneNumberInputModule,
    MatFormFieldModule,
    MfCountryModule,
  ],
  providers: [
    EComRecommendedProductsService,
  ]
})
export class EComRecommendedProductsModule { }