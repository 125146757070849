import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { EComTestComponent } from "./test.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MfCountryModule, MfPhoneNumberInputModule } from "@pattonair/material-framework";

@NgModule({
  declarations: [
    EComTestComponent
  ],
  exports: [
    EComTestComponent
  ],
  imports: [
    BrowserModule,
    MatFormFieldModule,
    MfCountryModule,
    MfPhoneNumberInputModule,
  ],
  providers: [
  ]
})
export class EComTestModule { }