// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComShippingMethodsTypes = {
  fedex: "fedex",
  dhl: "dnl",
  ups: "ups",
  other: "other",
} as const;

export type EComShippingMethodsTypes = typeof EComShippingMethodsTypes[keyof typeof EComShippingMethodsTypes]

export function ecomGetShippingMethodsTypesDisplayValue(value: EComShippingMethodsTypes): string {
  switch (value) {
    case EComShippingMethodsTypes.fedex:
      return "Fedex";
    case EComShippingMethodsTypes.dhl:
      return "DHL";
    case EComShippingMethodsTypes.ups:
      return "UPS";
    case EComShippingMethodsTypes.other:
      return "other";
  }
}