import { EComAddressModel } from "@e-commerce/modelConfigs/address.model.config";
import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";

export type EComCustomerDetailModel = MfModelBase & {
  billingName: string;
  billingAddress: EComAddressModel;
  defaultCurrencyCode: string;
  shippingName: string;
  shippingAddress: EComAddressModel;
  primaryCarrierKey: string;
  primaryCarrierName: string;
  availablePaymentMethods: string[];
}

export type EComCustomerDetailModelFieldsConfig = {
  [key in keyof Required<EComCustomerDetailModel>]: MfModelFieldConfig
};

export const ECOM_CUSTOMER_DETAIL_MODEL_FIELDS_CONFIG: EComCustomerDetailModelFieldsConfig = {
  billingName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  billingAddress: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  defaultCurrencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shippingName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shippingAddress: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  primaryCarrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  primaryCarrierName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  availablePaymentMethods: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  }
};

export const ECOM_CUSTOMER_DETAIL_MODEL_CONFIG_ID = "customerDetail";

export const ECOM_CUSTOMER_DETAIL_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CUSTOMER_DETAIL_MODEL_CONFIG_ID,
  fields: ECOM_CUSTOMER_DETAIL_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CUSTOMER_DETAIL_MODEL_CONFIG",
  },
  portals: {}
};