import { MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";
import { EComPayPalPaymentInfo } from "./paypal.model.config";
import { ECOM_INVOICE_CART_RESPONSE_MODEL_FIELDS_CONFIG, EComInvoiceCartActionResult } from "./cart.invoice.model.config";



export type EComInvoiceCheckoutBeginPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  amount: number;
}

export type EComInvoiceCheckoutBeginPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCheckoutBeginPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceCheckoutBeginPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  amount: {
    dataType: {
      type: MfModelFieldDataTypes.double
    },
  },
};

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID = "invoiceCheckoutBeginPaymentRequest";

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceCheckoutBeginPaymentModel = EComInvoiceCartActionResult & {
  paymentUrl: string;
}

export type EComInvoiceCheckoutBeginPaymentModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCheckoutBeginPaymentModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG: EComInvoiceCheckoutBeginPaymentModelFieldsConfig = {
  ...ECOM_INVOICE_CART_RESPONSE_MODEL_FIELDS_CONFIG,
  paymentUrl: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID = "invoiceCheckoutBeginPayment";

export const ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID,
  fields: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG",
  },
  portals: {}
};



export type EComInvoiceCheckoutEndPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  paymentInfo: EComPayPalPaymentInfo;
}

export type EComInvoiceCheckoutEndPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCheckoutEndPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceCheckoutEndPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentInfo: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
};

export const ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID = "invoiceCheckoutEndPaymentRequest";

export const ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};