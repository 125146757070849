import { MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";


export type EComNewCustomerResponseModel = MfModelBase & {
  id: number;
};

export type EComNewCustomerResponseModelFieldsConfig = {
  [key in keyof Required<EComNewCustomerResponseModel>]: MfModelFieldConfig
};

export const ECOM_NEW_CUSTOMER_RESPONSE_MODEL_FIELDS_CONFIG: EComNewCustomerResponseModelFieldsConfig = {
  id: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
};

export const ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG_ID = "newCustomerResponse";

export const ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG_ID,
  fields: ECOM_NEW_CUSTOMER_RESPONSE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG",
  }
};