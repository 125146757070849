import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";


export type EComUserApprovedModel = MfModelBase & {
  active: boolean;
}

export type EComUserApprovedModelFieldsConfig = {
  [key in keyof Required<EComUserApprovedModel>]: MfModelFieldConfig
};

export const ECOM_USER_APPROVED_MODEL_FIELDS_CONFIG: EComUserApprovedModelFieldsConfig = {
  active: {
    dataType: {
      type: MfModelFieldDataTypes.boolean,
    }
  }
};

export const ECOM_USER_APPROVED_MODEL_CONFIG_ID = "userApproved";

export const ECOM_USER_APPROVED_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_USER_APPROVED_MODEL_CONFIG_ID,
  fields: ECOM_USER_APPROVED_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_USER_APPROVED_MODEL_CONFIG",
  },
  portals: {}
};