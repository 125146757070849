<div class="ecom-container">
  <div class="ecom-products"
       *ngFor="let product of _products">
    <div class="ecom-product">
      <mat-card appearance="outlined">
        <mat-card-header>
          <mat-card-title>
            {{ product.itemCode }}
          </mat-card-title>
          <mat-card-subtitle>
            {{ product.itemDescription }}
          </mat-card-subtitle>
        </mat-card-header>
        <img mat-card-image
             [src]="product.imageUrl">
      </mat-card>
    </div>
  </div>
</div>