import { Component, Inject, Injector, Input } from "@angular/core";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComManufacturerModel } from "@e-commerce/modelConfigs/manufacturer.model.config";
import { EComStockSummeryModel } from "@e-commerce/modelConfigs/stock.summery.model.config";
import { EComManufacturerService } from "@e-commerce/services/manufacturer.service";
import { MfTypeInfo, MfBaseComponent, mfTypeIsUndefined, mfTypeIsNullOrUndefined, mfStringTrimEnd, mfStringTrimStart, mfStringIsEmptyOrWhitespace } from "@pattonair/material-framework";
import { map } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComManufacturerDetailLineComponent" };

type EComManufacturerViewModel = EComManufacturerModel & {
  displayValue?: string;
};

@Component({
  selector: "ecom-manufacturer-detail-line",
  templateUrl: "./manufacturer.detail.line.component.html",
  styleUrls: ["./manufacturer.detail.line.component.scss"]
})
export class EComManufacturerDetailLineComponent extends MfBaseComponent {
  @Input()
  public stockSummery?: EComStockSummeryModel;

  protected _manufacturer?: EComManufacturerViewModel;
  protected _manufacturerIsLoading = false;

  public constructor(
    protected override _injector: Injector,
    protected _manufacturerService: EComManufacturerService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  public loadDetail(): void {
    if (!mfTypeIsUndefined(this.stockSummery)) {
      this._manufacturerIsLoading = true;

      const geManufacturerDetail$ = this._manufacturerService.getManufacturerDetail(this.stockSummery!.manufacturerAccountKey).pipe(
        map((manufacturer) => {
          this._cleanAddress(manufacturer);
          this._manufacturer = manufacturer;
          this._manufacturerIsLoading = false;
          return true;
        })
      );

      this._sub(geManufacturerDetail$);
    }
  }

  protected _cleanAddress(manufacturer: EComManufacturerViewModel): void {
    const parts: string[] = [];

    if (!mfTypeIsNullOrUndefined(manufacturer.manufacturerName)) {
      parts.push(manufacturer.manufacturerName);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.addressLine1)) {
      parts.push(manufacturer.addressLine1);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.addressLine2)) {
      parts.push(manufacturer.addressLine2);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.addressLine3)) {
      parts.push(manufacturer.addressLine3);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.addressLine4)) {
      parts.push(manufacturer.addressLine4);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.addressLine5)) {
      parts.push(manufacturer.addressLine5);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.city)) {
      parts.push(manufacturer.city);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.postalCode)) {
      parts.push(manufacturer.postalCode);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.stateCode)) {
      parts.push(manufacturer.stateCode);
    }

    if (!mfTypeIsNullOrUndefined(manufacturer.countryCode)) {
      parts.push(manufacturer.countryCode);
    }

    manufacturer.displayValue = parts.map(p => mfStringTrimEnd(mfStringTrimStart(p, " "), " ")).filter(p => !mfStringIsEmptyOrWhitespace(p)).join(",");
  }

}