import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MatCardModule } from "@angular/material/card";
import { EComInvoiceOrderReviewComponent } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.component";
import { MfTableModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComInvoiceOrderReviewComponent
  ],
  exports: [
    EComInvoiceOrderReviewComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    MatCardModule,
    MfTableModule,
  ],
  providers: [
  ]
})
export class EComInvoiceOrderReviewModule { }