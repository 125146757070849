import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";

export type EComCheckoutSummery = {
  itemsCount?: number;
  totalQty?: number;
  currencyCode?: string;
  subTotal?: number;
  total?: number;
  invoiceTotal?: number;
  paymentAmount?: number;
  currencyAmount?: number;
  currencyTaxAmount?: number;
  currencyTotalAmount?: number;
  currentOpenAmount?: number;
  amountToPay?: number;
  resultingAmount?: number;
}

export type EComCheckoutSummeryFieldsConfig = {
  [key in keyof Required<EComCheckoutSummery>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_SUMMERY_MODEL_FIELDS_CONFIG: EComCheckoutSummeryFieldsConfig = {
  itemsCount: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  totalQty: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  subTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    },
    valueOutputFormatter: {
      currency: {}
    }
  },
  total: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    },
    valueOutputFormatter: {
      currency: {}
    }
  },
  invoiceTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  paymentAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTaxAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTotalAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currentOpenAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  amountToPay: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  resultingAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
};

export const ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID = "checkoutSummery";

export const ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_SUMMERY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG",
  },
  portals: {
    mock: {}
  }
};