import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes, MfPortalsRequestCollectionData } from "@pattonair/material-framework";
import { EComInvoiceCartValidationItemModel } from "./checkout.invoice.model.config";
import { EComErrorValidationItemModel } from "../services/cart.action";

export type EComInvoiceCartRequest = MfPortalsRequestCollectionData & {
  cartKey?: string;
  key?: string;
}

export type EComInvoiceCartRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceCartRequestModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG_ID = "invoiceCartLineRequest";

export const ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_LINE_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceCartActionResult = {
  cartKey: string;
  itemCount: number;
  items?: EComInvoiceCartValidationItemModel[],
  errors?: EComErrorValidationItemModel[];
}

export type EComInvoiceCartCountResponseModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartActionResult>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_RESPONSE_MODEL_FIELDS_CONFIG: EComInvoiceCartCountResponseModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  itemCount: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
  errors: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG_ID = "invoiceCartActionResult";

export const ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_RESPONSE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG",
  },
  portals: {}
};


export type EComInvoiceCreateCartRequest = {
  key: string;
  currencyCode: string;
}

export type EComInvoiceCreateCartRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCreateCartRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceCreateCartRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG_ID = "invoiceCreateCartRequest";

export const ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceCartLineModel = {
  documentKey: string,
  invoiceNumber: string;
  invoiceAmount: string;
  currencyCode: Date,
  currencyValue: string;
  currencyTaxValue?: string;
  currencyTotalValue: string;
  invoiceDate: string;
  paymentDueDate: Date,
}

export type EComInvoiceCartLineModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartLineModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_LINE_MODEL_FIELDS_CONFIG: EComInvoiceCartLineModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceNumber: {
    display: {
      displayName: "Invoice"
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currencyValue: {
    display: {
      displayName: "Value"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTaxValue: {
    display: {
      displayName: "Tax"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTotalValue: {
    display: {
      displayName: "Total Value"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  invoiceDate: {
    display: {
      displayName: "Invoice Date"
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  paymentDueDate: {
    display: {
      displayName: "Payment Due Date"
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
};

export const ECOM_INVOICE_CART_LINE_MODEL_CONFIG_ID = "invoiceCartLine";

export const ECOM_INVOICE_CART_LINE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_LINE_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_LINE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_LINE_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceAddCartItemRequest = {
  key: string;
  invoiceKey: string;
}

export type EComInvoiceAddCartItemRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceAddCartItemRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceAddCartItemRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID = "invoiceAddCartItemRequest";

export const ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceAddCartItemsRequest = {
  key: string;
  invoiceKeys: string[];
}

export type EComInvoiceAddCartItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceAddCartItemsRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceAddCartItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceKeys: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID = "invoiceAddCartItemsRequest";

export const ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};


export type EComInvoiceCartDeleteMultipleItemsRequest = {
  key: string;
  cartKey: string;
  invoiceKeys: string[];
}

export type EComInvoiceCartDeleteMultipleItemsRequestModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartDeleteMultipleItemsRequest>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG: EComInvoiceCartDeleteMultipleItemsRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceKeys: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.string,
    },
  },
};

export const ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID = "invoiceCartDeleteMultipleItemsRequest";

export const ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};