<div class="mf-column ecom-checkout-part-container">
  <div class="ecom-page-header">
    <div class="ecom-title">
      Order Review
    </div>
  </div>
  <div class="mf-row">
    <div class="ecom-split">
      <div class="ecom-shiping-address">
        <mat-card style="width: 100%;"
                  class="ecom-card-left">
          <mat-card-header>
            <mat-card-title>Shipping Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{checkoutData?.customerDetail?.shippingName}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address1}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address2}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address3}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address4}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.address5}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.city}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.region}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.postalCode}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.shippingAddress?.countryCode}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="mf-column ecom-split">
      <div class="ecom-billing-address">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Billing Address</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                {{checkoutData?.customerDetail?.billingName}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address1}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address2}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address3}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address4}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.address5}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.city}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.region}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.postalCode}}
              </div>
              <div>
                {{checkoutData?.customerDetail?.billingAddress?.countryCode}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="ecom-Payment-Method">
        <mat-card class="ecom-card-right">
          <mat-card-header>
            <mat-card-title>Payment Method</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="mf-column mf-large-margin-top">
              <div>
                Credit Card
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div class="mf-column">
    <mat-card class="ecom-card-right">
      <mat-card-header>
        <mat-card-title>Record of Payments</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="mf-column mf-large-margin-top">
          <mf-table #recordOfPaymentsTable
                    [locationKey]="_locationKey"
                    [modelConfig]="_recordOfPaymentModelConfig"
                    [postModelConfig]="_recordOfPaymentRequestModelConfig"
                    viewManagerEnabled="false"
                    paginationEnabled="false">
          </mf-table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>