import { NGX_MAT_DATE_FORMATS, NgxMatDatetimePickerModule, NgxMatNativeDateModule } from "@angular-material-components/datetime-picker";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MF_NGX_MAT_DATE_FORMATS, MfCountryModule, MFFileUploadModule, MfInputModule, MfPhoneNumberInputModule } from "@pattonair/material-framework";
import { EComNewCustomerSetupCompleteComponent } from "./new.customer.setup.complete.component";
import { EComNewCustomerSetupComponent } from "./new.customer.setup.component";
import { EComNewCustomerSetupService } from "./new.customer.setup.service";

@NgModule({
  declarations: [
    EComNewCustomerSetupComponent,
    EComNewCustomerSetupCompleteComponent,
  ],
  exports: [
    EComNewCustomerSetupComponent,
    EComNewCustomerSetupCompleteComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MfCountryModule,
    MFFileUploadModule,
    MfInputModule,
    MfPhoneNumberInputModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: MF_NGX_MAT_DATE_FORMATS },
    EComNewCustomerSetupService,
  ]
})
export class EComNewCustomerSetupModule { }