import { MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig, MfModelBase } from "@pattonair/material-framework";


export type EComOrderDownloadDocumentRequest = {
  key: string;
  orderLineKey: string;
  documentKey: string;
}

export type EComOrderDownloadDocumentRequestFieldsConfig = {
  [key in keyof Required<EComOrderDownloadDocumentRequest>]: MfModelFieldConfig
};

export const ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_FIELDS_CONFIG: EComOrderDownloadDocumentRequestFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  orderLineKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID = "orderDownloadDocumentRequest";

export const ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderDocumentDownloadInfoPayload = {
  key: string;
  orderLineKey: string;
  documentKey: string;
}

export type EComOrderDocumentDownloadInfoModel = MfModelBase & {
  httpMethod: string;
  downloadUrl: string;
  payLoad: EComOrderDocumentDownloadInfoPayload;
}

export type EComOrderDocumentDownloadInfoFieldsConfig = {
  [key in keyof Required<EComOrderDocumentDownloadInfoModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_FIELDS_CONFIG: EComOrderDocumentDownloadInfoFieldsConfig = {
  httpMethod: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  downloadUrl: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  payLoad: {
    dataType: {
      type: MfModelFieldDataTypes.object,
    }
  },
};

export const ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID = "orderDocumentDownloadInfo";

export const ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderDocumentsModel = MfModelBase & {
  documents: EComOrderDocumentModel[]
}

export type EComOrderDocumentsModelFieldsConfig = {
  [key in keyof Required<EComOrderDocumentsModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_DOCUMENTS_MODEL_FIELDS_CONFIG: EComOrderDocumentsModelFieldsConfig = {
  documents: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    }
  },
};

export const ECOM_ORDER_DOCUMENTS_MODEL_CONFIG_ID = "orderDocuments";

export const ECOM_ORDER_DOCUMENTS_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_DOCUMENTS_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_DOCUMENTS_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_DOCUMENTS_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderDocumentModel = MfModelBase & {
  documentKey: string;
  name: string;
}

export type EComOrderDocumentModelFieldsConfig = {
  [key in keyof Required<EComOrderDocumentModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_DOCUMENT_MODEL_FIELDS_CONFIG: EComOrderDocumentModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  name: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
};

export const ECOM_ORDER_DOCUMENT_MODEL_CONFIG_ID = "orderDocument";

export const ECOM_ORDER_DOCUMENT_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_DOCUMENT_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_DOCUMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_DOCUMENT_MODEL_CONFIG",
  },
  portals: {}
};