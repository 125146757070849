import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCarrierModel } from "@e-commerce/modelConfigs/carrier.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService } from "@pattonair/material-framework";
import { concatMap, map, Observable, of } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComCarrierService" };

type EComCarrierResponse = {
  results: EComCarrierModel[];
}

type EComCarrierExtendedModel = EComCarrierModel & {
  customerKey: string;
}

@Injectable({ providedIn: "root" })
export class EComCarrierService extends MfBaseService {
  protected _carrierModelConfig: MfModelConfigMapped;
  protected _carriers: EComCarrierExtendedModel[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._carrierModelConfig = this._modelConfigService.get<EComModelsConfig>("carrier");
  }

  public get carriers(): Observable<EComCarrierModel[]> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        const cachedCurriers = this._carriers.filter(i => i.customerKey === selectedCustomer.key);

        if (cachedCurriers.length > 0) {
          return of(cachedCurriers);
        }

        return this._portalsClientService.getItemGet<EComCarrierResponse>(this._carrierModelConfig, `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsCarriersUrl}`).pipe(
          map((response) => {
            this._carriers.push(...response.results.map(i => ({ ...i, customerKey: selectedCustomer.key })));
            return response.results;
          }),
        );
      }),
    );
  }
}