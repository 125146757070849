import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, MfSessionStorageService } from "@pattonair/material-framework";
import { concatMap, map, Observable } from "rxjs";
import { EComCartInvoiceService } from "./cart.invoice.service";
import { CHECKOUT_INVOICE_DATA_STORAGE_KEY } from "../checkout/invoice/checkout.invoice.component";
import { EComCheckoutInvoiceSummeryModel } from "../modelConfigs/checkout.invoice.summery.model.config";
import { EComCheckoutSummery } from "../checkout/checkout.summery";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutInvoiceService" };

@Injectable({ providedIn: "root" })
export class EComCheckoutInvoiceService extends MfBaseService {
  protected _checkoutSummeryModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _cartInvoiceService: EComCartInvoiceService,
    protected _storageService: MfSessionStorageService,
  ) {
    super(TYPE_INFO, _injector);
    this._checkoutSummeryModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceCheckoutSummery");

    this._sub(this._customerService.onSelectedChange, { next: () => this._clearCache() });
  }

  public get summery(): Observable<EComCheckoutSummery> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._cartInvoiceService.id.pipe(
          concatMap((cartId) => {
            return this._portalsClientService.getItemGet<EComCheckoutInvoiceSummeryModel>(
              this._checkoutSummeryModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsPaymentsUrl}/${cartId}/summary`
            ).pipe(
              map((invoiceSummery) => ({
                itemsCount: invoiceSummery.itemsCount,
                currencyCode: invoiceSummery.currencyCode,
                total: invoiceSummery.invoiceTotal,
                paymentAmount: invoiceSummery.paymentAmount,
                currencyAmount: invoiceSummery.currencyAmount,
                currencyTaxAmount: invoiceSummery.currencyTaxAmount,
                currencyTotalAmount: invoiceSummery.currencyTotalAmount,
                currentOpenAmount: invoiceSummery.currentOpenAmount,
                amountToPay: invoiceSummery.amountToPay,
                resultingAmount: invoiceSummery.resultingAmount,
              }))
            );
          }),
        );
      }),
    );
  }

  protected _clearCache(): void {
    this._storageService.remove(CHECKOUT_INVOICE_DATA_STORAGE_KEY);
  }
}