import { Component, Injector } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MfBaseComponent, MfTypeInfo, mfTypeIsNullOrUndefined } from "@pattonair/material-framework";
import { EComRouteNames } from "../../ecom.route.names";

const TYPE_INFO: MfTypeInfo = { className: "EComHomeComponent" };

@Component({
  selector: "cm-new-customer-setup-complete",
  templateUrl: "./new.customer.setup.complete.component.html",
  styleUrls: ["./new.customer.setup.complete.component.scss"]
})
export class EComNewCustomerSetupCompleteComponent extends MfBaseComponent {
  protected _applicationId?: number;

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
  ) {
    super(TYPE_INFO, _injector);
    this._getRouteParams();
  }

  protected _getRouteParams(): void {
    this._sub(this._activatedRoute.params, {
      next: (params) => {
        const applicationId = params[EComRouteNames.parameterNameApplicationId];
        if (!mfTypeIsNullOrUndefined(applicationId) && !isNaN(applicationId)) {
          this._applicationId = applicationId;
        }
      }
    });
  }
}