export const ECOM_ENVIRONMENT = {
  authConfig: {
    clientId: "ff03286c-0ac8-4b18-bb10-98e9f780055e",
    issuer: "https://login.incora.com/tfp/a42f291c-cd14-4967-9b76-39b8737608f1/B2C_1A_SIGNUP_SIGNIN_OAUTH_30M/v2.0/",
    scope: "openid profile email offline_access https://login.incora.com/f5c73591-4844-4350-93f0-2749ece220fb/read"
  },
  authenticatedUrls: ["https://eshop.incora.com", "https://app.hw2.eshop.incora.com"],
  authenticatedUrlsExclude: [],
  portalsCarriersUrl: "carriers",
  portalsCartsUrl: "carts",
  portalsCustomerRootUrl: "https://app.hw2.eshop.incora.com/api/Customer",
  portalsCustomersUrl: "customers",
  portalsCustomerUrl: "customer",
  portalsDocumentsUrl: "documents",
  portalsInvoicesUrl: "invoices",
  portalsLinesUrl: "lines",
  portalsManufacturersUrl: "manufacturers",
  portalsOrdersUrl: "orders",
  portalsPaymentsUrl: "payments",
  portalsRequestForQuoteUrl: "rfq",
  portalsStocksUrl: "stocks",
  portalsUserRootUrl: "https://eshop.incora.com/api/Users",
  productImageUrl: "https://content.hw2.eshop.incora.com/api/Documents/products/images?itemCode=",
  production: true,
  googleAnalyticsTrackingCode: "G-FNEV6VXQDD",
};