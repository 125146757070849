import { Component, Inject, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { MfTypeInfo, MfBaseComponent } from "@pattonair/material-framework";
import { concatMap, EMPTY } from "rxjs";
import { EComCartModes } from "../../../common/cart.modes";


const TYPE_INFO: MfTypeInfo = { className: "EComPaypalCancelComponent" };

@Component({
  selector: "ecom-paypal-cancel",
  templateUrl: "./paypal.cancel.component.html",
  styleUrls: ["./paypal.cancel.component.scss"]
})
export class EComPaypalCancelComponent extends MfBaseComponent {
  protected _mode?: EComCartModes;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cancelPayment();
  }

  protected _cancelPayment(): void {
    const loading = this._subLoading(
      this._activatedRoute.data.pipe(
        concatMap((data) => {
          this._mode = data["mode"];
          return EMPTY;
        }),
      ),
      {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); },
      }
    );
  }


  protected _goCart(): void {
    if (this._mode === EComCartModes.order) {
      this._router.navigate([EComRouteNames.routeShopCartList]);
    } else {
      this._router.navigate([EComRouteNames.routeInvoiceCartList]);
    }
  }
}