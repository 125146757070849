import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { EComPaypalCancelComponent } from "@e-commerce/landing/paypal/cancel/paypal.cancel.component";
import { MfIconModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComPaypalCancelComponent
  ],
  exports: [
    EComPaypalCancelComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    MfIconModule,
    MatButtonModule,
  ],
  providers: [
  ]
})
export class EComPaypalCancelModule { }