import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatTabsModule } from "@angular/material/tabs";
import { BrowserModule } from "@angular/platform-browser";
import { EComOrderDetailHostComponent } from "@e-commerce/orderDetail/order.detail.host.component";
import { EComOrderDetailDetailsComponent } from "@e-commerce/orderDetail/order.details.component";
import { EComOrderDetailDocumentationComponent } from "@e-commerce/orderDetail/order.documentation.component";
import { MfIconModule, MfTableModule, MfStatusChipModule, MfPipesModule } from "@pattonair/material-framework";

@NgModule({
  declarations: [
    EComOrderDetailHostComponent,
    EComOrderDetailDocumentationComponent,
    EComOrderDetailDetailsComponent,
  ],
  exports: [
    EComOrderDetailHostComponent,
    EComOrderDetailDocumentationComponent,
    EComOrderDetailDetailsComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MatTabsModule,
    MfIconModule,
    MfTableModule,
    MfStatusChipModule,
    MfPipesModule,
    MatProgressBarModule,
  ],
  providers: [
  ]
})
export class EComOrderDetailModule { }