import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule } from "@angular/platform-browser";
import { MfIconModule, MfTinyMCEEditorModule, MfDialogService, MF_MODEL_VALUE_OUTPUT_FORMATTERS_CONFIG } from "@pattonair/material-framework";
import { EComShelfLifeDaysFormatterComponent } from "./shelf.life.days.cell.formatter.component";

MF_MODEL_VALUE_OUTPUT_FORMATTERS_CONFIG["ecomShelfLifeDaysFormatter"] = EComShelfLifeDaysFormatterComponent;

@NgModule({
  declarations: [
    EComShelfLifeDaysFormatterComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MatTooltipModule,
    MfIconModule,
    MatButtonModule,
    MatDialogModule,
    MfTinyMCEEditorModule,
    FormsModule,
  ],
  exports: [
  ],
  providers: [
    MfDialogService,
  ]
})
export class EComTableCellModule { }