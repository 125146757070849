import { EComCustomerDetailModel } from "@e-commerce/modelConfigs/customer.detail.model.config";
import { EComCartPaymentTypes } from "../../common/cart.modes";
import { EComCheckoutSummery } from "../checkout.summery";
import { MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";

export type EComInvoiceCheckoutData = {
  customerDetail?: EComCustomerDetailModel,
  summery: EComCheckoutSummery;
  amount: number;
  paymentMethod: EComCartPaymentTypes;
  paymentMethodName?: string;
}

export type EComInvoiceCheckoutDataFieldsConfig = {
  [key in keyof Required<EComInvoiceCheckoutData>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CHECKOUT_DATA_MODEL_FIELDS_CONFIG: EComInvoiceCheckoutDataFieldsConfig = {
  customerDetail: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  summery: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  amount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  paymentMethodName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG_ID = "invoiceCheckoutData";

export const ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CHECKOUT_DATA_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG",
  },
  portals: {
    mock: {}
  }
};