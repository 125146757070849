<div class="ecom-page-container ecom-products-list-container">
  <div class="ecom-page-account-select">
    <ecom-account-select [disabled]="_isLoading"></ecom-account-select>
    <div class="mf-flex-fill"></div>
    <div class="ecom-search-container">
      <mf-table-simple-search (onSearch)="_onSearch()"
                              [isLoading]="_isLoading"
                              [operator]="_itemCodeOperator"
                              [table]="_stockSummeryTable"
                              [modelConfig]="_stockSummeryModelConfig"
                              [fieldPath]="_itemCodeFieldPath"></mf-table-simple-search>
    </div>
  </div>
  <!-- <ecom-recommended-products-b></ecom-recommended-products-b> -->
  <mf-table #stockSummeryTable
            viewManagerEnabled="true"
            [locationKey]="_locationKey"
            [modelConfig]="_stockSummeryModelConfig"
            [postModelConfig]="_stockSummeryRequestModelConfig"
            (onItemDetailExpand)="_onItemDetailExpand($event)"
            (onRowClicked)="_onRowClicked($event)">
    <ng-container *mfTableHeaderLeftDef>
      <div class="ecom-page-header">
        <div class="ecom-title">
          Shop
        </div>
      </div>
    </ng-container>
    <ng-container mfTableColumnSlideOutActionsDef
                  stickyEnd="true">
      <ng-container *mfTableCellDef="let item"
                    contentAlign="center">
        <div class="mf-column mf-default-margin">
          <button mat-stroked-button
                  (click)="_addToCard(item)">
            <mf-icon class="mf-default-margin-right"
                     [icon]="_iconsConfig.addToCartIcon"></mf-icon>
            Add To Cart
          </button>
        </div>
      </ng-container>
    </ng-container>
    <ng-container mfTableColumnExpandDef>
      <ng-container *mfTableCellDef="let item">
        <ecom-manufacturer-detail-line #manufacturerDetails
                                       [stockSummery]="item"></ecom-manufacturer-detail-line>
      </ng-container>
    </ng-container>
  </mf-table>
</div>