// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComPaymentTermsTypes = {
  creditCard: 0,
  cashInAdvance: 1,
  credit: 2,
} as const;

export type EComPaymentTermsTypes = typeof EComPaymentTermsTypes[keyof typeof EComPaymentTermsTypes]

export function ecomGetPaymentTermsTypesDisplayValue(value: EComPaymentTermsTypes): string {
  switch (value) {
    case EComPaymentTermsTypes.creditCard:
      return "Credit Card";
    case EComPaymentTermsTypes.cashInAdvance:
      return "Payment / Cash in Advance";
    case EComPaymentTermsTypes.credit:
      return "Line of Credit";
  }
}