import { Component, Injector } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { eComPayPalGetPaymentInfoFromQueryPrams } from "@e-commerce/common/paypal";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseComponent } from "@pattonair/material-framework";
import { concatMap } from "rxjs";
import { EComCartInvoiceService } from "../../../services/cart.invoice.service";
import { EComCartModes } from "../../../common/cart.modes";

const TYPE_INFO: MfTypeInfo = { className: "EComPaypalReturnComponent" };

@Component({
  selector: "ecom-paypal-return",
  templateUrl: "./paypal.return.component.html",
  styleUrls: ["./paypal.return.component.scss"]
})
export class EComPaypalReturnComponent extends MfBaseComponent {
  protected _mode?: EComCartModes;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartOrderService: EComCartOrderService,
    protected _cartInvoiceService: EComCartInvoiceService,
    protected _customerService: EComCustomerService,
  ) {
    super(TYPE_INFO, _injector);
    this._endPayment();
  }

  protected _endPayment(): void {
    const loading = this._subLoading(
      this._activatedRoute.queryParams.pipe(
        concatMap((queryParams) => {
          return this._activatedRoute.data.pipe(
            concatMap((data) => {
              this._mode = data["mode"];

              if (this._mode === EComCartModes.order) {
                return this._cartOrderService.endPayment({ paymentInfo: eComPayPalGetPaymentInfoFromQueryPrams(queryParams) });
              } else {
                return this._cartInvoiceService.endPayment({ paymentInfo: eComPayPalGetPaymentInfoFromQueryPrams(queryParams) });
              }
            }),
          );
        })
      ),
      {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); },
      }
    );
  }
}