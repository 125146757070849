import { Component, Inject, Injector, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseComponent, MfIcon, mfTypeIsUndefined } from "@pattonair/material-framework";
import { EComCartModeService } from "../services/cart.mode.service";
import { EComCartInvoiceService } from "../services/cart.invoice.service";

const TYPE_INFO: MfTypeInfo = { className: "EComCartPreviewComponent" };

@Component({
  selector: "ecom-cart-preview",
  templateUrl: "./cart.preview.component.html",
  styleUrls: ["./cart.preview.component.scss"]
})
export class EComCartPreviewComponent extends MfBaseComponent implements OnInit {
  protected _cartIcon?: MfIcon;
  protected _mode?: EComCartModes;
  protected _count: number = 0;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _cartModeService: EComCartModeService,
    protected _cartOrderService: EComCartOrderService,
    protected _cartInvoiceService: EComCartInvoiceService,
    protected _customerService: EComCustomerService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._setMode(this._cartModeService.mode);

    this._sub(this._cartModeService.onCartModeChanged, { next: (mode) => { this._setMode(mode); this._setCount(); } });
    this._sub(this._cartOrderService.onCountChanged, { next: () => this._setCount() });
    this._sub(this._cartInvoiceService.onCountChanged, { next: () => this._setCount() });
    this._sub(this._customerService.onSelectedChange, { next: () => this._count = 0 });
  }

  public ngOnInit(): void {
    this._setCount();
  }

  protected _goToCart(): void {
    if (!mfTypeIsUndefined(this._mode)) {
      switch (this._mode) {
        case EComCartModes.order:
          this._router.navigate([EComRouteNames.routeShopCartList]);
          break;
        case EComCartModes.invoice:
          this._router.navigate([EComRouteNames.routeInvoiceCartList]);
          break;
      }
    }
  }

  protected _setCount(): void {
    if (this._cartModeService.mode === EComCartModes.order) {
      this._sub(this._cartOrderService.count, { next: (count) => this._count = count });
    } else {
      this._sub(this._cartInvoiceService.count, { next: (count) => this._count = count });
    }
  }

  protected _setMode(mode: EComCartModes): void {
    this._mode = mode;
    switch (mode) {
      case EComCartModes.order:
        this._cartIcon = this._config.cartIcon;
        break;
      case EComCartModes.invoice:
        this._cartIcon = this._config.payIcon;
        break;
    }
  }
}