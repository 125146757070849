import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComInvoicesComponent } from "@e-commerce/invoices/invoices.component";
import { MfIconModule, MfTableModule } from "@pattonair/material-framework";
import { EComCurrencySelectModule } from "../currencySelect/currency.select.module";

@NgModule({
  declarations: [
    EComInvoicesComponent
  ],
  exports: [
    EComInvoicesComponent
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    EComCurrencySelectModule,
    MatButtonModule,
    MfIconModule,
    MfTableModule,
  ],
  providers: [
  ]
})
export class EComInvoicesModule { }