import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, Inject, Injector, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_TABLE_CONFIG, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComInvoiceModel, EComInvoiceRequest } from "@e-commerce/modelConfigs/invoice.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComInvoiceService } from "@e-commerce/services/invoice.service";
import { MfTypeInfo, MfTableConfig, MfModelFieldExtendedConfig, MF_ENUM_FILTER_CONFIG, mfObjectGetPropertyPath, MF_STRING_FILTER_CONFIG, MF_TABLE_CONFIG_TOKEN, MfBaseComponent, MfPortalsTableComponent, MfModelConfigMapped, MfModelConfigService, mfTypeIsUndefined, mfTableReIndexModelFieldExtendedConfig, mfTypeIsNullOrUndefined, MfTableQueryFilterService, MfModelValueOutputFormatterTypes } from "@pattonair/material-framework";
import { map, Observable } from "rxjs";
import { EComCartModeService } from "../services/cart.mode.service";
import { EComCartInvoiceService } from "../services/cart.invoice.service";
import { EComCurrencyService } from "../services/currency.service";

const TYPE_INFO: MfTypeInfo = { className: "EComInvoicesComponent" };

const ECOM_INVOICES_DETAIL_TABLE_CONFIG: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  row: {
    ...ECOM_TABLE_CONFIG.row,
    clickAction: {
      ...ECOM_TABLE_CONFIG.row.clickAction,
      tooltip: "View Invoice Detail",
    }
  }
};

export const ECOM_INVOICES_LOCATION_KEY = "invoices";

const ECOM_INVOICE_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_ENUM_FILTER_CONFIG },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("status")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 8 } }, exclude: { operatorsTypes: { string: { contains: true, startsWith: true } } } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("documentNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("invoiceDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currency")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyTaxValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("currencyTotalValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentValue")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentDueDate")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComInvoiceModel>("paymentTerms")
  },
];

@Component({
  selector: "ecom-invoices",
  templateUrl: "./invoices.component.html",
  styleUrls: ["./invoices.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_INVOICES_DETAIL_TABLE_CONFIG },
  ]
})
export class EComInvoicesComponent extends MfBaseComponent implements OnInit {
  @ViewChild("invoicesTable", { static: true })
  protected get _invoicesTable(): MfPortalsTableComponent<EComInvoiceModel, string, number> | undefined {
    return this._invoicesTableInt;
  }
  protected set _invoicesTable(value: MfPortalsTableComponent<EComInvoiceModel, string, number> | undefined) {
    this._invoicesTableInt = value;
    this._initializeTable();
  }

  protected _invoiceModelConfig: MfModelConfigMapped;
  protected _invoiceRequestModelConfig: MfModelConfigMapped;
  protected _invoicesTableInt?: MfPortalsTableComponent<EComInvoiceModel, string, number>;
  protected _locationKey = ECOM_INVOICES_LOCATION_KEY;
  protected _payMode = false;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _cartModeService: EComCartModeService,
    protected _cartInvoiceService: EComCartInvoiceService,
    protected _invoiceService: EComInvoiceService,
    protected _currencyService: EComCurrencyService,
    protected _tableQueryFilterService: MfTableQueryFilterService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cartModeService.setMode(EComCartModes.invoice);
    this._getQueryParams();
    this._invoiceModelConfig = this._modelConfigService.get<EComModelsConfig>("invoice");
    this._invoiceRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("invoiceRequest");
    this._setModelConfigs();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (params) => {
        const pay = params[EComRouteNames.parameterNamePayKey];
        if (!mfTypeIsNullOrUndefined(pay)) {
          this._payMode = coerceBooleanProperty(pay);
        }
      }
    });
  }

  protected _viewInvoice(invoice: EComInvoiceModel): void {
    const loading = this._subLoading(this._invoiceService.downloadDocument(invoice), {
      next: () => { loading.complete(); },
      error: () => { loading.complete(); },
    }, "Downloading Invoice");
  }

  protected _viewDetails(invoice: EComInvoiceModel): void {
    this._router.navigate([EComRouteNames.getInvoiceDetailRoute(invoice.documentKey, invoice.documentNumber)]);
  }

  protected _addToPay(invoice: EComInvoiceModel): void {
    this._invoicesTable?.slideOutActionsClose();
    const loading = this._subLoading(
      this._cartInvoiceService.add({ invoiceKey: invoice.documentKey, currencyCode: invoice.currency }),
      {
        next: () => {
          loading.complete();
          this._router.navigate([EComRouteNames.routeInvoiceCartList]);
        },
        error: () => {
          loading.complete();
        }
      },
      "Adding Invoice To Cart"
    );
  }

  protected get _isLoading(): boolean {
    return this._invoicesTable?.isLoading === true;
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._invoicesTable)) {
      this._invoicesTable.blockDataLoad = false;
      this._invoicesTable.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_INVOICE_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._invoiceModelConfig, ECOM_INVOICE_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._invoicesTable)) {
      this._invoicesTable.blockDataLoad = true;
      this._invoicesTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsInvoicesUrl}`;

      this._invoicesTable.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComInvoiceRequest): Observable<EComInvoiceRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.key = selected.key;
        return data;
      })
    );
  };
}