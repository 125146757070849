import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";

export type EComCheckoutInvoiceSummeryModel = MfModelBase & {
  itemsCount?: number;
  invoiceTotal?: number;
  paymentAmount?: number;
  currencyCode?: string;
  totalQty?: number;
  subTotal?: number;
  total?: number;
  currencyAmount?: number;
  currencyTaxAmount?: number;
  currencyTotalAmount?: number;
  currentOpenAmount?: number;
  amountToPay?: number;
  resultingAmount?: number;
}

export type EComCheckoutInvoiceSummeryModelFieldsConfig = {
  [key in keyof Required<EComCheckoutInvoiceSummeryModel>]: MfModelFieldConfig
};

export const ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_FIELDS_CONFIG: EComCheckoutInvoiceSummeryModelFieldsConfig = {
  itemsCount: {
    dataType: {
      type: MfModelFieldDataTypes.int,
    }
  },
  invoiceTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  paymentAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal,
    }
  },
  currencyCode: {
    dataType: {
      type: MfModelFieldDataTypes.string,
    }
  },
  currencyAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTaxAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTotalAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currentOpenAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  amountToPay: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  resultingAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  totalQty: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  subTotal: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  total: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
};

export const ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG_ID = "invoiceCheckoutSummery";

export const ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG_ID,
  fields: ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG",
  },
  portals: {}
};