import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComAccountSelectModule } from "@e-commerce/accountSelect/account.select.module";
import { EComCartInvoiceComponent } from "@e-commerce/cart/invoice/cart.invoice.component";
import { MfTableModule, MfIconModule } from "@pattonair/material-framework";
import { EComCurrencySelectModule } from "../../currencySelect/currency.select.module";


@NgModule({
  declarations: [
    EComCartInvoiceComponent
  ],
  exports: [
    EComCartInvoiceComponent
  ],
  imports: [
    BrowserModule,
    EComAccountSelectModule,
    EComCurrencySelectModule,
    MatButtonModule,
    MatCheckboxModule,
    MfIconModule,
    MfTableModule,
    RouterModule,
  ],
  providers: [
  ]
})
export class EComCartInvoiceModule { }