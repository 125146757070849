import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, Injector, Input, OnInit } from "@angular/core";
import { MfTypeInfo, MfBaseComponent, MfSelectOption, mfTypeIsUndefined } from "@pattonair/material-framework";
import { EComCurrencyService } from "../services/currency.service";
import { EComCurrencyModel } from "../modelConfigs/currency.model.config";
const TYPE_INFO: MfTypeInfo = { className: "EComCurrencySelectComponent" };

@Component({
  selector: "ecom-currency-select",
  templateUrl: "./currency.select.component.html",
  styleUrls: ["./currency.select.component.scss"]
})
export class EComCurrencySelectComponent extends MfBaseComponent implements OnInit {
  @Input({ transform: booleanAttribute })
  public get disabled(): boolean {
    return this._disabled;
  }
  public set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  protected _options?: MfSelectOption<string>[];
  protected _accounts?: EComCurrencyModel[];
  protected _selectedId?: string;
  protected _selected?: EComCurrencyModel;
  protected _disabled: boolean = false;

  public constructor(
    protected override _injector: Injector,
    protected _currencyService: EComCurrencyService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._getCurrencies();
    this._setSelected();
  }

  protected _optionChanged(options: MfSelectOption<string> | undefined): void {
    if (!mfTypeIsUndefined(options)) {
      this._sub(this._currencyService.setSelectedByCode(options.value));
    }
  }

  protected _getCurrencies(): void {
    this._sub(this._currencyService.currencies, {
      next: (currencies) => {
        this._options = currencies.map(currency => ({ value: currency.code, label: currency.displayName }));
      }
    });
  }

  protected _onSelectedChange(selected: EComCurrencyModel): void {
    this._selectedId = selected.code;
    this._selected = selected;
  }

  protected _setSelected(): void {
    this._sub(this._currencyService.selected, {
      next: (selected) => {
        this._selectedId = selected?.code;
        this._selected = selected;
      }
    });
  }
}