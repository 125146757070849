import { MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG, MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes, MfPortalsRequestCollectionData } from "@pattonair/material-framework";

export type EComRecordOfPaymentRequestModel = MfPortalsRequestCollectionData & {
  cartKey?: string;
  key?: string;
}

export type EComRecordOfPaymentRequestModelModelFieldsConfig = {
  [key in keyof Required<EComRecordOfPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComRecordOfPaymentRequestModelModelFieldsConfig = {
  ...MF_PORTALS_REQUEST_COLLECTION_DATA_MODEL_FIELDS_CONFIG,
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG_ID = "recordOfPaymentRequest";

export const ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComRecordOfPaymentModel = MfModelBase & {
  documentKey: string,
  invoiceNumber: string;
  invoiceAmount: string;
  currencyCode: Date,
  currencyValue: string;
  currencyTaxValue?: string;
  currencyTotalValue: string;
  invoiceDate: string;
  paymentDueDate: Date,
  currentOpenAmount: number,
  resultingOpenAmount: number,
}

export type EComRecordOfPaymentModelModelFieldsConfig = {
  [key in keyof Required<EComRecordOfPaymentModel>]: MfModelFieldConfig
};

export const ECOM_RECORD_OF_PAYMENT_MODEL_FIELDS_CONFIG: EComRecordOfPaymentModelModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceNumber: {
    display: {
      displayName: "Invoice"
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  invoiceAmount: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyCode: {
    display: {
      displayName: "Currency"
    },
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  currencyValue: {
    display: {
      displayName: "Value"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTaxValue: {
    display: {
      displayName: "Tax"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currencyTotalValue: {
    display: {
      displayName: "Total Value"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  invoiceDate: {
    display: {
      displayName: "Invoice Date"
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  paymentDueDate: {
    display: {
      displayName: "Payment Due Date"
    },
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    },
  },
  currentOpenAmount: {
    display: {
      displayName: "Current Open Amount"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  resultingOpenAmount: {
    display: {
      displayName: "Resulting Open Amount"
    },
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
};

export const ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG_ID = "recordOfPayment";

export const ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG_ID,
  fields: ECOM_RECORD_OF_PAYMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG",
  },
  portals: {}
};