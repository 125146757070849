// eslint-disable-next-line @typescript-eslint/naming-convention
export const EComCartErrorTypes = {
  info: "info",
  warning: "warning",
  error: "error",
} as const;

export type EComCartErrorTypes = typeof EComCartErrorTypes[keyof typeof EComCartErrorTypes]

export type EComErrorValidationItemModel = {
  message: string;
  type: EComCartErrorTypes;
}