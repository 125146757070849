import { ECOM_ADDRESS_MODEL_CONFIG, ECOM_ADDRESS_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/address.model.config";
import { ECOM_CARRIER_MODEL_CONFIG, ECOM_CARRIER_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/carrier.model.config";
import { ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG, ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG, ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG, ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_CART_LINE_MODEL_CONFIG, ECOM_ORDER_CART_LINE_MODEL_CONFIG_ID, ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG, ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG, ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG_ID, ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG, ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG, ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG, ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/cart.order.model.config";
import { ECOM_ORDER_COMPLETE_MODEL_CONFIG, ECOM_ORDER_COMPLETE_MODEL_CONFIG_ID, ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG, ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG_ID, ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG, ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG_ID, ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG, ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/checkout.order.model.config";
import { ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG, ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID, ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG, ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID, ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG, ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/checkout.order.payment.model.config";
import { ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG, ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/checkout.order.summery.model.config";
import { ECOM_CUSTOMER_DETAIL_MODEL_CONFIG, ECOM_CUSTOMER_DETAIL_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/customer.detail.model.config";
import { ECOM_CUSTOMER_MODEL_CONFIG, ECOM_CUSTOMER_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/customer.model.config";
import { ECOM_INVOICE_DETAIL_MODEL_CONFIG, ECOM_INVOICE_DETAIL_MODEL_CONFIG_ID, ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG, ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/invoice.detail.model.config";
import { ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG, ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID, ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG, ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/invoice.document.model.config";
import { ECOM_INVOICE_MODEL_CONFIG, ECOM_INVOICE_MODEL_CONFIG_ID, ECOM_INVOICE_REQUEST_MODEL_CONFIG, ECOM_INVOICE_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/invoice.model.config";
import { ECOM_ITEM_DETAIL_MODEL_CONFIG, ECOM_ITEM_DETAIL_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/item.detail.model.config";
import { ECOM_MANUFACTURER_MODEL_CONFIG, ECOM_MANUFACTURER_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/manufacturer.model.config";
import { ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG, ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID, ECOM_ORDER_DOCUMENT_MODEL_CONFIG, ECOM_ORDER_DOCUMENT_MODEL_CONFIG_ID, ECOM_ORDER_DOCUMENTS_MODEL_CONFIG, ECOM_ORDER_DOCUMENTS_MODEL_CONFIG_ID, ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG, ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/order.document.model.config";
import { ECOM_ORDER_MODEL_CONFIG, ECOM_ORDER_MODEL_CONFIG_ID, ECOM_ORDER_REQUEST_MODEL_CONFIG, ECOM_ORDER_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/order.model.config";
import { ECOM_REQUEST_QUOTE_MODEL_CONFIG, ECOM_REQUEST_QUOTE_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/quote.model.config";
import { ECOM_STOCK_SUMMERY_MODEL_CONFIG, ECOM_STOCK_SUMMERY_MODEL_CONFIG_ID, ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG, ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/stock.summery.model.config";
import { ECOM_USER_APPROVED_MODEL_CONFIG, ECOM_USER_APPROVED_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/user.approved.model.config";
import { ECOM_USER_MODEL_CONFIG, ECOM_USER_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/user.model.config";
import { MfModelsConfig, MfModelConfig, mfModelLinkModelConfig } from "@pattonair/material-framework";
import { ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG, ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID, ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG, ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID, ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG, ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID, ECOM_INVOICE_CART_LINE_MODEL_CONFIG, ECOM_INVOICE_CART_LINE_MODEL_CONFIG_ID, ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG, ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG_ID, ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG, ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG_ID, ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG, ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG_ID } from "@e-commerce/modelConfigs/cart.invoice.model.config";
import { ECOM_CURRENCY_MODEL_CONFIG, ECOM_CURRENCY_MODEL_CONFIG_ID } from "./currency.model.config";
import { ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG, ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID, ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG, ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID } from "./checkout.invoice.payment.model.config";
import { ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG, ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG_ID } from "./checkout.invoice.summery.model.config";
import { ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG, ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG_ID, ECOM_INVOICE_CHECKOUT_DATA_MODEL_FIELDS_CONFIG, EComInvoiceCheckoutData } from "../checkout/invoice/checkout.invoice";
import { ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG, ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG_ID, ECOM_ORDER_CHECKOUT_DATA_MODEL_FIELDS_CONFIG, EComOrderCheckoutData } from "../checkout/order/checkout.order";
import { ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG, ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID } from "../checkout/checkout.summery";
import { ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG, ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG_ID, ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG, ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG_ID } from "./record.of.payment.nodel.config";
import { ECOM_NEW_CUSTOMER_MODEL_CONFIG, ECOM_NEW_CUSTOMER_MODEL_CONFIG_ID, ECOM_NEW_CUSTOMER_MODEL_FIELDS_CONFIG, EComNewCustomerModel } from "./new.customer.model.config";
import { ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG, ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG_ID } from "./new.customer.response.model.config";
import { ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG, ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG_ID, ECOM_INVOICE_COMPLETE_MODEL_CONFIG, ECOM_INVOICE_COMPLETE_MODEL_CONFIG_ID } from "./checkout.invoice.model.config";

export type EComModelsConfig = MfModelsConfig & {
  [ECOM_ADDRESS_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CARRIER_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CURRENCY_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CUSTOMER_DETAIL_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_CUSTOMER_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CART_LINE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_COMPLETE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_DETAIL_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_INVOICE_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ITEM_DETAIL_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_MANUFACTURER_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_NEW_CUSTOMER_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_LINE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_COMPLETE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_DOCUMENT_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_DOCUMENTS_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_REQUEST_QUOTE_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_STOCK_SUMMERY_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_USER_APPROVED_MODEL_CONFIG_ID]: MfModelConfig;
  [ECOM_USER_MODEL_CONFIG_ID]: MfModelConfig;
}

export const ECOM_MODEL_CONFIG: EComModelsConfig = {
  [ECOM_ADDRESS_MODEL_CONFIG_ID]: ECOM_ADDRESS_MODEL_CONFIG,
  [ECOM_CARRIER_MODEL_CONFIG_ID]: ECOM_CARRIER_MODEL_CONFIG,
  [ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG_ID]: ECOM_CHECKOUT_INVOICE_SUMMERY_MODEL_CONFIG,
  [ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG_ID]: ECOM_CHECKOUT_ORDER_SUMMERY_MODEL_CONFIG,
  [ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG_ID]: ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG,
  [ECOM_CURRENCY_MODEL_CONFIG_ID]: ECOM_CURRENCY_MODEL_CONFIG,
  [ECOM_CUSTOMER_DETAIL_MODEL_CONFIG_ID]: ECOM_CUSTOMER_DETAIL_MODEL_CONFIG,
  [ECOM_CUSTOMER_MODEL_CONFIG_ID]: ECOM_CUSTOMER_MODEL_CONFIG,
  [ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_ADD_CART_ITEM_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_CART_LINE_MODEL_CONFIG_ID]: ECOM_INVOICE_CART_LINE_MODEL_CONFIG,
  [ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_CART_LINE_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG_ID]: ECOM_INVOICE_CART_RESPONSE_MODEL_CONFIG,
  [ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG_ID]: ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG,
  [ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID]: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_CONFIG,
  [ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG_ID]: ECOM_INVOICE_CHECKOUT_DATA_MODEL_CONFIG,
  [ECOM_INVOICE_COMPLETE_MODEL_CONFIG_ID]: ECOM_INVOICE_COMPLETE_MODEL_CONFIG,
  [ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_CREATE_CART_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_DETAIL_MODEL_CONFIG_ID]: ECOM_INVOICE_DETAIL_MODEL_CONFIG,
  [ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_DETAIL_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID]: ECOM_INVOICE_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG,
  [ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG,
  [ECOM_INVOICE_MODEL_CONFIG_ID]: ECOM_INVOICE_MODEL_CONFIG,
  [ECOM_INVOICE_REQUEST_MODEL_CONFIG_ID]: ECOM_INVOICE_REQUEST_MODEL_CONFIG,
  [ECOM_ITEM_DETAIL_MODEL_CONFIG_ID]: ECOM_ITEM_DETAIL_MODEL_CONFIG,
  [ECOM_MANUFACTURER_MODEL_CONFIG_ID]: ECOM_MANUFACTURER_MODEL_CONFIG,
  [ECOM_NEW_CUSTOMER_MODEL_CONFIG_ID]: ECOM_NEW_CUSTOMER_MODEL_CONFIG,
  [ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG_ID]: ECOM_NEW_CUSTOMER_RESPONSE_MODEL_CONFIG,
  [ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_ADD_CART_ITEM_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_ADD_CART_ITEMS_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CART_DELETE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CART_LINE_MODEL_CONFIG_ID]: ECOM_ORDER_CART_LINE_MODEL_CONFIG,
  [ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CART_LINE_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG_ID]: ECOM_ORDER_CART_RESPONSE_MODEL_CONFIG,
  [ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CART_UPDATE_MULTIPLE_ITEMS_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG_ID]: ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG,
  [ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG_ID]: ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG,
  [ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID]: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG,
  [ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG_ID]: ECOM_ORDER_CHECKOUT_DATA_MODEL_CONFIG,
  [ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_COMPLETE_MODEL_CONFIG_ID]: ECOM_ORDER_COMPLETE_MODEL_CONFIG,
  [ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_CREATE_CART_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG_ID]: ECOM_ORDER_DOCUMENT_DOWNLOAD_INFO_MODEL_CONFIG,
  [ECOM_ORDER_DOCUMENT_MODEL_CONFIG_ID]: ECOM_ORDER_DOCUMENT_MODEL_CONFIG,
  [ECOM_ORDER_DOCUMENTS_MODEL_CONFIG_ID]: ECOM_ORDER_DOCUMENTS_MODEL_CONFIG,
  [ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_DOWNLOAD_DOCUMENT_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_MODEL_CONFIG_ID]: ECOM_ORDER_MODEL_CONFIG,
  [ECOM_ORDER_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_REQUEST_MODEL_CONFIG,
  [ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG_ID]: ECOM_ORDER_UPDATE_CART_ITEM_REQUEST_MODEL_CONFIG,
  [ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG_ID]: ECOM_RECORD_OF_PAYMENT_MODEL_CONFIG,
  [ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG_ID]: ECOM_RECORD_OF_PAYMENT_REQUEST_MODEL_CONFIG,
  [ECOM_REQUEST_QUOTE_MODEL_CONFIG_ID]: ECOM_REQUEST_QUOTE_MODEL_CONFIG,
  [ECOM_STOCK_SUMMERY_MODEL_CONFIG_ID]: ECOM_STOCK_SUMMERY_MODEL_CONFIG,
  [ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG_ID]: ECOM_STOCK_SUMMERY_REQUEST_MODEL_CONFIG,
  [ECOM_USER_APPROVED_MODEL_CONFIG_ID]: ECOM_USER_APPROVED_MODEL_CONFIG,
  [ECOM_USER_MODEL_CONFIG_ID]: ECOM_USER_MODEL_CONFIG,
} as const;

mfModelLinkModelConfig<EComInvoiceCheckoutData>(ECOM_INVOICE_CHECKOUT_DATA_MODEL_FIELDS_CONFIG, "summery", ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG);

mfModelLinkModelConfig<EComOrderCheckoutData>(ECOM_ORDER_CHECKOUT_DATA_MODEL_FIELDS_CONFIG, "summery", ECOM_CHECKOUT_SUMMERY_MODEL_CONFIG);

mfModelLinkModelConfig<EComNewCustomerModel>(ECOM_NEW_CUSTOMER_MODEL_FIELDS_CONFIG, "billingAddress", ECOM_ADDRESS_MODEL_CONFIG);
mfModelLinkModelConfig<EComNewCustomerModel>(ECOM_NEW_CUSTOMER_MODEL_FIELDS_CONFIG, "shippingAddress", ECOM_ADDRESS_MODEL_CONFIG);