import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComCartPreviewModule } from "@e-commerce/cartPreview/cart.preview.module";
import { EComFooterModule } from "@e-commerce/footer/footer.module";
import { EComRootAuthnComponent } from "@e-commerce/root/authn/root.authn.component";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { MfAuthModule, MfFooterModule, MfIconModule, MfRootModule, MfUserPersonaModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComRootAuthnComponent
  ],
  exports: [
    EComRootAuthnComponent
  ],
  imports: [
    BrowserModule,
    MfRootModule,
    MfAuthModule,
    MfUserPersonaModule,
    MfIconModule,
    MfFooterModule,
    MatButtonModule,
    EComCartPreviewModule,
    EComFooterModule,
    RouterModule,
  ],
  providers: [
    EComRootService,
  ]
})
export class EComRootAuthnModule {
}