import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComCheckoutOrderSummeryModel } from "@e-commerce/modelConfigs/checkout.order.summery.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, MfSessionStorageService } from "@pattonair/material-framework";
import { concatMap, map, Observable } from "rxjs";
import { CHECKOUT_ORDER_DATA_STORAGE_KEY } from "../checkout/order/checkout.order.component";
import { EComCheckoutSummery } from "../checkout/checkout.summery";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutOrderService" };

@Injectable({ providedIn: "root" })
export class EComCheckoutOrderService extends MfBaseService {
  protected _checkoutSummeryModelConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _customerService: EComCustomerService,
    protected _cartOrderService: EComCartOrderService,
    protected _storageService: MfSessionStorageService,
  ) {
    super(TYPE_INFO, _injector);
    this._checkoutSummeryModelConfig = this._modelConfigService.get<EComModelsConfig>("orderCheckoutSummery");

    this._sub(this._customerService.onSelectedChange, { next: () => this._clearCache() });
  }

  public get summery(): Observable<EComCheckoutSummery> {
    return this._customerService.selected.pipe(
      concatMap((selectedCustomer) => {
        return this._cartOrderService.id.pipe(
          concatMap((cartId) => {
            return this._portalsClientService.getItemGet<EComCheckoutOrderSummeryModel>(
              this._checkoutSummeryModelConfig,
              `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsCartsUrl}/${selectedCustomer.key}/${ECOM_ENVIRONMENT.portalsOrdersUrl}/${cartId}/summary`
            ).pipe(
              map((orderSummery) => ({
                itemsCount: orderSummery.totalItems,
                totalQty: orderSummery.totalQuantity,
                currencyCode: orderSummery.currencyCode,
                subTotal: orderSummery.netTotal,
                total: orderSummery.orderTotal,
              }))
            );
          }),
        );
      }),
    );
  }

  protected _clearCache(): void {
    this._storageService.remove(CHECKOUT_ORDER_DATA_STORAGE_KEY);
  }
}