import { MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";

export type EComCarrierModel = {
  uniqueIdentifier: number;
  carrierIndicator: string;
  carrierName: string;
  carrierKey: string;
}


export type EComCarrierModelFieldsConfig = {
  [key in keyof Required<EComCarrierModel>]: MfModelFieldConfig
};

export const ECOM_CARRIER_MODEL_FIELDS_CONFIG: EComCarrierModelFieldsConfig = {
  uniqueIdentifier: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  carrierIndicator: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_CARRIER_MODEL_CONFIG_ID = "carrier";

export const ECOM_CARRIER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_CARRIER_MODEL_CONFIG_ID,
  fields: ECOM_CARRIER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_CARRIER_MODEL_CONFIG",
  },
  portals: {
    mock: {}
  }
};