import { Injectable, Injector } from "@angular/core";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComUserApprovedModel } from "@e-commerce/modelConfigs/user.approved.model.config";
import { MfTypeInfo, MfBaseService, MfModelConfigMapped, MfPortalsClientService, MfModelConfigService, MfAuthService, mfTypeIsUndefined } from "@pattonair/material-framework";
import { map, Observable, of, share } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComUserService" };

@Injectable({ providedIn: "root" })
export class EComUserService extends MfBaseService {
  protected _userApprovedModelConfig: MfModelConfigMapped;
  protected _isApproved?: boolean;
  protected _isApproved$?: Observable<boolean>;

  public constructor(
    protected override _injector: Injector,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
    protected _authService: MfAuthService,
  ) {
    super(TYPE_INFO, _injector);
    this._userApprovedModelConfig = this._modelConfigService.get<EComModelsConfig>("userApproved");
    this._sub(this._authService.onLoggedOut, {
      next: () => {
        delete this._isApproved;
        delete this._isApproved$;
      }
    });
  }

  public get isApproved(): Observable<boolean> {
    if (!mfTypeIsUndefined(this._isApproved)) {
      return of(this._isApproved);
    } else {
      if (mfTypeIsUndefined(this._isApproved$)) {
        this._isApproved$ = this._portalsClientService.getItemGet<EComUserApprovedModel>(
          this._userApprovedModelConfig,
          `${ECOM_ENVIRONMENT.portalsUserRootUrl}/MyAccount/User/Check`
        ).pipe(
          map((userApproved) => {
            this._isApproved = userApproved.active;
            return this._isApproved;
          }),
          share(),
        );
      }
      return this._isApproved$;
    }
  }
}