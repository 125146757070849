import { Injector } from "@angular/core";
import { MfModelFieldUserPersonsConfig, MfModelBase, MfModelFieldConfig, MfFunctionRef, mfTypeIsNullOrUndefined, mfStringIsEmptyOrWhitespace, mfStringGetInitials, mfFunctionRefRegisterFunctionRef, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";


export const ECOM_USER_MODEL_CONFIG_ID = "user";

export const ECOM_USER_PERSONA_CONFIG: MfModelFieldUserPersonsConfig = {
  requiredFields: {
    type: "userPersona",
  },
  mappings: {
    idMap: "id",
    displayNameMap: "displayName",
    emailMap: "mail",
    photoMap: "userPhoto.contentB64",
    jobTitleMap: "jobTitle",
    initialsMap: "initials",
  }
};

export type EComUserModel = MfModelBase & {
  id?: number;
  lastUpdated?: Date;
  objectId?: string;
  userPrincipalName?: string;
  givenName?: string;
  surname?: string;
  mail?: string;
  displayName?: string;
  jobTitle?: string;
  initials?: string;
}

export type EComUserModelFieldsConfig = {
  [key in keyof Required<EComUserModel>]: MfModelFieldConfig
};

export const getInitialsValueFunctionRef: MfFunctionRef<string, EComUserModel> = {
  function: (injector: Injector, model?: EComUserModel) => {
    if (!mfTypeIsNullOrUndefined(model)) {
      if (!mfTypeIsNullOrUndefined(model.displayName) && !mfStringIsEmptyOrWhitespace(model.displayName)) {
        return mfStringGetInitials(model.displayName);
      }
    }
    return "";
  },
  name: "getInitialsValue",
};
const getInitialsValuePointer = mfFunctionRefRegisterFunctionRef(getInitialsValueFunctionRef);

export const ECOM_USER_MODEL_FIELDS_CONFIG: EComUserModelFieldsConfig = {
  initials: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
    valueLoadFormatter: {
      calculated: {
        getValueFunctionPointer: getInitialsValuePointer,
      }
    }
  },
  jobTitle: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  userPrincipalName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  givenName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  displayName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  mail: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  surname: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  objectId: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  id: {
    isId: true,
    dataType: {
      type: MfModelFieldDataTypes.int
    }
  },
  lastUpdated: {
    dataType: {
      type: MfModelFieldDataTypes.dateTime
    }
  },
};

export const ECOM_USER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_USER_MODEL_CONFIG_ID,
  fields: ECOM_USER_MODEL_FIELDS_CONFIG,
  userPersona: ECOM_USER_PERSONA_CONFIG,
  autoMapping: {
    constName: "ECOM_USER_MODEL_CONFIG",
  }
};