import { MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";
import { EComAddressModel } from "./address.model.config";
import { EComFreightHandlingTypes } from "../common/freight.handling.types";
import { EComPaymentTermsTypes } from "../common/payment.terms.types";
import { EComShippingMethodsTypes } from "../common/shipping.method.types";

export type EComNewCustomerModel = MfModelBase & {
  name: string;
  doingBusinessAs: string;
  dunBradstreetNumber?: number;
  website?: string;
  telephoneNumber: string;
  faxNumber?: string;
  billingAddress: EComAddressModel,
  shippingAddress: EComAddressModel,
  shippingFreightForwarderName?: string;
  shippingFreightHandlingCode: EComFreightHandlingTypes;
  shippingMethod?: EComShippingMethodsTypes | string;
  shippingCarrierAcct?: string;
  deliveryInstructions?: string;
  shelfLife: number;
  payableContactName: string;
  payableContactEmail: string;
  payableContactPhoneNumber?: string;
  payableContactFaxNumber?: string;
  receiveInvoicesEmail?: string;
  paymentTermsRequested: EComPaymentTermsTypes;
  creditLimit?: number;
  totalAssets?: number;
  totalLiabilities?: number;
  totalEquity?: number;
  totalDebt?: number;
  currentAssets?: number;
  currentLiabilities?: number;
  totalInventory?: number;
  financialQuestions?: string;
  taxId: string;
  certificateRequirement: boolean;
  accepted: boolean;
  legalName: string;
  authorizedParty: string;
  date: Date;
};

export type EComNewCustomerModelFieldsConfig = {
  [key in keyof Required<EComNewCustomerModel>]: MfModelFieldConfig
};

export const ECOM_NEW_CUSTOMER_MODEL_FIELDS_CONFIG: EComNewCustomerModelFieldsConfig = {
  name: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  doingBusinessAs: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  dunBradstreetNumber: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  website: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  telephoneNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  faxNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  billingAddress: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  shippingAddress: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
  shippingFreightForwarderName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shippingFreightHandlingCode: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shippingMethod: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shippingCarrierAcct: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  deliveryInstructions: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  shelfLife: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  payableContactName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  payableContactEmail: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  payableContactPhoneNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  payableContactFaxNumber: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  receiveInvoicesEmail: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentTermsRequested: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  creditLimit: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  totalAssets: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  totalLiabilities: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  totalEquity: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  totalDebt: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currentAssets: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  currentLiabilities: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  totalInventory: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  financialQuestions: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  taxId: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  certificateRequirement: {
    dataType: {
      type: MfModelFieldDataTypes.boolean
    },
  },
  accepted: {
    dataType: {
      type: MfModelFieldDataTypes.boolean
    },
  },
  legalName: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  authorizedParty: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  date: {
    dataType: {
      type: MfModelFieldDataTypes.date
    },
  }
};

export const ECOM_NEW_CUSTOMER_MODEL_CONFIG_ID = "newCustomer";

export const ECOM_NEW_CUSTOMER_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_NEW_CUSTOMER_MODEL_CONFIG_ID,
  fields: ECOM_NEW_CUSTOMER_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_NEW_CUSTOMER_MODEL_CONFIG",
  }
};