import { Injectable, Injector } from "@angular/core";
import { MfAuthService, MfBaseService, MfModelConfigMapped, MfModelConfigService, MfPortalsClientService, MfTypeInfo } from "@pattonair/material-framework";
import { map, Observable, of } from "rxjs";
import { EComModelsConfig } from "../../modelConfigs/model.config";
import { EComNewCustomerModel } from "../../modelConfigs/new.customer.model.config";
import { EComNewCustomerResponseModel } from "../../modelConfigs/new.customer.response.model.config";

const TYPE_INFO: MfTypeInfo = { className: "EComUserService" };

@Injectable()
export class EComNewCustomerSetupService extends MfBaseService {
  protected _newCustomerModelConfig: MfModelConfigMapped;
  protected _newCustomerModelResponseConfig: MfModelConfigMapped;

  public constructor(
    protected override _injector: Injector,
    protected _authService: MfAuthService,
    protected _portalsClientService: MfPortalsClientService,
    protected _modelConfigService: MfModelConfigService,
  ) {
    super(TYPE_INFO, _injector);
    this._newCustomerModelConfig = this._modelConfigService.get<EComModelsConfig>("newCustomer");
    this._newCustomerModelResponseConfig = this._modelConfigService.get<EComModelsConfig>("newCustomerResponse");
  }

  public submit(newCustomer: EComNewCustomerModel): Observable<number> {
    return of(1);
    // return this._portalsClientService.getItemPost<EComNewCustomerResponseModel, EComNewCustomerModel>(
    //   this._newCustomerModelResponseConfig,
    //   this._newCustomerModelConfig,
    //   newCustomer,
    //   "", //`${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsNewCustomersUrl}`
    // ).pipe(
    //   map((response) => response.id)
    // );
  }

  public save(newCustomer: EComNewCustomerModel): Observable<boolean> {
    return of(true);
  }

  public getLast(): Observable<EComNewCustomerModel> {
    return of();
  }
}