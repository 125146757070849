import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { EComManufacturerDetailLineModule } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.module";
import { EComQuoteDialogComponent } from "@e-commerce/productDetail/dialog/quote.dialog.component";
import { EComProductDetailComponent } from "@e-commerce/productDetail/product.detail.component";
import { MfTableModule, MfPipesModule, MfInputModule } from "@pattonair/material-framework";


@NgModule({
  declarations: [
    EComProductDetailComponent,
    EComQuoteDialogComponent,
  ],
  exports: [
    EComProductDetailComponent,
    EComQuoteDialogComponent,
  ],
  imports: [
    BrowserModule,
    MatButtonModule,
    MfTableModule,
    MfPipesModule,
    EComManufacturerDetailLineModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    RouterModule,
    MfInputModule
  ],
  providers: [
  ]
})
export class EComProductDetailModule { }