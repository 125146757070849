import { Directive, Injector } from "@angular/core";
import { MfTypeInfo, MfBaseService, MfModelBase, mfTypeIsUndefined } from "@pattonair/material-framework";
import { merge, Observable, Subject } from "rxjs";
import { EComCustomerService } from "./customer.service";
import { EComCartModeService } from "./cart.mode.service";
import { EComErrorValidationItemModel } from "./cart.action";

@Directive()
export abstract class EComCartBaseService<TCartValidationItemModel extends MfModelBase> extends MfBaseService {
  public onCountChanged: Subject<number> = new Subject();

  protected _customerService: EComCustomerService;
  protected _cartModeService: EComCartModeService;

  protected _errors?: EComErrorValidationItemModel[];
  protected _errorItems?: TCartValidationItemModel[];
  protected _count?: number;
  protected _count$?: Observable<number>;
  protected _create$?: Observable<string>;
  protected _idInt?: string;

  public constructor(
    protected override _typeInfo: MfTypeInfo,
    protected override _injector: Injector,
  ) {
    super(_typeInfo, _injector);
    this._customerService = this._injector.get(EComCustomerService);
    this._cartModeService = this._injector.get(EComCartModeService);

    this._sub(this._customerService.selected, {
      next: () => {
        this._sub(merge(this._customerService.onSelectedChange), { next: () => this._clearCache() });
      }
    });
  }

  public get hasValidationErrors(): boolean {
    return !mfTypeIsUndefined(this._errors) && this._errors.length > 0 || !mfTypeIsUndefined(this._errorItems) && this._errorItems.length > 0;
  }

  public clearErrors(): void {
    delete this._errors;
    delete this._errorItems;
  }

  protected _hasValidationErrors(result: { errors?: EComErrorValidationItemModel[], items?: TCartValidationItemModel[], }): boolean {
    return !mfTypeIsUndefined(result.items) && result.items.length > 0 || !mfTypeIsUndefined(result.errors) && result.errors.length > 0;
  }

  public get errors(): EComErrorValidationItemModel[] | undefined {
    return this._errors;
  }

  public get errorItems(): TCartValidationItemModel[] | undefined {
    return this._errorItems;
  }

  protected _combineErrors(result: { errors?: EComErrorValidationItemModel[], items?: TCartValidationItemModel[], }): void {
    this._errors = result.errors;
    this._errorItems = result.items;
  }

  public abstract get count(): Observable<number>;
  public abstract get id(): Observable<string>;

  protected _clearCache(): void {
    delete this._idInt;
    delete this._count$;
    delete this._create$;
    delete this._count;
    this.onCountChanged.next(0);
  }
}