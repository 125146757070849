import { coerceBooleanProperty, coerceNumberProperty } from "@angular/cdk/coercion";
import { Component, Injector, signal } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatExpansionPanel } from "@angular/material/expansion";
import { Router } from "@angular/router";
import { ERROR_MESSAGE_EMAIL, ERROR_MESSAGE_MAX_LENGTH, ERROR_MESSAGE_PHONE_NUMBER_LOOSE, ERROR_MESSAGE_REQUIRED, MfBaseValidationComponent, MfInputTypes, MfTypeInfo, mfTypeIsNullOrUndefined, mfTypeIsUndefined, MfValidators } from "@pattonair/material-framework";
import { merge } from "rxjs";
import { EComNewCustomerSetupService } from "./new.customer.setup.service";
import { ecomGetAddressTypesDisplayValue, EComAddressTypes } from "../../common/address.type";
import { EComFreightHandlingTypes, ecomGetFreightHandlingTypesDisplayValue } from "../../common/freight.handling.types";
import { ecomGetPaymentTermsTypesDisplayValue, EComPaymentTermsTypes } from "../../common/payment.terms.types";
import { ecomGetShippingMethodsTypesDisplayValue, EComShippingMethodsTypes } from "../../common/shipping.method.types";
import { EComAddressModel } from "../../modelConfigs/address.model.config";
import { EComNewCustomerModel } from "../../modelConfigs/new.customer.model.config";
import { EComRouteNames } from "../../ecom.route.names";

const TYPE_INFO: MfTypeInfo = { className: "EComNewCustomerSetupComponent" };

type EComCreditLimit = {
  value: number;
  label: string;
};

export const CM_CREDIT_LIMITS: EComCreditLimit[] = [
  { value: 5000, label: "5,000" },
  { value: 10000, label: "10,000" },
  { value: 20000, label: "20,000" },
  { value: 30000, label: "30,000" },
  { value: 40000, label: "40,000" },
  { value: 50000, label: "50,000" },
  { value: 60000, label: "60,000" },
  { value: 70000, label: "70,000" },
  { value: 80000, label: "80,000" },
  { value: 90000, label: "90,000" },
  { value: 100000, label: "100,000" },
  { value: 120000, label: "120,000" },
  { value: 140000, label: "140,000" },
  { value: 160000, label: "160,000" },
  { value: 180000, label: "180,000" },
  { value: 200000, label: "200,000" },
  { value: 250000, label: "250,000" },
  { value: 300000, label: "300,000" },
  { value: 350000, label: "350,000" },
  { value: 400000, label: "400,000" },
  { value: 450000, label: "450,000" },
  { value: 500000, label: "500,000" },
  { value: 750000, label: "750,000" },
  { value: 1000000, label: "1,000,000" },
  { value: 0, label: "Other" },
];

@Component({
  selector: "ecom-new-customer-setup",
  templateUrl: "./new.customer.setup.component.html",
  styleUrls: ["./new.customer.setup.component.scss"]
})
export class EComNewCustomerSetupComponent extends MfBaseValidationComponent {
  protected _step = signal(0);

  protected _expansionIndex = 0;

  protected _showAccountsCreditLimitFormControl = false;
  protected _showAccountsCreditLimitOtherFormControl = false;

  protected _showShippingAddressFreightForwarderNameFormControl = false;

  protected _showShippingInfoShippingMethodFormControl = false;
  protected _showShippingInfoShippingCarrierAcctFormControl = false;

  protected _showFinancialTotalAssetsFormControl = false;
  protected _showFinancialTotalLiabilitiesFormControl = false;
  protected _showFinancialTotalEquityFormControl = false;
  protected _showFinancialTotalDebtFormControl = false;
  protected _showFinancialCurrentAssetsFormControl = false;
  protected _showFinancialCurrentLiabilitiesFormControl = false;
  protected _showFinancialTotalInventoryFormControl = false;
  protected _showFinancialQuestionsFormControl = false;
  protected _showFinancialFileUpload = false;

  protected _getAddressTypesDisplayValue = ecomGetAddressTypesDisplayValue;
  protected _addressTypes = EComAddressTypes;

  protected _getFreightHandlingTypesDisplayValue = ecomGetFreightHandlingTypesDisplayValue;
  protected _freightHandlingTypes = EComFreightHandlingTypes;

  protected _getShippingMethodsTypesDisplayValue = ecomGetShippingMethodsTypesDisplayValue;
  protected _shippingMethodsTypes = EComShippingMethodsTypes;

  protected _getPaymentTermsTypesDisplayValue = ecomGetPaymentTermsTypesDisplayValue;
  protected _paymentTermsTypes = EComPaymentTermsTypes;

  protected _inputTypes = MfInputTypes;

  protected readonly _creditLimits = CM_CREDIT_LIMITS;

  protected readonly _customerInfoFormGroup: FormGroup;

  protected readonly _customerInfoCompanyNameName = "companyName" as const;
  protected readonly _customerInfoCompanyNameFormControl = new FormControl();

  protected readonly _customerInfoDoingBusinessAsName = "doingBusinessAs" as const;
  protected readonly _customerInfoDoingBusinessAsFormControl = new FormControl();

  protected readonly _customerInfoDunBradstreetNumberName = "dunBradstreetNumber" as const;
  protected readonly _customerInfoDunBradstreetNumberFormControl = new FormControl();

  protected readonly _customerInfoWebsiteName = "website" as const;
  protected readonly _customerInfoWebsiteFormControl = new FormControl();

  protected readonly _customerInfoTelephoneNumberName = "telephoneNumber" as const;
  protected readonly _customerInfoTelephoneNumberFormControl = new FormControl();

  protected readonly _customerInfoFaxNumberName = "faxNumber" as const;
  protected readonly _customerInfoFaxNumberFormControl = new FormControl();

  protected readonly _billingAddressFormGroup: FormGroup;

  protected readonly _billingAddressLine1Name = "line1" as const;
  protected readonly _billingAddressLine1FormControl = new FormControl();

  protected readonly _billingAddressLine2Name = "line2" as const;
  protected readonly _billingAddressLine2FormControl = new FormControl();

  protected readonly _billingAddressLine3Name = "line3" as const;
  protected readonly _billingAddressLine3FormControl = new FormControl();

  protected readonly _billingAddressCityName = "city" as const;
  protected readonly _billingAddressCityFormControl = new FormControl();

  protected readonly _billingAddressStateName = "state" as const;
  protected readonly _billingAddressStateFormControl = new FormControl();

  protected readonly _billingAddressPostalCodeName = "postalCode" as const;
  protected readonly _billingAddressPostalCodeFormControl = new FormControl();

  protected readonly _billingAddressCountryName = "country" as const;
  protected readonly _billingAddressCountryFormControl = new FormControl();

  protected readonly _billingAddressTypeName = "type" as const;
  protected readonly _billingAddressTypeFormControl = new FormControl();

  protected readonly _shippingAddressFormGroup: FormGroup;

  protected readonly _shippingAddressSameBillingName = "sameAsBilling" as const;
  protected readonly _shippingAddressSameBillingFormControl = new FormControl();

  protected readonly _shippingAddressLine1Name = "line1" as const;
  protected readonly _shippingAddressLine1FormControl = new FormControl();

  protected readonly _shippingAddressLine2Name = "line2" as const;
  protected readonly _shippingAddressLine2FormControl = new FormControl();

  protected readonly _shippingAddressLine3Name = "line3" as const;
  protected readonly _shippingAddressLine3FormControl = new FormControl();

  protected readonly _shippingAddressCityName = "city" as const;
  protected readonly _shippingAddressCityFormControl = new FormControl();

  protected readonly _shippingAddressStateName = "state" as const;
  protected readonly _shippingAddressStateFormControl = new FormControl();

  protected readonly _shippingAddressPostalCodeName = "postalCode" as const;
  protected readonly _shippingAddressPostalCodeFormControl = new FormControl();

  protected readonly _shippingAddressCountryName = "country" as const;
  protected readonly _shippingAddressCountryFormControl = new FormControl();

  protected readonly _shippingAddressTypeName = "type" as const;
  protected readonly _shippingAddressTypeFormControl = new FormControl();

  protected readonly _shippingAddressFreightForwarderNameName = "freightForwarderName" as const;
  protected readonly _shippingAddressFreightForwarderNameFormControl = new FormControl();

  protected readonly _shippingInfoFormGroup: FormGroup;

  protected readonly _shippingInfoFreightHandlingCodeName = "freightHandlingCode" as const;
  protected readonly _shippingInfoFreightHandlingCodeFormControl = new FormControl();

  protected readonly _shippingInfoShippingMethodName = "shippingMethod" as const;
  protected readonly _shippingInfoShippingMethodFormControl = new FormControl();

  protected readonly _shippingInfoShippingMethodOtherName = "shippingMethodOther" as const;
  protected readonly _shippingInfoShippingMethodOtherFormControl = new FormControl();

  protected readonly _shippingInfoShippingCarrierAcctName = "carrierAcct" as const;
  protected readonly _shippingInfoShippingCarrierAcctFormControl = new FormControl();

  protected readonly _shippingInfoDeliveryInstructionsName = "deliveryInstructions" as const;
  protected readonly _shippingInfoDeliveryInstructionsFormControl = new FormControl();

  protected readonly _shippingInfoShelfLifeName = "shelfLife" as const;
  protected readonly _shippingInfoShelfLifeFormControl = new FormControl();

  protected readonly _accountsFormGroup: FormGroup;

  protected readonly _accountsPayableContactNameName = "payableContactName" as const;
  protected readonly _accountsPayableContactNameFormControl = new FormControl();

  protected readonly _accountsPayableContactEmailAddressName = "payableContactEmailAddress" as const;
  protected readonly _accountsPayableContactEmailAddressFormControl = new FormControl();

  protected readonly _accountsPayableContactPhoneNumberName = "payableContactPhoneNumber" as const;
  protected readonly _accountsPayableContactPhoneNumberFormControl = new FormControl();

  protected readonly _accountsPayableContactFaxNumberName = "faxNumber" as const;
  protected readonly _accountsPayableContactFaxNumberFormControl = new FormControl();

  protected readonly _accountsReceiveInvoicesEmailName = "receiveInvoicesEmail" as const;
  protected readonly _accountsReceiveInvoicesEmailFormControl = new FormControl();

  protected readonly _accountsPaymentTermsRequestedName = "paymentTermsRequested" as const;
  protected readonly _accountsPaymentTermsRequestedFormControl = new FormControl();

  protected readonly _accountsCreditLimitName = "creditLimit" as const;
  protected readonly _accountsCreditLimitFormControl = new FormControl();

  protected readonly _accountsCreditLimitOtherName = "creditLimitOther" as const;
  protected readonly _accountsCreditLimitOtherFormControl = new FormControl();

  protected readonly _financialFormGroup: FormGroup;

  protected readonly _financialTotalAssetsName = "totalAssets" as const;
  protected readonly _financialTotalAssetsFormControl = new FormControl();

  protected readonly _financialTotalLiabilitiesName = "totalLiabilities" as const;
  protected readonly _financialTotalLiabilitiesFormControl = new FormControl();

  protected readonly _financialTotalEquityName = "totalEquity" as const;
  protected readonly _financialTotalEquityFormControl = new FormControl();

  protected readonly _financialTotalDebtName = "totalDebt" as const;
  protected readonly _financialTotalDebtFormControl = new FormControl();

  protected readonly _financialCurrentAssetsName = "currentAssets" as const;
  protected readonly _financialCurrentAssetsFormControl = new FormControl();

  protected readonly _financialCurrentLiabilitiesName = "currentLiabilities" as const;
  protected readonly _financialCurrentLiabilitiesFormControl = new FormControl();

  protected readonly _financialTotalInventoryName = "totalInventory" as const;
  protected readonly _financialTotalInventoryFormControl = new FormControl();

  protected readonly _financialQuestionsName = "currencyUsed" as const;
  protected readonly _financialQuestionsFormControl = new FormControl();

  protected readonly _financialTaxIDName = "taxID" as const;
  protected readonly _financialTaxIDFormControl = new FormControl();

  protected readonly _financialCertificateRequirementName = "certificateRequirement" as const;
  protected readonly _financialCertificateRequirementFormControl = new FormControl();

  protected readonly _termsAndConditionsFormGroup: FormGroup;

  protected readonly _termsAndConditionsAcceptedName = "accepted" as const;
  protected readonly _termsAndConditionsAcceptedFormControl = new FormControl();

  protected readonly _termsAndConditionsLegalNameCompanyName = "legalNameCompany" as const;
  protected readonly _termsAndConditionsLegalNameCompanyFormControl = new FormControl();

  protected readonly _termsAndConditionsAuthorizedPartyName = "authorizedParty" as const;
  protected readonly _termsAndConditionsAuthorizedPartyFormControl = new FormControl();

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _newCustomerSetupService: EComNewCustomerSetupService,
  ) {
    super(TYPE_INFO, _injector);
    this._customerInfoFormGroup = new FormGroup([]);
    this._customerInfoBuildFormGroup();
    this._customerInfoAddEvents();
    this._customerInfoSetInitialState();

    this._billingAddressFormGroup = new FormGroup([]);
    this._billingAddressBuildFormGroup();
    this._billingAddressAddEvents();
    this._billingAddressSetInitialState();

    this._shippingAddressFormGroup = new FormGroup([]);
    this._shippingAddressBuildFormGroup();
    this._shippingAddressAddEvents();
    this._shippingAddressSetInitialState();

    this._shippingInfoFormGroup = new FormGroup([]);
    this._shippingInfoBuildFormGroup();
    this._shippingInfoAddEvents();
    this._shippingInfoSetInitialState();

    this._accountsFormGroup = new FormGroup([]);
    this._accountsBuildFormGroup();
    this._accountsAddEvents();
    this._accountsSetInitialState();

    this._financialFormGroup = new FormGroup([]);
    this._financialBuildFormGroup();
    this._financialAddEvents();
    this._financialSetInitialState();

    this._termsAndConditionsFormGroup = new FormGroup([]);
    this._termsAndConditionsBuildFormGroup();
    this._termsAndConditionsAddEvents();
    this._termsAndConditionsSetInitialState();

    this._formGroupsAddEvents();
  }

  protected get _isShippingAddressSameBilling(): boolean {
    return coerceBooleanProperty(this._shippingAddressSameBillingFormControl.value);
  }
  protected get _shippingInfoFreightHandlingType(): EComFreightHandlingTypes {
    return this._shippingInfoFreightHandlingCodeFormControl.value;
  }
  protected get _shippingInfoShippingMethodType(): EComShippingMethodsTypes {
    return this._shippingInfoShippingMethodFormControl.value;
  }
  protected get _accountsPaymentTermsRequestedFType(): EComPaymentTermsTypes {
    return this._accountsPaymentTermsRequestedFormControl.value;
  }
  protected get _accountsCreditLimit(): number | undefined {
    if (this._accountsPaymentTermsRequestedFType === EComPaymentTermsTypes.credit) {
      const selectedValue = coerceNumberProperty(this._accountsCreditLimitFormControl.value);
      return selectedValue === 0 ? coerceNumberProperty(this._accountsCreditLimitOtherFormControl.value) : selectedValue;
    }
    return undefined;
  }
  protected get _customerInfoCompanyName(): string {
    return this._customerInfoCompanyNameFormControl.value;
  }
  protected get _customerInfoDoingBusinessAs(): string {
    return this._customerInfoDoingBusinessAsFormControl.value;
  }
  protected get _customerInfoDunBradstreetNumber(): number | undefined {
    const value = coerceNumberProperty(this._customerInfoDunBradstreetNumberFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _customerInfoWebsite(): string | undefined {
    return this._customerInfoWebsiteFormControl.value;
  }
  protected get _customerInfoTelephoneNumber(): string {
    return this._customerInfoTelephoneNumberFormControl.value;
  }
  protected get _customerInfoFaxNumber(): string | undefined {
    return this._customerInfoFaxNumberFormControl.value;
  }
  protected get _billingAddressLine1(): string {
    return this._billingAddressLine1FormControl.value;
  }
  protected get _billingAddressLine2(): string {
    return this._billingAddressLine2FormControl.value;
  }
  protected get _billingAddressLine3(): string {
    return this._billingAddressLine3FormControl.value;
  }
  protected get _billingAddressCity(): string {
    return this._billingAddressCityFormControl.value;
  }
  protected get _billingAddressState(): string {
    return this._billingAddressStateFormControl.value;
  }
  protected get _billingAddressPostalCode(): string {
    return this._billingAddressPostalCodeFormControl.value;
  }
  protected get _billingAddressCountry(): string {
    return this._billingAddressCountryFormControl.value;
  }
  protected get _billingAddressType(): EComAddressTypes {
    return this._billingAddressTypeFormControl.value;
  }
  protected get _shippingAddressLine1(): string {
    return this._shippingAddressLine1FormControl.value;
  }
  protected get _shippingAddressLine2(): string {
    return this._shippingAddressLine2FormControl.value;
  }
  protected get _shippingAddressLine3(): string {
    return this._shippingAddressLine3FormControl.value;
  }
  protected get _shippingAddressCity(): string {
    return this._shippingAddressCityFormControl.value;
  }
  protected get _shippingAddressState(): string {
    return this._shippingAddressStateFormControl.value;
  }
  protected get _shippingAddressPostalCode(): string {
    return this._shippingAddressPostalCodeFormControl.value;
  }
  protected get _shippingAddressCountry(): string {
    return this._shippingAddressCountryFormControl.value;
  }
  protected get _shippingAddressType(): EComAddressTypes {
    return this._shippingAddressTypeFormControl.value;
  }
  protected get _shippingInfoShippingMethod(): string | undefined {
    return this._shippingInfoShippingMethodFormControl.value;
  }
  protected get _shippingInfoShippingCarrierAcct(): string | undefined {
    return this._shippingInfoShippingCarrierAcctFormControl.value;
  }
  protected get _shippingInfoDeliveryInstructions(): string | undefined {
    return this._shippingInfoDeliveryInstructionsFormControl.value;
  }
  protected get _shippingInfoShelfLife(): number {
    return coerceNumberProperty(this._shippingInfoShelfLifeFormControl.value);
  }
  protected get _shippingAddressFreightForwarderName(): string {
    return this._shippingAddressFreightForwarderNameFormControl.value;
  }
  protected get _accountsPayableContactName(): string {
    return this._accountsPayableContactNameFormControl.value;
  }
  protected get _accountsPayableContactEmailAddress(): string {
    return this._accountsPayableContactEmailAddressFormControl.value;
  }
  protected get _accountsPayableContactPhoneNumber(): string | undefined {
    return this._accountsPayableContactPhoneNumberFormControl.value;
  }
  protected get _accountsPayableContactFaxNumber(): string | undefined {
    return this._accountsPayableContactFaxNumberFormControl.value;
  }
  protected get _accountsReceiveInvoicesEmail(): string | undefined {
    return this._accountsReceiveInvoicesEmailFormControl.value;
  }
  protected get _accountsPaymentTermsRequested(): EComPaymentTermsTypes {
    return this._accountsPaymentTermsRequestedFormControl.value;
  }
  protected get _financialTotalAssets(): number | undefined {
    const value = coerceNumberProperty(this._financialTotalAssetsFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialTotalLiabilities(): number | undefined {
    const value = coerceNumberProperty(this._financialTotalLiabilitiesFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialTotalEquity(): number | undefined {
    const value = coerceNumberProperty(this._financialTotalEquityFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialTotalDebt(): number | undefined {
    const value = coerceNumberProperty(this._financialTotalDebtFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialCurrentAssets(): number | undefined {
    const value = coerceNumberProperty(this._financialCurrentAssetsFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialCurrentLiabilities(): number | undefined {
    const value = coerceNumberProperty(this._financialCurrentLiabilitiesFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialTotalInventory(): number | undefined {
    const value = coerceNumberProperty(this._financialTotalInventoryFormControl.value);
    return !mfTypeIsNullOrUndefined(value) && !isNaN(value) ? value : undefined;
  }
  protected get _financialQuestions(): string | undefined {
    return this._financialQuestionsFormControl.value;
  }
  protected get _financialTaxID(): string {
    return this._financialTaxIDFormControl.value;
  }
  protected get _financialCertificateRequirement(): boolean {
    return coerceBooleanProperty(this._financialCertificateRequirementFormControl.value);
  }
  protected get _termsAndConditionsAccepted(): boolean {
    return coerceBooleanProperty(this._termsAndConditionsAcceptedFormControl.value);
  }
  protected get _termsAndConditionsLegalNameCompany(): string {
    return this._termsAndConditionsLegalNameCompanyFormControl.value;
  }
  protected get _termsAndConditionsAuthorizedParty(): string {
    return this._termsAndConditionsAuthorizedPartyFormControl.value;
  }

  protected _customerInfoBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoCompanyNameName, this._customerInfoCompanyNameFormControl);
    this._addValidation(this._customerInfoCompanyNameName, this._customerInfoCompanyNameFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation(this._customerInfoCompanyNameName, this._customerInfoCompanyNameFormControl, Validators.maxLength(40), { priority: 1, validatorName: "maxLength", message: ERROR_MESSAGE_MAX_LENGTH, messageParams: [40] });
    this._addFormControlErrorMessageHandling(this._customerInfoCompanyNameName, this._customerInfoCompanyNameFormControl);

    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoDoingBusinessAsName, this._customerInfoDoingBusinessAsFormControl);
    this._addValidation(this._customerInfoDoingBusinessAsName, this._customerInfoDoingBusinessAsFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._customerInfoDoingBusinessAsName, this._customerInfoDoingBusinessAsFormControl);

    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoDunBradstreetNumberName, this._customerInfoDunBradstreetNumberFormControl);

    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoWebsiteName, this._customerInfoWebsiteFormControl);

    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoTelephoneNumberName, this._customerInfoTelephoneNumberFormControl);
    this._addValidation(this._customerInfoTelephoneNumberName, this._customerInfoTelephoneNumberFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation(this._customerInfoTelephoneNumberName, this._customerInfoTelephoneNumberFormControl, MfValidators.phoneNumberInput, { priority: 1, validatorName: "phoneNumberInput", message: ERROR_MESSAGE_PHONE_NUMBER_LOOSE });
    this._addFormControlErrorMessageHandling(this._customerInfoTelephoneNumberName, this._customerInfoTelephoneNumberFormControl);

    this._addFormControlToFormGroup(this._customerInfoFormGroup, this._customerInfoFaxNumberName, this._customerInfoFaxNumberFormControl);
    this._addValidation(this._customerInfoFaxNumberName, this._customerInfoFaxNumberFormControl, MfValidators.phoneNumberInput, { priority: 1, validatorName: "phoneNumberInput", message: ERROR_MESSAGE_PHONE_NUMBER_LOOSE });
    this._addFormControlErrorMessageHandling(this._customerInfoFaxNumberName, this._customerInfoFaxNumberFormControl);

  }

  protected _customerInfoAddEvents(): void {

  }

  protected _customerInfoSetInitialState(): void {
    this._customerInfoDunBradstreetNumberFormControl.setValue(null, { emitEvent: false });
  }

  protected _billingAddressBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressLine1Name, this._billingAddressLine1FormControl);
    this._addValidation(this._billingAddressLine1Name, this._billingAddressLine1FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressLine1Name, this._billingAddressLine1FormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressLine2Name, this._billingAddressLine2FormControl);
    this._addValidation(this._billingAddressLine2Name, this._billingAddressLine2FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressLine2Name, this._billingAddressLine2FormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressLine3Name, this._billingAddressLine3FormControl);
    this._addValidation(this._billingAddressLine3Name, this._billingAddressLine3FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressLine3Name, this._billingAddressLine3FormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressCityName, this._billingAddressCityFormControl);
    this._addValidation(this._billingAddressCityName, this._billingAddressCityFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressCityName, this._billingAddressCityFormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressStateName, this._billingAddressStateFormControl);
    this._addValidation(this._billingAddressStateName, this._billingAddressStateFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressStateName, this._billingAddressStateFormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressPostalCodeName, this._billingAddressPostalCodeFormControl);
    this._addValidation(this._billingAddressPostalCodeName, this._billingAddressPostalCodeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressPostalCodeName, this._billingAddressPostalCodeFormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressCountryName, this._billingAddressCountryFormControl);
    this._addValidation(this._billingAddressCountryName, this._billingAddressCountryFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressCountryName, this._billingAddressCountryFormControl);

    this._addFormControlToFormGroup(this._billingAddressFormGroup, this._billingAddressTypeName, this._billingAddressTypeFormControl);
    this._addValidation(this._billingAddressTypeName, this._billingAddressTypeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._billingAddressTypeName, this._billingAddressTypeFormControl);
  }

  protected _billingAddressAddEvents(): void {
    this._sub(merge(
      this._billingAddressLine1FormControl.valueChanges,
      this._billingAddressLine2FormControl.valueChanges,
      this._billingAddressLine3FormControl.valueChanges,
      this._billingAddressCityFormControl.valueChanges,
      this._billingAddressStateFormControl.valueChanges,
      this._billingAddressPostalCodeFormControl.valueChanges,
      this._billingAddressCountryFormControl.valueChanges,
    ),
      {
        next: () => this._onBillingAddressValueChanges()
      }
    );
  }

  protected _billingAddressSetInitialState(): void {

  }

  protected _shippingAddressBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl);
    this._addValidation(this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressLine1Name, this._shippingAddressLine1FormControl);
    this._addValidation(this._shippingAddressLine1Name, this._shippingAddressLine1FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressLine1Name, this._shippingAddressLine1FormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressLine2Name, this._shippingAddressLine2FormControl);
    this._addValidation(this._shippingAddressLine2Name, this._shippingAddressLine2FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressLine2Name, this._shippingAddressLine2FormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressLine3Name, this._shippingAddressLine3FormControl);
    this._addValidation(this._shippingAddressLine3Name, this._shippingAddressLine3FormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressLine3Name, this._shippingAddressLine3FormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressCityName, this._shippingAddressCityFormControl);
    this._addValidation(this._shippingAddressCityName, this._shippingAddressCityFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressCityName, this._shippingAddressCityFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressStateName, this._shippingAddressStateFormControl);
    this._addValidation(this._shippingAddressStateName, this._shippingAddressStateFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressStateName, this._shippingAddressStateFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressPostalCodeName, this._shippingAddressPostalCodeFormControl);
    this._addValidation(this._shippingAddressPostalCodeName, this._shippingAddressPostalCodeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressPostalCodeName, this._shippingAddressPostalCodeFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressCountryName, this._shippingAddressCountryFormControl);
    this._addValidation(this._shippingAddressCountryName, this._shippingAddressCountryFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressCountryName, this._shippingAddressCountryFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressTypeName, this._shippingAddressTypeFormControl);
    this._addValidation(this._shippingAddressTypeName, this._shippingAddressTypeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressTypeName, this._shippingAddressTypeFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressFreightForwarderNameName, this._shippingAddressFreightForwarderNameFormControl);

    this._addFormControlToFormGroup(this._shippingAddressFormGroup, this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl);
    this._addValidation(this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingAddressSameBillingName, this._shippingAddressSameBillingFormControl);
  }

  protected _shippingAddressAddEvents(): void {
    this._sub(this._shippingAddressSameBillingFormControl.valueChanges, { next: () => this._onShippingAddressSameBillingFormControlValueChanges() });
    this._sub(this._shippingAddressTypeFormControl.valueChanges, { next: () => this._onShippingAddressTypeFormControlValueChanges() });
  }

  protected _shippingAddressSetInitialState(): void {

  }

  protected _shippingInfoBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoFreightHandlingCodeName, this._shippingInfoFreightHandlingCodeFormControl);
    this._addValidation(this._shippingInfoFreightHandlingCodeName, this._shippingInfoFreightHandlingCodeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingInfoFreightHandlingCodeName, this._shippingInfoFreightHandlingCodeFormControl);

    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoShippingMethodName, this._shippingInfoShippingMethodFormControl);
    this._addValidation(this._shippingInfoShippingMethodName, this._shippingInfoShippingMethodFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingInfoShippingMethodName, this._shippingInfoShippingMethodFormControl);

    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoShippingMethodOtherName, this._shippingInfoShippingMethodOtherFormControl);
    this._addValidation(this._shippingInfoShippingMethodOtherName, this._shippingInfoShippingMethodOtherFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._shippingInfoShippingMethodOtherName, this._shippingInfoShippingMethodOtherFormControl);

    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoShippingCarrierAcctName, this._shippingInfoShippingCarrierAcctFormControl);

    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoDeliveryInstructionsName, this._shippingInfoDeliveryInstructionsFormControl);

    this._addFormControlToFormGroup(this._shippingInfoFormGroup, this._shippingInfoShelfLifeName, this._shippingInfoShelfLifeFormControl);
    this._addValidation(this._shippingInfoShelfLifeName, this._shippingInfoShelfLifeFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
  }

  protected _shippingInfoAddEvents(): void {
    this._sub(this._shippingInfoFreightHandlingCodeFormControl.valueChanges, { next: () => this._onShippingInfoFreightHandlingCodeFormControlValueChanges() });
    this._sub(this._shippingInfoShippingMethodFormControl.valueChanges, { next: () => this._onShippingInfoShippingMethodFormControlValueChanges() });
  }

  protected _shippingInfoSetInitialState(): void {
    this._shippingInfoShippingMethodFormControl.setValue(EComShippingMethodsTypes.fedex);
    this._shippingInfoShelfLifeFormControl.setValue(0);
  }

  protected _accountsBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsPayableContactNameName, this._accountsPayableContactNameFormControl);
    this._addValidation(this._accountsPayableContactNameName, this._accountsPayableContactNameFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._accountsPayableContactNameName, this._accountsPayableContactNameFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsPayableContactEmailAddressName, this._accountsPayableContactEmailAddressFormControl);
    this._addValidation(this._accountsPayableContactEmailAddressName, this._accountsPayableContactEmailAddressFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addValidation(this._accountsPayableContactEmailAddressName, this._accountsPayableContactEmailAddressFormControl, Validators.email, { priority: 1, validatorName: "email", message: ERROR_MESSAGE_EMAIL });
    this._addFormControlErrorMessageHandling(this._accountsPayableContactEmailAddressName, this._accountsPayableContactEmailAddressFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsPayableContactPhoneNumberName, this._accountsPayableContactPhoneNumberFormControl);
    this._addValidation(this._accountsPayableContactPhoneNumberName, this._accountsPayableContactPhoneNumberFormControl, MfValidators.phoneNumberInput, { priority: 1, validatorName: "phoneNumberInput", message: ERROR_MESSAGE_PHONE_NUMBER_LOOSE });
    this._addFormControlErrorMessageHandling(this._accountsPayableContactPhoneNumberName, this._accountsPayableContactPhoneNumberFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsPayableContactFaxNumberName, this._accountsPayableContactFaxNumberFormControl);
    this._addValidation(this._accountsPayableContactFaxNumberName, this._accountsPayableContactFaxNumberFormControl, MfValidators.phoneNumberInput, { priority: 1, validatorName: "phoneNumberInput", message: ERROR_MESSAGE_PHONE_NUMBER_LOOSE });
    this._addFormControlErrorMessageHandling(this._accountsPayableContactFaxNumberName, this._accountsPayableContactFaxNumberFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsReceiveInvoicesEmailName, this._accountsReceiveInvoicesEmailFormControl);
    this._addValidation(this._accountsReceiveInvoicesEmailName, this._accountsReceiveInvoicesEmailFormControl, Validators.email, { priority: 1, validatorName: "email", message: ERROR_MESSAGE_EMAIL });
    this._addFormControlErrorMessageHandling(this._accountsReceiveInvoicesEmailName, this._accountsReceiveInvoicesEmailFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsPaymentTermsRequestedName, this._accountsPaymentTermsRequestedFormControl);
    this._addValidation(this._accountsPaymentTermsRequestedName, this._accountsPaymentTermsRequestedFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._accountsPaymentTermsRequestedName, this._accountsPaymentTermsRequestedFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsCreditLimitName, this._accountsCreditLimitFormControl);
    this._addValidation(this._accountsCreditLimitName, this._accountsCreditLimitFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._accountsCreditLimitName, this._accountsCreditLimitFormControl);

    this._addFormControlToFormGroup(this._accountsFormGroup, this._accountsCreditLimitOtherName, this._accountsCreditLimitOtherFormControl);
    this._addValidation(this._accountsCreditLimitOtherName, this._accountsCreditLimitOtherFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._accountsCreditLimitOtherName, this._accountsCreditLimitOtherFormControl);
  }

  protected _accountsAddEvents(): void {
    this._sub(this._accountsPaymentTermsRequestedFormControl.valueChanges, { next: () => this._onAccountsPaymentTermsRequestedFormControlValueChanges() });
    this._sub(this._accountsCreditLimitFormControl.valueChanges, { next: () => this._onAccountsCreditLimitFormControlValueChanges() });
    this._sub(this._accountsCreditLimitOtherFormControl.valueChanges, { next: () => this._onAccountsCreditLimitFormControlValueChanges() });
  }

  protected _accountsSetInitialState(): void {
    this._financialTotalAssetsFormControl.disable({ emitEvent: true });
    this._financialTotalLiabilitiesFormControl.disable({ emitEvent: true });
    this._financialTotalEquityFormControl.disable({ emitEvent: true });
    this._financialTotalDebtFormControl.disable({ emitEvent: true });
    this._financialCurrentAssetsFormControl.disable({ emitEvent: true });
    this._financialCurrentLiabilitiesFormControl.disable({ emitEvent: true });
    this._financialTotalInventoryFormControl.disable({ emitEvent: true });
    this._financialQuestionsFormControl.disable({ emitEvent: true });

    this._showFinancialTotalAssetsFormControl = false;
    this._showFinancialTotalLiabilitiesFormControl = false;
    this._showFinancialTotalEquityFormControl = false;
    this._showFinancialTotalDebtFormControl = false;
    this._showFinancialCurrentAssetsFormControl = false;
    this._showFinancialCurrentLiabilitiesFormControl = false;
    this._showFinancialTotalInventoryFormControl = false;
    this._showFinancialQuestionsFormControl = false;
    this._showFinancialFileUpload = false;
  }

  protected _financialBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTotalAssetsName, this._financialTotalAssetsFormControl);
    this._addValidation(this._financialTotalAssetsName, this._financialTotalAssetsFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTotalAssetsName, this._financialTotalAssetsFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTotalLiabilitiesName, this._financialTotalLiabilitiesFormControl);
    this._addValidation(this._financialTotalLiabilitiesName, this._financialTotalLiabilitiesFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTotalLiabilitiesName, this._financialTotalLiabilitiesFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTotalEquityName, this._financialTotalEquityFormControl);
    this._addValidation(this._financialTotalEquityName, this._financialTotalEquityFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTotalEquityName, this._financialTotalEquityFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTotalDebtName, this._financialTotalDebtFormControl);
    this._addValidation(this._financialTotalDebtName, this._financialTotalDebtFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTotalDebtName, this._financialTotalDebtFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialCurrentAssetsName, this._financialCurrentAssetsFormControl);
    this._addValidation(this._financialCurrentAssetsName, this._financialCurrentAssetsFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialCurrentAssetsName, this._financialCurrentAssetsFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialCurrentLiabilitiesName, this._financialCurrentLiabilitiesFormControl);
    this._addValidation(this._financialCurrentLiabilitiesName, this._financialCurrentLiabilitiesFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialCurrentLiabilitiesName, this._financialCurrentLiabilitiesFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTotalInventoryName, this._financialTotalInventoryFormControl);
    this._addValidation(this._financialTotalInventoryName, this._financialTotalInventoryFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTotalInventoryName, this._financialTotalInventoryFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialQuestionsName, this._financialQuestionsFormControl);
    this._addValidation(this._financialQuestionsName, this._financialQuestionsFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialQuestionsName, this._financialQuestionsFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialTaxIDName, this._financialTaxIDFormControl);
    this._addValidation(this._financialTaxIDName, this._financialTaxIDFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialTaxIDName, this._financialTaxIDFormControl);

    this._addFormControlToFormGroup(this._financialFormGroup, this._financialCertificateRequirementName, this._financialCertificateRequirementFormControl);
    this._addValidation(this._financialCertificateRequirementName, this._financialCertificateRequirementFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._financialCertificateRequirementName, this._financialCertificateRequirementFormControl);
  }

  protected _financialAddEvents(): void {
    this._sub(this._financialCertificateRequirementFormControl.valueChanges, { next: () => this._onFinancialCertificateRequirementFormControlValueChanges() });
  }

  protected _financialSetInitialState(): void {

  }

  protected _termsAndConditionsBuildFormGroup(): void {
    this._addFormControlToFormGroup(this._termsAndConditionsFormGroup, this._termsAndConditionsAcceptedName, this._termsAndConditionsAcceptedFormControl);
    this._addValidation(this._termsAndConditionsAcceptedName, this._termsAndConditionsAcceptedFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._termsAndConditionsAcceptedName, this._termsAndConditionsAcceptedFormControl);

    this._addFormControlToFormGroup(this._termsAndConditionsFormGroup, this._termsAndConditionsLegalNameCompanyName, this._termsAndConditionsLegalNameCompanyFormControl);
    this._addValidation(this._termsAndConditionsLegalNameCompanyName, this._termsAndConditionsLegalNameCompanyFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._termsAndConditionsLegalNameCompanyName, this._termsAndConditionsLegalNameCompanyFormControl);

    this._addFormControlToFormGroup(this._termsAndConditionsFormGroup, this._termsAndConditionsAuthorizedPartyName, this._termsAndConditionsAuthorizedPartyFormControl);
    this._addValidation(this._termsAndConditionsAuthorizedPartyName, this._termsAndConditionsAuthorizedPartyFormControl, Validators.required, { priority: 1, validatorName: "required", message: ERROR_MESSAGE_REQUIRED });
    this._addFormControlErrorMessageHandling(this._termsAndConditionsAuthorizedPartyName, this._termsAndConditionsAuthorizedPartyFormControl);
  }

  protected _termsAndConditionsAddEvents(): void {

  }

  protected _termsAndConditionsSetInitialState(): void {

  }

  protected _formGroupsAddEvents(): void {
    this._sub(merge(this._customerInfoFormGroup.valueChanges,
      this._billingAddressFormGroup.valueChanges ||
      this._shippingAddressFormGroup.valueChanges ||
      this._shippingInfoFormGroup.valueChanges ||
      this._accountsFormGroup.valueChanges ||
      this._financialFormGroup.valueChanges ||
      this._termsAndConditionsFormGroup.valueChanges), {
      next: () => this._newCustomerSetupService.save(this._getNewCustomerModel())
    });
  }

  protected _onShippingInfoShippingMethodFormControlValueChanges(): void {
    switch (this._shippingInfoShippingMethodType) {
      case EComShippingMethodsTypes.fedex:
      case EComShippingMethodsTypes.dhl:
      case EComShippingMethodsTypes.ups:
        this._shippingInfoShippingMethodOtherFormControl.disable({ emitEvent: true });
        this._shippingInfoShippingMethodOtherFormControl.setValue("", { emitEvent: false });
        break;
      case EComShippingMethodsTypes.other:
        this._shippingInfoShippingMethodOtherFormControl.enable({ emitEvent: true });
        break;
    }
  }

  protected _onShippingInfoFreightHandlingCodeFormControlValueChanges(): void {
    switch (this._shippingInfoFreightHandlingType) {
      case EComFreightHandlingTypes.incora:
        this._shippingInfoShippingMethodFormControl.disable({ emitEvent: false });
        this._shippingInfoShippingCarrierAcctFormControl.disable({ emitEvent: false });
        this._showShippingInfoShippingMethodFormControl = false;
        this._showShippingInfoShippingCarrierAcctFormControl = false;
        break;
      case EComFreightHandlingTypes.collect:
        this._shippingInfoShippingMethodFormControl.enable({ emitEvent: false });
        this._shippingInfoShippingCarrierAcctFormControl.enable({ emitEvent: false });
        this._showShippingInfoShippingMethodFormControl = true;
        this._showShippingInfoShippingCarrierAcctFormControl = true;
        break;
    }
  }

  protected _onBillingAddressValueChanges(): void {
    this._setShippingAddressFromBillingAddress();
  }

  protected _onShippingAddressTypeFormControlValueChanges(): void {
    switch (this._shippingAddressType) {
      case EComAddressTypes.commercial:
      case EComAddressTypes.residential:
        this._showShippingAddressFreightForwarderNameFormControl = false;
        this._shippingAddressFreightForwarderNameFormControl.disable({ emitEvent: false });
        break;
      case EComAddressTypes.freightForwarder:
        this._showShippingAddressFreightForwarderNameFormControl = true;
        this._shippingAddressFreightForwarderNameFormControl.enable({ emitEvent: false });
        break;
    }
  }

  protected _onShippingAddressSameBillingFormControlValueChanges(): void {
    if (this._isShippingAddressSameBilling === true) {
      this._setShippingAddressFromBillingAddress();

      this._shippingAddressLine1FormControl.disable({ emitEvent: false });
      this._shippingAddressLine2FormControl.disable({ emitEvent: false });
      this._shippingAddressLine3FormControl.disable({ emitEvent: false });
      this._shippingAddressCityFormControl.disable({ emitEvent: false });
      this._shippingAddressStateFormControl.disable({ emitEvent: false });
      this._shippingAddressPostalCodeFormControl.disable({ emitEvent: false });
      this._shippingAddressCountryFormControl.disable({ emitEvent: false });
    } else {
      this._shippingAddressLine1FormControl.enable({ emitEvent: false });
      this._shippingAddressLine2FormControl.enable({ emitEvent: false });
      this._shippingAddressLine3FormControl.enable({ emitEvent: false });
      this._shippingAddressCityFormControl.enable({ emitEvent: false });
      this._shippingAddressStateFormControl.enable({ emitEvent: false });
      this._shippingAddressPostalCodeFormControl.enable({ emitEvent: false });
      this._shippingAddressCountryFormControl.enable({ emitEvent: false });
    }
  }

  protected _onAccountsPaymentTermsRequestedFormControlValueChanges(): void {
    switch (this._accountsPaymentTermsRequestedFType) {
      case EComPaymentTermsTypes.cashInAdvance:
      case EComPaymentTermsTypes.creditCard:
        this._accountsCreditLimitFormControl.disable({ emitEvent: false });
        this._accountsCreditLimitOtherFormControl.disable({ emitEvent: false });
        this._accountsCreditLimitFormControl.setValue("", { emitEvent: false });
        this._accountsCreditLimitOtherFormControl.setValue("", { emitEvent: false });
        this._showAccountsCreditLimitFormControl = false;
        break;
      case EComPaymentTermsTypes.credit:
        this._accountsCreditLimitFormControl.enable({ emitEvent: false });
        this._accountsCreditLimitOtherFormControl.disable({ emitEvent: false });
        this._showAccountsCreditLimitFormControl = true;
        break;
    }
  }

  protected _onAccountsCreditLimitFormControlValueChanges(): void {
    if (coerceNumberProperty(this._accountsCreditLimitFormControl.value) === 0) {
      this._showAccountsCreditLimitOtherFormControl = true;
      this._accountsCreditLimitOtherFormControl.enable({ emitEvent: false });
    } else {
      this._showAccountsCreditLimitOtherFormControl = false;
      this._accountsCreditLimitOtherFormControl.disable({ emitEvent: false });
      this._accountsCreditLimitOtherFormControl.setValue("", { emitEvent: false });
    }

    if (!mfTypeIsUndefined(this._accountsCreditLimit) && this._accountsCreditLimit <= 50000) {
      this._financialTotalAssetsFormControl.disable({ emitEvent: true });
      this._financialTotalLiabilitiesFormControl.disable({ emitEvent: true });
      this._financialTotalEquityFormControl.disable({ emitEvent: true });
      this._financialTotalDebtFormControl.disable({ emitEvent: true });
      this._financialCurrentAssetsFormControl.disable({ emitEvent: true });
      this._financialCurrentLiabilitiesFormControl.disable({ emitEvent: true });
      this._financialTotalInventoryFormControl.disable({ emitEvent: true });
      this._financialQuestionsFormControl.disable({ emitEvent: true });

      this._showFinancialTotalAssetsFormControl = false;
      this._showFinancialTotalLiabilitiesFormControl = false;
      this._showFinancialTotalEquityFormControl = false;
      this._showFinancialTotalDebtFormControl = false;
      this._showFinancialCurrentAssetsFormControl = false;
      this._showFinancialCurrentLiabilitiesFormControl = false;
      this._showFinancialTotalInventoryFormControl = false;
      this._showFinancialQuestionsFormControl = false;
    } else {
      this._financialTotalAssetsFormControl.enable({ emitEvent: true });
      this._financialTotalLiabilitiesFormControl.enable({ emitEvent: true });
      this._financialTotalEquityFormControl.enable({ emitEvent: true });
      this._financialTotalDebtFormControl.enable({ emitEvent: true });
      this._financialCurrentAssetsFormControl.enable({ emitEvent: true });
      this._financialCurrentLiabilitiesFormControl.enable({ emitEvent: true });
      this._financialTotalInventoryFormControl.enable({ emitEvent: true });
      this._financialQuestionsFormControl.enable({ emitEvent: true });

      this._showFinancialTotalAssetsFormControl = true;
      this._showFinancialTotalLiabilitiesFormControl = true;
      this._showFinancialTotalEquityFormControl = true;
      this._showFinancialTotalDebtFormControl = true;
      this._showFinancialCurrentAssetsFormControl = true;
      this._showFinancialCurrentLiabilitiesFormControl = true;
      this._showFinancialTotalInventoryFormControl = true;
      this._showFinancialQuestionsFormControl = true;
    }


    this._financialTotalAssetsFormControl.setValue("", { emitEvent: true });
    this._financialTotalLiabilitiesFormControl.setValue("", { emitEvent: true });
    this._financialTotalEquityFormControl.setValue("", { emitEvent: true });
    this._financialTotalDebtFormControl.setValue("", { emitEvent: true });
    this._financialCurrentAssetsFormControl.setValue("", { emitEvent: true });
    this._financialCurrentLiabilitiesFormControl.setValue("", { emitEvent: true });
    this._financialTotalInventoryFormControl.setValue("", { emitEvent: true });
    this._financialQuestionsFormControl.setValue("", { emitEvent: true });
  }

  protected _onFinancialCertificateRequirementFormControlValueChanges(): void {
    if (this._financialCertificateRequirementFormControl.value === true) {
      this._showFinancialFileUpload = true;
    } else {
      this._showFinancialFileUpload = false;
    }
  }

  protected _setShippingAddressFromBillingAddress(): void {
    if (this._isShippingAddressSameBilling === true) {
      this._shippingAddressLine1FormControl.setValue(this._billingAddressLine1FormControl.value, { emitEvent: false });
      this._shippingAddressLine2FormControl.setValue(this._billingAddressLine2FormControl.value, { emitEvent: false });
      this._shippingAddressLine3FormControl.setValue(this._billingAddressLine3FormControl.value, { emitEvent: false });
      this._shippingAddressCityFormControl.setValue(this._billingAddressCityFormControl.value, { emitEvent: false });
      this._shippingAddressStateFormControl.setValue(this._billingAddressStateFormControl.value, { emitEvent: false });
      this._shippingAddressPostalCodeFormControl.setValue(this._billingAddressPostalCodeFormControl.value, { emitEvent: false });
      this._shippingAddressCountryFormControl.setValue(this._billingAddressCountryFormControl.value, { emitEvent: false });
    }
  }

  protected _onExpansionPanelOpened(index: number, container: MatExpansionPanel): void {
    this._expansionIndex = index;
    this._step.set(index);
  }

  protected _onExpansionPanelClosed(index: number, panel: MatExpansionPanel): void {
    if (this._expansionIndex === index) {
      panel.open();
    }
  }

  protected _nextStep(): void {
    this._expansionIndex = this._expansionIndex + 1;
    this._step.update(() => this._expansionIndex);
  }

  protected _prevStep(): void {
    this._expansionIndex = this._expansionIndex - 1;
    this._step.update(() => this._expansionIndex);
  }

  protected _onSubmitClicked(): void {
    const loading = this._subLoading(this._newCustomerSetupService.submit(this._getNewCustomerModel()), {
      next: (applicationId) => {
        loading.complete();
        this._router.navigate([EComRouteNames.routeCustomerNewConfirmation, applicationId]);
      }
    }, "Submitting New Customer Application");
  }

  protected _getNewCustomerModel(): EComNewCustomerModel {
    const billingAddress: EComAddressModel = {
      address1: this._billingAddressLine1,
      address2: this._billingAddressLine2,
      address3: this._billingAddressLine3,
      city: this._billingAddressCity,
      region: this._billingAddressState,
      postalCode: this._billingAddressPostalCode,
      country: this._billingAddressCountry,
      type: this._billingAddressType,
    };

    const shippingAddress: EComAddressModel = {
      address1: this._shippingAddressLine1,
      address2: this._shippingAddressLine2,
      address3: this._shippingAddressLine3,
      city: this._shippingAddressCity,
      region: this._shippingAddressState,
      postalCode: this._shippingAddressPostalCode,
      country: this._shippingAddressCountry,
      type: this._shippingAddressType,
    };

    const model: EComNewCustomerModel = {
      name: this._customerInfoCompanyName,
      doingBusinessAs: this._customerInfoDoingBusinessAs,
      dunBradstreetNumber: this._customerInfoDunBradstreetNumber,
      website: this._customerInfoWebsite,
      telephoneNumber: this._customerInfoTelephoneNumber,
      faxNumber: this._customerInfoFaxNumber,
      billingAddress,
      shippingAddress,
      shippingFreightForwarderName: this._shippingAddressFreightForwarderName,
      shippingFreightHandlingCode: this._shippingInfoFreightHandlingType,
      shippingMethod: this._shippingInfoShippingMethod,
      shippingCarrierAcct: this._shippingInfoShippingCarrierAcct,
      deliveryInstructions: this._shippingInfoDeliveryInstructions,
      shelfLife: this._shippingInfoShelfLife,
      payableContactName: this._accountsPayableContactName,
      payableContactEmail: this._accountsPayableContactEmailAddress,
      payableContactPhoneNumber: this._accountsPayableContactPhoneNumber,
      payableContactFaxNumber: this._accountsPayableContactFaxNumber,
      receiveInvoicesEmail: this._accountsReceiveInvoicesEmail,
      paymentTermsRequested: this._accountsPaymentTermsRequested,
      creditLimit: this._accountsCreditLimit,
      totalAssets: this._financialTotalAssets,
      totalLiabilities: this._financialTotalLiabilities,
      totalEquity: this._financialTotalEquity,
      totalDebt: this._financialTotalDebt,
      currentAssets: this._financialCurrentAssets,
      currentLiabilities: this._financialCurrentLiabilities,
      totalInventory: this._financialTotalInventory,
      financialQuestions: this._financialQuestions,
      taxId: this._financialTaxID,
      certificateRequirement: this._financialCertificateRequirement,
      accepted: this._termsAndConditionsAccepted,
      legalName: this._termsAndConditionsLegalNameCompany,
      authorizedParty: this._termsAndConditionsAuthorizedParty,
      date: new Date(),
    };


    return model;
  }
}