import { mfDateToEarliest } from "@pattonair/material-framework";
import * as dayjs from "dayjs";

export function ecomCartGetDefaultRequiredDateMidday(): Date {
  return dayjs().add(5, "days").toDate();
}

export function ecomCartGetDefaultRequiredDateEarliest(): Date {
  return mfDateToEarliest(ecomCartGetDefaultRequiredDateMidday());
}

export function ecomCartGetDefaultQty(): number {
  return 1;
}