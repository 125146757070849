import { MfModelBase, MfModelConfig, MfModelFieldConfig, MfModelFieldDataTypes } from "@pattonair/material-framework";


export type EComInvoiceCartValidationItemModel = MfModelBase & {
  documentKey: string,
  errorMessage?: string;
}

export type EComInvoiceCartValidationItemModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartValidationItemModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_FIELDS_CONFIG: EComInvoiceCartValidationItemModelFieldsConfig = {
  documentKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  errorMessage: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_CONFIG_ID = "invoiceCartValidationItem";

export const ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_VALIDATION_ITEM_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceCompleteModel = {
  key: string;
  cartKey: string;
};

export type EComInvoiceCompleteModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCompleteModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_COMPLETE_MODEL_FIELDS_CONFIG: EComInvoiceCompleteModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_INVOICE_COMPLETE_MODEL_CONFIG_ID = "invoiceComplete";

export const ECOM_INVOICE_COMPLETE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_COMPLETE_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_COMPLETE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_COMPLETE_MODEL_CONFIG",
  },
  portals: {}
};

export type EComInvoiceCartValidationModel = MfModelBase & {
  cartKey?: string;
  items?: EComInvoiceCartValidationItemModel[],
}

export type EComOrderCartValidationModelFieldsConfig = {
  [key in keyof Required<EComInvoiceCartValidationModel>]: MfModelFieldConfig
};

export const ECOM_INVOICE_CART_VALIDATION_MODEL_FIELDS_CONFIG: EComOrderCartValidationModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG_ID = "invoiceCartValidation";

export const ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG_ID,
  fields: ECOM_INVOICE_CART_VALIDATION_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_INVOICE_CART_VALIDATION_MODEL_CONFIG",
  },
  portals: {}
};