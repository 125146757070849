import { AfterViewInit, Component, Injector, OnInit, ViewEncapsulation } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { isStepComponent, isValidatingComponent } from "@e-commerce/checkout/base/checkout";
import { EComCheckoutBaseComponent } from "@e-commerce/checkout/base/checkout.base.component";
import { EComInvoiceBillingPaymentComponent } from "@e-commerce/checkout/invoice/billingPayment/invoice.billing.payment.component";
import { EComInvoiceOrderReviewComponent } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.component";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { EComCompleteInvoiceModel } from "@e-commerce/modelConfigs/complete.invoice.model.config";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { MfSessionStorageService, MfTypeInfo, mfTypeIsUndefined } from "@pattonair/material-framework";
import { EComInvoiceCheckoutData } from "./checkout.invoice";
import { concatMap, map } from "rxjs";
import { EComCustomerService } from "../../services/customer.service";
import { EComCartInvoiceService } from "../../services/cart.invoice.service";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { EComCartPaymentTypes } from "../../common/cart.modes";
import { EComCheckoutInvoiceService } from "../../services/checkout.invoice.service";

const TYPE_INFO: MfTypeInfo = { className: "EComCheckoutInvoiceComponent" };

export const CHECKOUT_INVOICE_DATA_STORAGE_KEY = "invoiceCheckoutData" as const;

@Component({
  selector: "ecom-checkout-invoice",
  templateUrl: "./checkout.invoice.component.html",
  styleUrls: ["./checkout.invoice.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EComCheckoutInvoiceComponent extends EComCheckoutBaseComponent implements AfterViewInit, OnInit {
  protected _checkoutData?: EComInvoiceCheckoutData;
  protected _lockNavigation: boolean = false;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _activatedRoute: ActivatedRoute,
    protected _cartService: EComCartInvoiceService,
    protected _storageService: MfSessionStorageService,
    protected _customerService: EComCustomerService,
    protected _checkoutService: EComCheckoutInvoiceService,
    protected override _rootService: EComRootService,
  ) {
    super(TYPE_INFO, _injector, _rootService);
  }

  public ngAfterViewInit(): void {
    this._routerActivate();
  }

  public ngOnInit(): void {
    this._getCheckoutData();
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (queryParams) => {
        this._lockNavigation = coerceBooleanProperty(queryParams[EComRouteNames.queryParamNameLockNavigation]);
      },
    });
  }

  protected _summaryContinueClick(): void {
    if (!mfTypeIsUndefined(this._routerOutletComponent)) {
      if (this._isEComBillingPaymentComponent(this._routerOutletComponent)) {
        this._beginPayment();
        return;
      }
      if (this._isEComOrderReviewComponent(this._routerOutletComponent)) {
        this._completeOrder();
      }
    }
  }

  protected _beginPayment(): void {
    if (!mfTypeIsUndefined(this._checkoutData)) {
      this._storageService.addUpdate(CHECKOUT_INVOICE_DATA_STORAGE_KEY, this._checkoutData);
      const loading = this._subLoading(this._cartService.beginPayment(this._checkoutData), {
        next: () => { loading.complete(); },
        error: () => { loading.complete(); }
      }, "Start Payment");
    }
  }

  protected _setComponentChangeStates(routerOutlet: RouterOutlet): void {
    setTimeout(() => {
      const component = routerOutlet.component;
      if (isStepComponent<EComCompleteInvoiceModel>(component)) {
        component.checkoutData = this._checkoutData;

        if (isValidatingComponent<EComCompleteInvoiceModel>(component)) {
          this._formGroup = component.formGroup;
        } else {
          this._formGroup = new FormGroup([]);
        }

        if (this._isEComOrderReviewComponent(component)) {
          this._summaryButtonLabel = "Make Payment";
          this._summaryShowTC = true;
        } else {
          this._summaryButtonLabel = "Continue";
          this._summaryShowTC = false;
        }

        this._routerOutletComponent = component;
      }
    }, 1);
  }

  protected _isEComBillingPaymentComponent(component: object): component is EComInvoiceBillingPaymentComponent {
    if (component instanceof EComInvoiceBillingPaymentComponent) {
      return true;
    }
    return false;
  }

  protected _isEComOrderReviewComponent(component: object): component is EComInvoiceOrderReviewComponent {
    if (component instanceof EComInvoiceOrderReviewComponent) {
      return true;
    }
    return false;
  }

  protected _getCheckoutData(): void {
    const storeCheckoutData = this._storageService.get<EComInvoiceCheckoutData>(CHECKOUT_INVOICE_DATA_STORAGE_KEY);

    if (!mfTypeIsUndefined(storeCheckoutData)) {
      this._checkoutData = storeCheckoutData;
    } else {
      const sub$ = this._customerService.details.pipe(
        concatMap((customerDetails) => {
          return this._checkoutService.summery.pipe(
            map((checkoutSummery) => {
              this._checkoutData = {
                customerDetail: customerDetails,
                summery: checkoutSummery,
                paymentMethod: EComCartPaymentTypes.creditCard,
                amount: 0,
              };
              if (!mfTypeIsUndefined(this._routerOutletComponent)) {
                this._routerOutletComponent.checkoutData = this._checkoutData;
              }
            })
          );
        })
      );

      const loading = this._subLoading(sub$, {
        next: () => {
          loading.complete();
        }
      }, "Loading Checkout");
    }
  }

  protected _completeOrder(): void {
    if (!mfTypeIsUndefined(this._checkoutData)) {
      const loading = this._subLoading(this._cartService.complete(this._checkoutData), {
        next: (result) => {
          if (result === true) {
            this._storageService.remove(CHECKOUT_INVOICE_DATA_STORAGE_KEY);
            this._router.navigate([EComRouteNames.routeOrderConfirmation]);
          }
          loading.complete();
        },
        error: () => {
          this._storageService.remove(CHECKOUT_INVOICE_DATA_STORAGE_KEY);
          loading.complete();
        }
      }, "Placing Order");
    }
  }
}