<div class="cm-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        Application Submitted
      </mat-card-title>
      <mat-card-subtitle>
        Application Ref: <span class="cm-app-ref">{{_applicationId}}</span>
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <div class="cm-message">
        Thank you for you application you will here back soon.
      </div>
    </mat-card-content>
  </mat-card>
</div>