import { Component, Inject, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_TABLE_CONFIG, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComOrderModel, EComOrderRequest } from "@e-commerce/modelConfigs/order.model.config";
import { EComOrderDetailHostComponent } from "@e-commerce/orderDetail/order.detail.host.component";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { MfTypeInfo, MfTableConfig, MfModelFieldExtendedConfig, MF_ENUM_FILTER_CONFIG, mfObjectGetPropertyPath, MF_STRING_FILTER_CONFIG, MfTableModelSelected, MF_TABLE_CONFIG_TOKEN, MfBaseComponent, MfPortalsTableComponent, MfModelConfigMapped, MfSideDrawerRef, MFModelConfigFieldKey, MfModelConfigService, MfRootSideDrawerService, mfTypeIsUndefined, mfTableReIndexModelFieldExtendedConfig, MfModelValueOutputFormatterTypes } from "@pattonair/material-framework";
import { map, Observable, Subscription } from "rxjs";

const TYPE_INFO: MfTypeInfo = { className: "EComOrdersComponent" };

const ECOM_ORDERS_DETAIL_TABLE_CONFIG: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  row: {
    ...ECOM_TABLE_CONFIG.row,
    clickAction: {
      ...ECOM_TABLE_CONFIG.row.clickAction,
      tooltip: "View Order Detail",
    }
  }
};

export const ECOM_ORDERS_LOCATION_KEY = "orders";

const ECOM_ORDER_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_ENUM_FILTER_CONFIG },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("status")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 8 } }, exclude: { operatorsTypes: { string: { contains: true, startsWith: true } } } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("orderNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("orderLineNumber")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 25 } } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("customerOrder")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("customerOrderLine")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 25 } } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("itemCode")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("revision")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("actionQuantity")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("orderQuantity")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("shippedQuantity")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComOrderModel>("requiredDate")
  },
];

type EComOrderViewModel = EComOrderModel & MfTableModelSelected;

@Component({
  selector: "ecom-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_ORDERS_DETAIL_TABLE_CONFIG },
  ]
})
export class EComOrdersComponent extends MfBaseComponent implements OnInit, OnDestroy {
  @ViewChild("ordersTable", { static: true })
  protected get _ordersTable(): MfPortalsTableComponent<EComOrderViewModel, string, number> | undefined {
    return this._ordersTableInt;
  }
  protected set _ordersTable(value: MfPortalsTableComponent<EComOrderViewModel, string, number> | undefined) {
    this._ordersTableInt = value;
    this._initializeTable();
  }

  protected _orderModelConfig: MfModelConfigMapped;
  protected _orderRequestModelConfig: MfModelConfigMapped;
  protected _ordersTableInt?: MfPortalsTableComponent<EComOrderViewModel, string, number>;
  protected _sideDrawerRef?: MfSideDrawerRef<EComOrderDetailHostComponent>;
  protected _sideDrawerOnAfterOpened?: Subscription;
  protected _sideDrawerOnAfterClosed?: Subscription;
  protected _sideDrawerOnClose?: Subscription;
  protected _locationKey = ECOM_ORDERS_LOCATION_KEY;
  protected _selectedOrder?: EComOrderViewModel;
  protected _filterOrderNumber?: string;
  protected _orderNumberFieldPath: MFModelConfigFieldKey;

  public constructor(
    protected override _injector: Injector,
    protected _activatedRoute: ActivatedRoute,
    protected _modelConfigService: MfModelConfigService,
    protected _rootSideDrawerService: MfRootSideDrawerService,
    protected _customerService: EComCustomerService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _config: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._orderModelConfig = this._modelConfigService.get<EComModelsConfig>("order");
    this._orderRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("orderRequest");
    this._orderNumberFieldPath = mfObjectGetPropertyPath<EComOrderViewModel>("orderNumber");
    this._getQueryParams();
    this._setModelConfigs();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    if (!mfTypeIsUndefined(this._sideDrawerRef)) {
      this._sideDrawerRef.close();
    }
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected _getQueryParams(): void {
    this._sub(this._activatedRoute.queryParams, {
      next: (queryParams) => {
        this._filterOrderNumber = queryParams[EComRouteNames.queryParamNameFilterOrderNumber];
      }
    });
  }

  protected get _isLoading(): boolean {
    return this._ordersTable?.isLoading === true;
  }

  protected _onRowClicked(order: EComOrderViewModel): void {
    this._selectedOrder = order;
    this._ordersTable?.dataSource.data.forEach(i => i.selected = false);
    order.selected = true;
    this._showSideDrawer(order);
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._ordersTable)) {
      this._ordersTable.blockDataLoad = false;
      this._ordersTable.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_ORDER_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._orderModelConfig, ECOM_ORDER_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._ordersTable)) {
      this._ordersTable.blockDataLoad = true;
      this._ordersTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsOrdersUrl}`;

      this._ordersTable.dataSource.buildPostData = this._updatePostDate;

      this._sub(this._ordersTable.dataSource.onDataLoaded, {
        next: (data) => {
          if (!mfTypeIsUndefined(this._selectedOrder)) {
            const orders = data as EComOrderViewModel[];
            const length = orders.length;
            for (let index = 0; index < length; index++) {
              const order = orders[index];
              if (order.itemCode === this._selectedOrder.itemCode) {
                order.selected = true;
                break;
              }
            }
          }
        }
      });
    }
  }

  protected _showSideDrawer(order: EComOrderViewModel): void {
    if (mfTypeIsUndefined(this._sideDrawerRef)) {
      this._sideDrawerRef = this._rootSideDrawerService.open(EComOrderDetailHostComponent);
      this._sideDrawerOnAfterOpened = this._sub(this._sideDrawerRef.onAfterOpened, {
        next: (sideDrawerRef) => {
          if (!mfTypeIsUndefined(sideDrawerRef.componentRef)) {
            this._setSideDrawerInputs(order);
            this._sideDrawerOnClose = this._sub(
              sideDrawerRef.componentRef.instance.onClose, { next: () => sideDrawerRef.close() }
            );
          }
        }
      });
      this._sideDrawerOnAfterClosed = this._sub(this._sideDrawerRef.onAfterClosed, { next: () => this._hideSideDrawer() });
    } else {
      this._setSideDrawerInputs(order);
    }
  }

  protected _setSideDrawerInputs(order: EComOrderViewModel): void {
    if (!mfTypeIsUndefined(this._sideDrawerRef) && !mfTypeIsUndefined(this._sideDrawerRef.componentRef)) {
      this._sideDrawerRef.componentRef.setInput("order", order);
    }
  }

  protected _hideSideDrawer(): void {
    if (!mfTypeIsUndefined(this._sideDrawerRef) &&
      !mfTypeIsUndefined(this._sideDrawerOnAfterOpened) &&
      !mfTypeIsUndefined(this._sideDrawerOnAfterClosed) &&
      !mfTypeIsUndefined(this._sideDrawerOnClose)) {

      delete this._sideDrawerRef;

      this._unSub(this._sideDrawerOnAfterOpened);
      this._unSub(this._sideDrawerOnAfterClosed);
      this._unSub(this._sideDrawerOnClose);

      delete this._sideDrawerOnAfterOpened;
      delete this._sideDrawerOnAfterClosed;
      delete this._sideDrawerOnClose;
    }
  }

  protected _updatePostDate = (data: EComOrderRequest): Observable<EComOrderRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.key = selected.key;
        return data;
      })
    );
  };
}