import { Component, Inject, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_LINKS_CONFIG_TOKEN, EComIconsConfig, EComLinksConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { EComRootService } from "@e-commerce/root/authn/root.authn.service";
import { EComUserService } from "@e-commerce/services/user.service";
import { MfTypeInfo, MfBaseComponent, mfDateCurrent, MfModelConfigService, MfAuthService } from "@pattonair/material-framework";


const TYPE_INFO: MfTypeInfo = { className: "EComRootUnAuthnComponent" };

@Component({
  selector: "ecom-root-unauthn",
  templateUrl: "./root.unauthn.component.html",
  styleUrls: ["./root.unauthn.component.scss"]
})
export class EComRootUnAuthnComponent extends MfBaseComponent {
  protected _mfDateCurrent = mfDateCurrent;
  protected _isApproved: boolean = true;

  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _modelConfigService: MfModelConfigService,
    protected _rootService: EComRootService,
    protected _authService: MfAuthService,
    protected _userService: EComUserService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
    @Inject(ECOM_LINKS_CONFIG_TOKEN)
    protected _linksConfig: EComLinksConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._sub(this._userService.isApproved, {
      next: (isApproved) => {
        this._isApproved = isApproved;
        if (isApproved === false) {
          this._router.navigate([EComRouteNames.routeNotApproved]);
        } else if (this._authService.hasValidAccessToken()) {
          this._router.navigate([EComRouteNames.routeHome]);
        }
      }
    });
  }

  protected _logIn(): void {
    this._sub(this._authService.runInitialLoginSequence(EComRouteNames.partNameHome));
  }

  protected _goHome(): void {
    this._sub(this._userService.isApproved, {
      next: (isApproved) => {
        if (isApproved === true) {
          this._router.navigate([EComRouteNames.routeHome]);
        }
      }
    });
  }
}
