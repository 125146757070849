import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";


export type EComOrderCheckoutRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
};

export type EComOrderCheckoutRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCheckoutRequestModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CHECKOUT_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCheckoutRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG_ID = "orderCheckoutRequest";

export const ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CHECKOUT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CHECKOUT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartValidationModel = MfModelBase & {
  cartKey?: string;
  orderReference?: string;
  items?: EComOrderCartValidationItemModel[],
}

export type EComOrderCartValidationModelFieldsConfig = {
  [key in keyof Required<EComOrderCartValidationModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_VALIDATION_MODEL_FIELDS_CONFIG: EComOrderCartValidationModelFieldsConfig = {
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  orderReference: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  items: {
    dataType: {
      type: MfModelFieldDataTypes.array,
      arrayItemType: MfModelFieldDataTypes.object,
    },
  },
};

export const ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG_ID = "orderCartValidation";

export const ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_VALIDATION_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_VALIDATION_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCartValidationItemModel = MfModelBase & {
  cartItemKey: string,
  latestQuantityAvailable?: number;
  latestPrice?: number;
  latestPriceCurrency?: string;
  latestPriceUom?: string;
  latestItemUom?: string;
  isRequiredDateValid?: boolean;
  errorMessage?: string;
}

export type EComOrderCartValidationItemModelFieldsConfig = {
  [key in keyof Required<EComOrderCartValidationItemModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_FIELDS_CONFIG: EComOrderCartValidationItemModelFieldsConfig = {
  cartItemKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestQuantityAvailable: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  latestPrice: {
    dataType: {
      type: MfModelFieldDataTypes.decimal
    },
  },
  latestPriceCurrency: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestPriceUom: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  latestItemUom: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  errorMessage: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  isRequiredDateValid: {
    dataType: {
      type: MfModelFieldDataTypes.boolean
    },
  },
};

export const ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG_ID = "orderCartValidationItem";

export const ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CART_VALIDATION_ITEM_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCompleteModel = {
  key: string;
  cartKey: string;
  carrierKey: string;
  paymentMethod: number;
  purchaseOrderRef: string;
};

export type EComOrderCompleteModelFieldsConfig = {
  [key in keyof Required<EComOrderCompleteModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_COMPLETE_MODEL_FIELDS_CONFIG: EComOrderCompleteModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  },
  purchaseOrderRef: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  }
};

export const ECOM_ORDER_COMPLETE_MODEL_CONFIG_ID = "orderComplete";

export const ECOM_ORDER_COMPLETE_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_COMPLETE_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_COMPLETE_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_COMPLETE_MODEL_CONFIG",
  },
  portals: {}
};