import { Component, Inject, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ECOM_ICONS_CONFIG_TOKEN, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { MfTypeInfo, MfBaseComponent } from "@pattonair/material-framework";

const TYPE_INFO: MfTypeInfo = { className: "EComConfirmationComponent" };

@Component({
  selector: "ecom-confirmation",
  templateUrl: "./confirmation.component.html",
  styleUrls: ["./confirmation.component.scss"]
})
export class EComConfirmationComponent extends MfBaseComponent {
  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
  }

  protected _goHome(): void {
    this._router.navigate([EComRouteNames.routeHome]);
  }
}