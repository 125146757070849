import { MfModelBase, MfModelFieldConfig, MfModelFieldDataTypes, MfModelConfig } from "@pattonair/material-framework";
import { EComPayPalPaymentInfo } from "./paypal.model.config";
import { ECOM_ORDER_CART_VALIDATION_MODEL_FIELDS_CONFIG, EComOrderCartValidationModel } from "./checkout.order.model.config";

export type EComOrderCheckoutBeginPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  purchaseOrderRef: string;
  carrierKey: string;
  paymentMethod: number;
}

export type EComOrderCheckoutBeginPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCheckoutBeginPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCheckoutBeginPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  purchaseOrderRef: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  carrierKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentMethod: {
    dataType: {
      type: MfModelFieldDataTypes.int
    },
  }
};

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID = "orderCheckoutBeginPaymentRequest";

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};

export type EComOrderCheckoutBeginPaymentModel = EComOrderCartValidationModel & {
  paymentUrl: string;
}

export type EComOrderCheckoutBeginPaymentModelFieldsConfig = {
  [key in keyof Required<EComOrderCheckoutBeginPaymentModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG: EComOrderCheckoutBeginPaymentModelFieldsConfig = {
  ...ECOM_ORDER_CART_VALIDATION_MODEL_FIELDS_CONFIG,
  paymentUrl: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
};

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID = "orderCheckoutBeginPayment";

export const ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG_ID,
  fields: ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CHECKOUT_BEGIN_PAYMENT_CONFIG",
  },
  portals: {}
};

export type EComOrderCheckoutEndPaymentRequestModel = MfModelBase & {
  key: string;
  cartKey: string;
  paymentInfo: EComPayPalPaymentInfo;
}

export type EComOrderCheckoutEndPaymentRequestModelFieldsConfig = {
  [key in keyof Required<EComOrderCheckoutEndPaymentRequestModel>]: MfModelFieldConfig
};

export const ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG: EComOrderCheckoutEndPaymentRequestModelFieldsConfig = {
  key: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  cartKey: {
    dataType: {
      type: MfModelFieldDataTypes.string
    },
  },
  paymentInfo: {
    dataType: {
      type: MfModelFieldDataTypes.object
    },
  },
};

export const ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID = "orderCheckoutEndPaymentRequest";

export const ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG: MfModelConfig = {
  key: ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG_ID,
  fields: ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_FIELDS_CONFIG,
  autoMapping: {
    constName: "ECOM_ORDER_CHECKOUT_END_PAYMENT_REQUEST_MODEL_CONFIG",
  },
  portals: {}
};