import { Component, Injector, OnInit } from "@angular/core";
import { MfBaseComponent, MfTypeInfo } from "@pattonair/material-framework";
import { EComRecommendedProductModel } from "../../modelConfigs/recommended.product.model.config";
import { EComRecommendedProductsService } from "./recommended.products.service";

const TYPE_INFO: MfTypeInfo = { className: "EComRecommendedProductsAComponent" };

@Component({
  selector: "ecom-recommended-products-a",
  templateUrl: "./recommended.products.a.component.html",
  styleUrls: ["./recommended.products.a.component.scss"],
})
export class EComRecommendedProductsAComponent extends MfBaseComponent implements OnInit {
  protected _products: EComRecommendedProductModel[] = [];

  public constructor(
    protected override _injector: Injector,
    protected _recommendedProductsService: EComRecommendedProductsService,
  ) {
    super(TYPE_INFO, _injector);
  }

  public ngOnInit(): void {
    this._sub(this._recommendedProductsService.get(), {
      next: (result) => this._products = result
    });
  }
}